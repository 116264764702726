import { api } from "../../../helpers/api";
import {
  isLoading,
  isLoaded,
} from "../../Common/Layout/components/Cabinet/actions";

export const getAllPostsRequest = () => async (dispatch) => {
  // const response = await api("get", `posts/all-posts`, null);

  // console.log("In the service file", response);

  // return response;

  dispatch(isLoading());
  try {
    const response = await api("get", `posts/all-posts`, null);
    dispatch(isLoaded());
    console.log("In the service file", response);
    return response;
  } catch (err) {
    console.log(err);
    dispatch(isLoaded());
  }
};

export const searchPostsRequest = (keyword) => async (dispatch) => {
  // const response = await api("get", `posts/find/${keyword}`, null);

  // return response;

  dispatch(isLoading());
  try {
    const response = await api("get", `posts/find/${keyword}`, null);
    dispatch(isLoaded());
    console.log("In the service file", response);
    return response;
  } catch (err) {
    console.log(err);
    dispatch(isLoaded());
  }
};
