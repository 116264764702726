import React from "react";
import moment, { Moment } from "moment";
import {
  CarryOutOutlined,
  UserAddOutlined,
  FieldTimeOutlined,
  EnvironmentOutlined,
  ScheduleOutlined,
  ArrowLeftOutlined,
  DollarOutlined,
  DesktopOutlined,
} from "@ant-design/icons";
import { Row, Col, Button, Typography, Space, Tooltip } from "antd";
import Icon from "@ant-design/icons";
import style from "./index.module.scss";
import { useState, useEffect } from "react";
import { timeSince } from "../../../../../helpers/timeSince";

const { Title, Text } = Typography;

export const PostTablets = (props) => {
  const [hours, setHours] = useState();
  const [discount1, setDiscount1] = useState();
  const [start, setStart] = useState();
  const [end, setEnd] = useState();
  const [time, setTime] = useState();

  console.log(props);

  const iconStyle = {
    color: "#f44336",
    fontSize: "20px",
    paddingLeft: "0px",
    paddingRight: "10px",
  };

  const size = "large";
  useEffect(() => {
    


    const hours = () => {
      // alert( moment(props.post?.dateTime, "DD-MM-YYYY hh:mm:ss"))
      var startDate = moment(props.post?.dateTime);
      const endData = moment(props.post?.endTime);
      const diff = endData.diff(startDate);
      const diffDuration = moment.duration(diff);
      return diffDuration.asHours();
    };
    const date = hours();
    // alert(date)
    setHours(Math.abs(Math.round(date)));
 
  });
  useEffect(() => {
    const discount = () => {
      const off = props.post?.payment;
      let dis;
      if (props.discount)
        dis = `after applicable fees & deductions $${
          off - props.discount * off
        } `;
      else dis = `after applicable fees & deductions $${off - 0.12 * off}`;
      // console.log(props.discount, "hjjj");
      return dis;
    };
    const finalDiscount = discount();
    // alert(finalDiscount)
    setDiscount1(finalDiscount);
  });
  return (
    <>
      <Row>
        <Col span={21} style={{ display: "flex", gap: "0.5rem" }}>
          <Button size={size} onClick={props.goBack}>
            <ArrowLeftOutlined style={{ color: "#f44336" }} />
          </Button>
          <Button size={size} style={{ background: "#f44336" }}>
            <Text style={{ color: "#fff" }}>
              <CarryOutOutlined
                style={{
                  color: "#fff",
                  fontSize: "20px",
                  paddingLeft: "0px",
                  paddingRight: "10px",
                }}
              />
              {props?.dateTime?.month} {props?.dateTime?.day}{" "}
              {props?.dateTime?.hours}:{props?.dateTime?.minutes}
            </Text>
          </Button>
          <Button size={size}>
            <Text>
              <EnvironmentOutlined style={iconStyle} />
              {props.post?.postedBy?.company?.city}
            </Text>
          </Button>

          <Button size={size}>
            <Text>
              <FieldTimeOutlined style={iconStyle} />
              {hours} Hours Shift
            </Text>
          </Button>

          {/* <Button size={size}>
          <Text>
            <FieldTimeOutlined style={iconStyle} />
            {props?.post?.software} 
          </Text>
        </Button> */}

          {/* <Button size={size}>
          <Text>
            <UserAddOutlined style={iconStyle} />
            {props.post?.availableShifts} Available Shifts
          </Text>
        </Button> */}
          <Button size={size}>
            {/* <Text>
            <DollarOutlined style={iconStyle} />
            {props.post?.payment || "0"} dollars
          </Text> */}
            <Space wrap>
              <Tooltip title={discount1} color={"#f50"}>
                {/* <Button>{color}</Button> */}
                <span className={style.iconBox}>
                  <Text>
                    <DollarOutlined style={iconStyle} />
                    {props.post?.payment || "0"} dollars
                  </Text>
                </span>
              </Tooltip>
            </Space>
          </Button>
          <Button size={size} style={{ float: "right", marginLeft: "10px" }}>
            <Text>
              {/* &nbsp;&nbsp; &nbsp;{" "} */}
              {/* <UserAddOutlined style={iconStyle} /> */}
              {moment(props.post?.dateTime).format("HH:mm A")} {" - "}
              {moment(props.post?.endTime).format("HH:mm A")}
              
            </Text>
          </Button>

          <Button size={size} style={{ float: "right", marginLeft: "10px" }}>
            <Text>
              {timeSince(new Date(props.post?.createdAt).getTime())} ago
            </Text>
          </Button>
        </Col>
        {/* <Col span={3}>
        <Button size={size} style={{ float: "right" }}>
          <Text>
            {timeSince(new Date(props.post?.createdAt).getTime())} ago
          </Text>
        </Button>
      </Col> */}
      </Row>
      <Row>
        <Col span={21} style={{ display: "flex", gap: "0.5rem" }}>
          <Button size={size}>
            <Text>
              {/* &nbsp;&nbsp; &nbsp;{" "} */}
              {/* <UserAddOutlined style={iconStyle} /> */}
              <ScheduleOutlined style={iconStyle} />
              {props.post?.work}
            </Text>
          </Button>

          <Button size={size}>
            <Text>
              {/* <FieldTimeOutlined style={iconStyle} /> */}
              {props.post?.language1? props.post?.language1 : props.post?.language}
            </Text>
          </Button>

          <Button size={size}>
            <Space wrap>
              <span className={style.iconBox}>
                <Text>
                  {/* <DesktopOutlined style={iconStyle} /> */}
                  {props.post?.software1
                    ? props.post?.software1
                    : props.post?.software}{" "}
                  Software
                </Text>
              </span>
            </Space>
          </Button>

          <Button size={size} style={{ float: "right", marginLeft: "10px" }}>
            <Text>{props.post?.days} Day Coverage</Text>
          </Button>


          <Button size={size} >
            <Text >
              <CarryOutOutlined
                style={{
                  // color: "#fff",
                  fontSize: "20px",
                  paddingLeft: "0px",
                  paddingRight: "10px",
                }}
              />
              {props?.startDate?.month} {props?.startDate?.day}{"-"}
              {props?.endDate?.month} {props?.endDate?.day}

              {/* {props?.startDate?.hours}:{props?.startDate?.minutes} */}
            </Text>
          </Button>

      
        </Col>
      </Row>
    </>
  );
};
