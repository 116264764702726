import React from "react";
import { Button, Col, Row } from "antd";
import { RatingContainer } from "../../../../Common/Rating/container";
import {
  CarryOutOutlined,
  UserAddOutlined,
  FieldTimeOutlined,
  EnvironmentOutlined,
  IdcardOutlined,
  PushpinOutlined,
  CheckOutlined,
} from "@ant-design/icons";
import { Typography } from "antd";
import style from "./index.module.scss";
import { timeSince } from "../../../../../helpers/timeSince";
import { useEffect } from "react";
import { useState } from "react";
export const CandidateCard = (props) => {
  const candidate = props.candidate?.candidate;
  const status = props.candidate?.status;
  const [feedbackBtn, setFeedbackBtn] = useState();
  const [candidates, setCandidates] = useState(null);
 const [feedbackByPharmacy, setPharmacyFeedback] = useState(props.candidate?.isPharmmcyFeedback)
  console.log(candidate.isComplete, "isComplete");
  const updateTime = props.candidate?.updatedAt;
  console.log(props.candidate);
  console.log(props.postName);
  const iconStyle = {
    color: "#f44336",
    fontSize: "20px",
    paddingLeft: "0px",
    paddingRight: "10px",
  };
  // useEffect(() => {
  //   // const FeedbackUpdatedOrNot = async () => {
  //   //   const data = await props.onPharmacyFeedbackUpdate(
  //   //     props.post.candidates,
  //   //     candidate?._id
  //   //   );
  //   //   if (data[0] == true) setFeedbackBtn("Edit Review");
  //   //   else setFeedbackBtn("Add Review");
   
  //   // };
  //   // FeedbackUpdatedOrNot();
  // }, [props.candidate.isPharmmcyFeedback]);

  useEffect(()=>{
// props.getCandidatesRequest(props.canId);

})
  return (
    <Row className={style.container}>
      <Col span={12}>
        <Row className={style.information}>
          <Col span={12}>
            <p className={style.name}>
              {candidate?.firstName} {candidate?.middleName}{" "}
              {candidate?.lastName}
            </p>
          </Col>
          <Col span={12}>
            <p className={style.lightText}>
              <PushpinOutlined style={iconStyle} />
              {props?.postName}
            </p>
          </Col>
        </Row>
        <Row>
          <Col span={4}>
            <p className={style.lightText}>{candidate?.immunizer}</p>
          </Col>
        </Row>
        <Row>
          <Col span={4}>
            <p className={style.lightText}>{timeSince(new Date(updateTime))}</p>
          </Col>
          <Col span={6}>
            <p className={style.iconText}>
              <EnvironmentOutlined style={iconStyle} />
              {candidate?.city} {" "}   {candidate?.state}
            </p>
          </Col>
          {/* <Col span={12}>
            <p className={style.iconText}>
              <EnvironmentOutlined style={iconStyle} />
              {candidate?.state}
            </p>
          </Col> */}
        </Row>
      </Col>
      <Col span={12} className={style.controls}>
        <Row className={style.controlsContainer}>
          <Col span={24}>
            <Row>
              <Col span={12}>
                <div className={style.btnContainer}>
                  {status === "Approved" && (
                    <Button
                      className={style.statusContainer}
                      style={{ backgroundColor: "#83e3ad" }}
                    >
                      <CheckOutlined /> Approved
                    </Button>
                  )}
                  {status === "Disapproved" && (
                    <Button
                      className={style.statusContainer}
                      style={{ backgroundColor: "#f4dde3" }}
                    >
                      Disapproved
                    </Button>
                  )}
                </div>
              </Col>
              <Col span={8}>
                <Button
                  className={style.statusContainer}
                  type="danger"
                  onClick={() => {
                    props?.onLearnMoreClick(candidate?._id, status);
                  }}
                >
                  Learn More
                </Button>
              </Col>
              <Col span={4}>
                <div className={style.btnContainer}>
                  {props.candidate.isComplete===true &&(feedbackByPharmacy==false ||feedbackByPharmacy ==undefined)? (
                    <RatingContainer
                      postID={props.post._id}
                      candidates={props.post.candidates}
                      candidateID={candidate?._id}
                      onMarkInComplete={props.onMarkInComplete}
                      userID={props.userID}
                      onPharmacyFeedback={props.onPharmacyFeedback}
                      size={"medium"}
                      feedbackBtn={"Add Review"}

                    />
                  ) : (
                    <></>
                  )}
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
