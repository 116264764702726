import React from "react";
import PropTypes from "prop-types";
import { Auth } from "./components/Auth";
import { Cabinet } from "./components/Cabinet";
import {
  pharmacistMenuList,
  pharmacyMenuList,
  adminMenuList,
} from "../../../config/menu";
import { connect } from "react-redux";
import { logoutHandler } from "../../Auth/Signin/actions";
import { ROLES } from "../../../constants/ROLES";

const LayoutContainer = (props) => {
  return props.isAuth ? (
    <Cabinet
      menuList={
        props.user?.role === ROLES.pharmacy
          ? pharmacyMenuList
          : props.user?.role === ROLES.admin
          ? adminMenuList
          : pharmacistMenuList
      }
      user={props.user}
      logout={props.logoutHandler}
    >
      {props.children}
    </Cabinet>
  ) : (
    <Auth>{props.children}</Auth>
  );
};

LayoutContainer.propTypes = {
  isAuth: PropTypes.bool.isRequired,
  user: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    email: PropTypes.string,
    role: PropTypes.string,
  }),
  logoutHandler: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  isAuth: state.auth.signin.isAuth,
  user: state.auth.signin.user,
});

export const Layout = connect(mapStateToProps, { logoutHandler })(
  LayoutContainer
);
