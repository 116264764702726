import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { withRouter } from "../../../../helpers/withRouter";
import style from "./index.module.scss";
import { connect } from "react-redux";
import { Loader } from "../../../Common/Loader";
import { useLocation } from "react-router";
import { message } from "antd";
import { ScheduleCard } from "../components/ScheduleCard";
import {
  getMyScheduleRequest,
  updateCandidateRequest,
  updateFeedback,
  submitFeedbackRequest,
  getFeedbacksByPostID,
  filterFeedback,
  getContractsByFilter,
  updateContracts
} from "../service";
import { getDate } from "../../../../helpers/getDate";

import { ItemNotFound } from "../../../Common/ItemNotFound";
import moment, { Moment } from "moment";

const MySchedule = (props) => {
  const [posts, setPosts] = useState([]);
  const [id, setPostID] = useState();
  const [postedbyID, setPostbyID] = useState();
  const location = useLocation();
  const [discount, setDiscount] = useState();
  const [contractID, setContractID] = useState();

  const history = useNavigate();
  useEffect(() => {
    const getSchedule = async () => {
      const response = await props.getMyScheduleRequest(props.user?.id);
      setPosts(response.data.posts);
      setDiscount(response?.data?.discount);
    };
    getSchedule();
  }, []);

  const onPressViewShift = (postID) => {
    history(`/post-details/${postID}`, { state: "my-schedule" });
  };

  const postID = (postID, postedby) => {
    setPostID(postID);
    setPostbyID(postedby);
  };

  const data = async (comment, rating) => {
    console.log(rating, "rating");
    console.log(comment, "comment");
    const Feedback = await props.filterFeedback(id, postedbyID, props.user?.id);
    const feedbackID = Feedback?.data?.feedback.map((user) => {
      return user?._id;
    });
    console.log(feedbackID.length, "length");

    if (feedbackID.length > 0) {
      feedbackID.toString();
      console.log(feedbackID, "FeedbackID");
      await props.updateFeedback(feedbackID, rating, comment);
      message.info("Feedback Updated");
    } else {
      await props.submitFeedbackRequest(
        id,
        props.user?.id,
        postedbyID,
        rating,
        comment
      );
      console.log(rating, id, postedbyID, { ...comment });
      message.info("Feedback submitted");
    }
  };

  const onMarkComplete = async (postID, company, caandidates) => {
    const data = caandidates?.map((candidate) => {
      if (candidate.candidate._id === props.user?.id) {
        candidate.isComplete = true;
      }
      return candidate;
    });
    const data1 = {
      candidates: data,
    };
    const response = await props.updateCandidateRequest(postID, data1);
    console.log(response);
    const contract = await props.getContractsByFilter(
      postID,
      company,
      props.user.id
    );
    console.log(contract?.data.contarct, "contract");
    let contractID = "";
    contract?.data.contarct?.map((user) => {
      contractID = user._id;
      return contractID;
    });
    // console.log(contractID);
    const updatedData = {
      status:"Completed"
    }
const contracts = await props.updateContracts(contractID, updatedData)
console.log("Complete ", contracts)
  };




  const onPharmacistFeedback = async (postID, caandidates, id) => {
    const data = caandidates?.map((candidate) => {
      if (candidate.candidate._id === id) {
        candidate.isPharmacistFeedback = true;
      }
      return candidate;
    });

    const data1 = {
      candidates: data,
    };
    const response = await props.updateCandidateRequest(postID, data1);
    console.log(response);
  };

  return props.isLoading ? (
    <Loader />
  ) : posts.length > 0 ? (
    <div
      style={{
        margin: "24px 0 0",
        marginBottom: "10px",
      }}
    >
      {posts?.length > 0 &&
        posts.map((post) => (
          <ScheduleCard
            key={post._id}
            id={post._id}
            post={post}
            posts={posts}
            candidateID={props.user?.id}
            onPressViewShift={onPressViewShift}
            onMarkComplete={onMarkComplete}
            updateFeedback={props.updateFeedback}
            submitFeedbackRequest={props.submitFeedbackRequest}
            filterFeedback={props.filterFeedback}
            data={data}
            postID={postID}
            idpost={id}
            postedbyID={postedbyID}
            // initialValues = {initialValues}
            // hours = {hours}
            onPharmacistFeedback={onPharmacistFeedback}
            discount={discount}
          />
        ))}
    </div>
  ) : (
    <ItemNotFound>
      <h1 style={{ color: "#808080" }}>You have not applied to any jobs.</h1>
    </ItemNotFound>
  );
};

MySchedule.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  isAuth: PropTypes.bool.isRequired,
  isSuccess: PropTypes.bool.isRequired,
  isError: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
  getMyScheduleRequest: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  getContractsByFilter,
};

const mapStateToProps = (state) => ({
  isLoading: state.shared.loading.isLoading,
  isAuth: state.auth.signin.isAuth,
  isError: state.auth.signup.isError,
  isSuccess: state.auth.signup.isSuccess,
  errorMessage: state.auth.signup.errorMessage,
  user: state.auth.signin.user,
});
export const MyScheduleContainer = withRouter(
  connect(mapStateToProps, {
    getMyScheduleRequest,
    updateCandidateRequest,
    updateFeedback,
    getFeedbacksByPostID,
    submitFeedbackRequest,
    getContractsByFilter,
    filterFeedback,
    updateContracts
  })(MySchedule)
);
