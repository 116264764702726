import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import PharmacistProfileForm from "../components/PharmacistProfileForm";
import PharmacyProfileForm from "../components/PharmacyProfileForm";
import { connect } from "react-redux";
import { signupRequest, getLoggedInUserRequest , profileRequest} from "../actions";
import { Link } from "react-router-dom";
import { withRouter } from "./../../../../helpers/withRouter";
import style from "./index.module.scss";
import { ROLES } from "../../../../constants/ROLES";
// import { signupRequest } from "../actions";

const Profile = (props) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const getData = async () => {
      const response = await props.getLoggedInUserRequest(props.user?.id);
      console.log(response);
      setUser(response.data?.user);
    };

    if (props?.user) {
      getData();
    }
  }, [props]);

  return (
    <div className={style.signupFormWrapper}>
      {props.user?.role === ROLES.pharmacist ? (
        <PharmacistProfileForm
          signupRequest={props.signupRequest}
          isLoading={props.isLoading}
          isError={props.isError}
          errorMessage={props.errorMessage}
          user={props?.user}
          isProfileComplete = {props.isProfile}
          profileRequest={props.profileRequest}
          userInfo = {user}
        />
      ) : (
        <PharmacyProfileForm
          user={user}
          signupRequest={props.signupRequest}
          isLoading={props.isLoading}
          isError={props.isError}
          errorMessage={props.errorMessage}
          profileRequest = {props.profileRequest}
        />
      )}
    </div>
  );
};

Profile.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  isAuth: PropTypes.bool.isRequired,
  isSuccess: PropTypes.bool.isRequired,
  isError: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
  signupRequest: PropTypes.func.isRequired,
  getLoggedInUserRequest: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  isLoading: state.auth.signup.isLoading,
  isAuth: state.auth.signin.isAuth,
  isError: state.auth.signup.isError,
  isSuccess: state.auth.signup.isSuccess,
  errorMessage: state.auth.signup.errorMessage,
  user: state.auth.signin?.user,
  isProfile: state.auth.signin?.isProfileComplete,

});

export const ProfileContainer = withRouter(
  connect(mapStateToProps, { signupRequest, profileRequest, getLoggedInUserRequest })(Profile)
);
