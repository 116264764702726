import React from "react";

export const UnderReview = () => {
  const containerStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    width: "100%",
  };

  return (
    <div style={containerStyle}>
      <h1 style={{ color: "#808080" }}>Your Account is Under Review!</h1>
    </div>
  );
};
