import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "../../../../helpers/withRouter";
import { RatingComponents } from "../component";
import { connect } from "react-redux";
import { submitFeedbackRequest , filterFeedback,getFeedbacksByPostID, updateFeedback} from "../service";
import { useLocation } from "react-router";
import { useState } from "react";
import moment from "moment";

const Rating = (props) => {
  const location = useLocation();

  const feedback = location?.state;
  const initialValues = feedback
    ? {
        comment: feedback?.comment,
      }
    : {
        comment: "",
      };
  return (
    <>
      <RatingComponents
        initialValues={initialValues}
        isLoading={props.isLoading}
        isError={props.isError}
        onSubmit={props.submitFeedbackRequest}
        errorMessage={props.errorMessage}
        postID={props.postID}
        candidates={props.candidates}
        candidateID={props.candidateID}
        onMarkInComplete={props.onMarkInComplete}
        userID={props.userID}
        size = {props.size}
        feedback={feedback}
        onPharmacyFeedback = {props.onPharmacyFeedback}
        updateFeedback = {props.updateFeedback}
        getFeedbacksByPostID = {props.getFeedbacksByPostID}
        filterFeedback = {props.filterFeedback}
        feedbackBtn = {props.feedbackBtn}
      />
    </>
  );
};

Rating.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  isAuth: PropTypes.bool.isRequired,
  isSuccess: PropTypes.bool.isRequired,
  isError: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,

  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  isLoading: state.shared.loading.isLoading,
  isAuth: state.auth.signin.isAuth,
  isError: state.auth.signup.isError,
  isSuccess: state.auth.signup.isSuccess,
  errorMessage: state.auth.signup.errorMessage,
  user: state.auth.signin.user,
});

export const RatingContainer = withRouter(
  connect(mapStateToProps, { submitFeedbackRequest ,filterFeedback, updateFeedback, getFeedbacksByPostID})(Rating)
);
