import { api } from "../../../helpers/api";
import {
  isLoading,
  isLoaded,
} from "../../Common/Layout/components/Cabinet/actions";

export const getCandidateInformationRequest = (id) => async (dispatch) => {
  dispatch(isLoading());
  try {
    const response = await api("get", `users/${id}`, null);
    dispatch(isLoaded());
    console.log("In the service file", response);
    return response;
  } catch (err) {
    console.log(err);
    dispatch(isLoaded());
  }
};

export const updatePostInformationRequest =
  (postID, data) => async (dispatch) => {
    // const response = await api("patch", `posts/edit-post/${postID}`, data);
    // return response;

    dispatch(isLoading());
    try {
      const response = await api("patch", `posts/edit-post/${postID}`, data);
      dispatch(isLoaded());
      console.log("In the service file", response);
      return response;
    } catch (err) {
      console.log(err);
      dispatch(isLoaded());
    }
  };
  export const createPaymentRequest =
  (agencyID, userID, payment , postID) => async (dispatch) => {
    // const response = await api("patch", `posts/edit-post/${postID}`, data);
    // return response;
    const data = {
     
      companyID: agencyID,
      postID: postID,
      amount: payment
    };
    // console.log(data, "ggh")
    dispatch(isLoading());
    try {
      const response = await api("post", `payments/create-payment/${userID}`, data);
      dispatch(isLoaded());
      console.log("In the service file", response);
      return response;
    } catch (err) {
      console.log(err);
      dispatch(isLoaded());
    }
  };
  export const filterPayment =
  (agencyID, userID, postID ) => async (dispatch) => {
    // const response = await api("patch", `posts/edit-post/${postID}`, data);
    // return response;
    // console.log(data, "ggh")
    dispatch(isLoading());
    try {
      const response = await api("get", `payments/filter-payments?userID=${userID}&postID=${postID}&agencyID=${agencyID}`);
      dispatch(isLoaded());
      console.log("In the service file", response);
      return response;
    } catch (err) {
      console.log(err);
      dispatch(isLoaded());
    }
  };





  export const getPostByyPostID =
  (postID ) => async (dispatch) => {
    // const response = await api("patch", `posts/edit-post/${postID}`, data);
    // return response;
    // console.log(data, "ggh")
    dispatch(isLoading());
    try {
      const response = await api("get", `/posts/${postID}`);
      dispatch(isLoaded());
      console.log("In the service file", response);
      return response;
    } catch (err) {
      console.log(err);
      dispatch(isLoaded());
    }
  };



  export const createContractsRequest =
  (userID, companyID, startDate, endDate, amount, post ) => async (dispatch) => {
    dispatch(isLoading());
    try {
      const data ={
        companyID:companyID,
        startDate: startDate,
        endDate:endDate,
        amount:amount,
        post:post,
      }
      const response = await api("post", `/contracts/create-contract/${userID}`, data);
      dispatch(isLoaded());
      console.log("In the service file of contracts", response);
      return response;
    } catch (err) {
      console.log(err);
      dispatch(isLoaded());
    }
  };

  // /contracts/create-contract/:userID