import React from "react";
import NotFoundImage from "../../../assets/postNotFound.png";

export const ItemNotFound = ({ children }) => {
  const containerStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    width: "100%",
  };

  const imageStyle = {
    width: "306px",
    height: "204px",
  };

  return (
    <div style={containerStyle}>
      <img src={NotFoundImage} alt="Not Found" style={imageStyle} />
      {children}
    </div>
  );
};
