import React from "react";
import { useLocation } from "react-router";
import PropTypes from "prop-types";
import { withRouter } from "../../../helpers/withRouter";
import { connect } from "react-redux";

export const TermsAndConditionsContainer = (props) => {
  const location = useLocation();
  const data = location.state;
  const containerStyle = {
    // display: "flex",
    // flexDirection: "column",
    // alignItems: "center",
    justifyContent: "center",
    // height: "30vh",
    // width: "100%",
    marginLeft: "50px",
    marginRight: "50px",
    fontSize: "16px",
    textAlign: "justify",
  };
// alert(props.user.role)
  return (
    <div style={containerStyle}>

{/* <p>{data} da</p>    */}
   {/* <h1 style={{ color: "#808080" }}>Terms and Conditions</h1> */}
      {/* <p>here goes conditions</p> */}
      {props.user.role === "Pharmacy" ? (
        <>
          <div style={{ marginTop: "40px" }}>
            <h2>Independent Contractor Agreement Etesal Health</h2>
            <p>
              {" "}
              This Independent Contractor Agreement ("Agreement") is made and
              entered into by and between the <b>pharmacy or facility</b>
              ("Contractor") and Etesal Health, a subsidiary of Payless Staffing
              and Consultants ("Company"), effective as of{" "}
              <b>
                <u>the date the job is posted.</u>
              </b>
            </p>
            <br />
            <p>
              <i>Services.</i> Contractor agrees to accept Staff Pharmacist
              services using etesalhealth.com from Company as an independent
              contractor. Contractor shall accept the services in a professional
              and workmanlike manner and in accordance with all applicable laws
              and regulations. Staff Pharmacist(s) will ensure compliance to all
              pharmacy laws and regulations, ensure the accurate dispensing of
              patient medications and consult patients and prescribers on
              pharmaceuticals safety and effectiveness.
            </p>
            <br />
            <p>
              <i>Term</i>. The term of this Agreement shall be for{" "}
              <b>time period of the job post being signed up for </b> unless
              earlier terminated as provided herein.
            </p>
            <br />
            <p>
              <i>Compensation</i>. Company shall collect from the Contractor the
              amount for the services rendered under this Agreement.
              <b>
                Payment shall be made using the preferred payment method
                immediately following posting for a job on EtesalHealth Platform
              </b>
              . Contractor shall be solely responsible for all taxes and other
              expenses related to the compensation.
            </p>
            <br />
            <p>
              <i>Expenses</i>. Contractor shall be responsible for all expenses
              incurred in connection with the services, including travel, meals,
              and lodging.
            </p>
            <br />
            <p>
              <i>Confidentiality</i>. Contractor agrees to maintain the
              confidentiality of all information disclosed by Company, its
              clients, or customers, and to use such information solely for the
              purpose of providing the services under this Agreement.
            </p>
            <br />
            <p>
              <i>Ownership</i> of Work Product. All work products, including
              reports, documents, and other materials created by Contractor in
              connection with the services, shall be the sole property of the
              Company. Contractor hereby assigns all right, title, and interest
              in such work product to Company.
            </p>
            <br />
            <p>
              Termination. Either party may terminate this Agreement at any
              time, with or without cause, upon
              <b> 14 days</b> written notice to the other party.
            </p>
            <br />
            <p>
              <i> Independent Contractor Status</i>. Contractor is an
              independent contractor and not an employee, partner, or agent of
              Company. Contractor shall be responsible for all taxes, insurance,
              and other obligations arising from the performance of the services
              under this Agreement.
            </p>
            <br />
            <p>
              <i>Governing Law.</i> This Agreement shall be governed by and
              construed in accordance with the laws of the state of New York,
              without regard to its conflicts of law provisions.
            </p>
            <br />
            <p>
              <i>Entire Agreement.</i> his Agreement constitutes the entire
              agreement between the parties with respect to the subject matter
              hereof and supersedes all prior and contemporaneous agreements and
              understandings, whether written or oral.
            </p>
            <br />
            <p>
              Etesal Health a Subsidiary of Payless Staffing and Consultants
              Inc. will be held harmless against all other lawsuits, claims or
              demands and liability of any kind during the term of the contract
              agreement for services provided by employee/candidates not
              employed under Etesal Health a Subsidiary of Payless Staffing and
              Consultants. There will be no lapse in providing workers
              compensation or liability insurance for the duration of the
              contract for services that Payless Staffing and Consultant
              candidates are not liable for. The Contractor also agrees to
              provide and maintain their own liability insurance in an adequate
              amount to cover third party claims for death or bodily injury
              arising from or in connection with the contract for services.
            </p>
            <br />
            <p>
              Contractor agrees that it will not contact the client of Etesal
              Health, a Subsidiary of Payless Staffing and Consultants directly
              or indirectly for two years after contract termination or
              completion. We are contractually obligated to keep a file copy of
              the licenses and credentials and copy of visa or resident status
              along with two professional references.
            </p>
            <br />
            <h3>Sexual Harassment Notice and Policy for Etesal Health</h3>
            <p>
              Etesal Health, a subsidiary of Payless Staffing and Consultants,
              is committed to providing a work environment free of sexual
              harassment or sexualized behavior. We strictly adhere to state and
              federal laws that prohibit all types of discrimination, including
              sexual harassment by employees, managers, vendors, and customers.
            </p>
            <br />
            <p>
              Sexual harassment is defined as any harassment based on a person's
              sex, such as unwelcome sexual advances, requests for sexual
              favors, and other verbal or physical harassment of a sexual
              nature, including offensive remarks about a person’s gender.
            </p>
            <br />
            <p>
              Both males and females can be victims of sexual harassment.
              Prohibited behavior includes any behavior that creates an
              offensive work environment [hostile environment] or that results
              in an adverse employment decision, such as the victim being fired
              or demoted (Quid pro Quo
            </p>
            <br />
            <p>Examples of sexual harassment include:</p>
            <br />
            <li> Unwanted physical contact or advances.</li>
            <li> Sexual jokes or comments.</li>
            <li>Displaying sexually explicit material.</li>
            <li>Offering employment benefits in exchange for sexual favors.</li>
            <li> Using gender-based insults or epithets.</li>
            <li>
              Spreading rumors or making false statements about an employee's
              personal lif
            </li>
            <br />
            <p>
              Any individual who violates this policy will be subject to
              disciplinary action, up to and including termination of
              employment. All employees, regardless of their gender, are
              required to report suspected sexual harassment directly to their
              supervisor, HR manager, or the owner of the company immediately in
              person, via phone call or e-mail.
            </p>
            <br />
            <p>
              Complaints of harassment will be documented and investigated as
              soon as possible. Our company will provide a resolution to the
              victim in writing within 60 days. Complaints will be maintained as
              confidential to prevent retaliation while the complaint is being
              researched and/or resolved. In addition, our company prohibits
              retaliation of any kind against an individual claiming harassment.
            </p>
            <br />
            <p>
              By signing this policy, I, the Contractor’s agent, acknowledge
              that I have read, understood, and agree to abide by this polic
            </p>
            <br />
            <h3></h3>
            <p>
              This Non-Disclosure Agreement (the "Agreement") is entered into as
              of the date signed by the parties below, between Etesal Health, a
              Subsidiary of Payless Staffing and Consultants ("Company"), and
              ("Contractor").
            </p>
            <br />
            purpose
            <br />
            <p>
              The purpose of this Agreement is to protect the confidentiality of
              certain proprietary or confidential information of the Company
              that the Contractor may access or be exposed to in the course of
              the engagement.
            </p>
            <br />
            Definition of Confidential Information
            <br />
            <p>
              "Confidential Information" means any information that is
              proprietary to the Company or its clients, not generally known to
              the public, and that the Contractor may access or be exposed to in
              the course of the engagement, including but not limited to, trade
              secrets, business plans, financial information, customer lists,
              marketing strategies, and any other information designated as
              confidential by the Company.
            </p>
            <br />
            Obligations of Contractor
            <br />
            <p>
              The Contractor agrees to hold all Confidential Information in
              strict confidence and to use such information only for the purpose
              of performing services for the Company. The Contractor shall not
              disclose or allow access to any Confidential Information to any
              third party, including its employees or subcontractors, without
              the prior written consent of the Company.
            </p>
            <br />
            Exceptions
            <br />
            <p>
              The obligations of confidentiality and non-disclosure set forth in
              this Agreement shall not apply to any information that: (a) is or
              becomes generally known to the public through no fault of the
              Contractor; (b) was in the Contractor's possession before
              receiving it from the Company and was not acquired directly or
              indirectly from the Company; (c) is rightfully obtained by the
              Contractor from a third party without any obligation of
              confidentiality; (d) is required to be disclosed by law,
              regulation, or court order, provided that the Contractor gives the
              Company prompt notice of such requirement to allow the Company to
              seek a protective order or other appropriate remedy.
            </p>
            <br />
            Ownership
            <br />
            <p>
              The Contractor acknowledges and agrees that all Confidential
              Information is the exclusive property of the Company and its
              clients, and that the Contractor shall not acquire any ownership
              interest or license to use any Confidential Information by virtue
              of this Agreement or by the performance of services for the
              Company.
            </p>
            <br />
            Term and Termination
            <br />
            <p>
              This Agreement shall remain in effect until terminated by either
              party upon written notice. The obligations of confidentiality and
              non-disclosure under this Agreement shall survive the termination
              of the engagement
            </p>
            <br />
            Remedies
            <br />
            <p>
              The Contractor acknowledges that any breach of this Agreement may
              cause irreparable harm to the Company and its clients, and that
              monetary damages may not be an adequate remedy. In the event of a
              breach or threatened breach of this Agreement, the Company shall
              be entitled to injunctive relief, specific performance, or any
              other available equitable relief, without the need to post a bond
              or prove actual damages.
            </p>
            <br />
            Governing Law and Jurisdiction
            <br />
            <p>
              This Agreement shall be governed by and construed in accordance
              with the laws of the state where the Company is headquartered,
              without giving effect to its conflict of laws provisions. Any
              dispute arising out of or in connection with this Agreement shall
              be resolved exclusively in the state or federal courts located in
              the same jurisdiction.
            </p>
            <br />
            Entire Agreement
            <br />
            <p>
              This Agreement contains the entire understanding between the
              parties with respect to the subject matter hereof and supersedes
              all prior or contemporaneous agreements or understandings, whether
              oral or written. This Agreement may not be amended or modified
              except in writing signed by both parties.
            </p>
            <br />
            
            <h3>HIPAA BUSINESS ASSOCIATE AGREEMENT</h3>
            <br />
            <p>
              This HIPAA Business Associate Agreement (“Agreement”) is entered
              into by and between Etesal Health, a subsidiary of Payless
              Staffing and Consultants (“Covered Entity”) and
              <u>“Contractor”</u> (“Business Associate”), as of the{" "}
              <b>date terms and conditions are approved and accepted.</b>
            </p>
            <br />
            <p>
              WHEREAS, Covered Entity and Business Associate may have entered
              into an agreement for Business Associate to provide certain
              services to Covered Entity, pursuant to which Business Associate
              may create, receive, maintain, or transmit Protected Health
              Information (“PHI”) on behalf of Covered Entity, and
            </p>
            <br />
            <p>
              HEREAS, Covered Entity and Business Associate intend to comply
              with the requirements of the Health Insurance Portability and
              Accountability Act of 1996, as amended, and the regulations
              promulgated thereunder, including, but not limited to, 45 C.F.R.
              Parts 160, 162, and 164, as amended from time to time, and the
              Health Information Technology for Economic and Clinical Health Act
              (“HITECH Act”) and the Omnibus Rule, collectively referred to
              herein as “HIPAA”;
            </p>
            <br />
            <p>NOW, THEREFORE, the parties agree as follows:</p>
            <br />
            <p>
              Definitions. Terms used, but not otherwise defined, in this
              Agreement shall have the same meaning as those terms in the HIPAA
              Rules. For purposes of this Agreement, the following terms shall
              have the following meanings: (a) “HIPAA Rules” shall mean the
              Privacy, Security, Breach Notification, and Enforcement Rules at
              45 C.F.R. Part 160 and Part 164, as amended from time to time,
              including the HITECH Act and the Omnibus Rule.
            </p>
            <br />
            <p>
              (b) “Protected Health Information” or “PHI” shall have the same
              meaning as the term “protected health information” in 45 C.F.R. §
              160.103, and shall be limited to the PHI created, received,
              maintained, or transmitted by Business Associate on behalf of
              Covered Entity pursuant to the Agreement.
            </p>
            <br />
            <p>
              (c) “Breach” shall have the same meaning as the term “breach” in
              45 C.F.R. § 164.402.
            </p>
            <br />
            <p>
              (d) “Electronic PHI” or “ePHI” shall have the same meaning as the
              term “electronic protected health information” in 45 C.F.R. §
              160.103.
            </p>
            <br />
            <p>Permitted Uses and Disclosures of PH</p>
            <br />
            <p>
              (a) Business Associate shall not use or disclose PHI other than as
              permitted or required by the Agreement or as Required by Law.
            </p>
            <br />
            <p>
              (b) Business Associate shall use appropriate safeguards to prevent
              disclosure of the PHI other than as provided for by this
              Agreement.
            </p>
            <br />
            <p>Obligations of Business Associate.</p>
            <br />
            <p>
              (a) Business Associate agrees to use, disclose, or request only
              the minimum necessary PHI to accomplish the purpose of the
              Agreement.
            </p>
            <br />
            <p>
              (b) Business Associate agrees to report to Covered Entity any use
              or disclosure of PHI not provided for by this Agreement of which
              it becomes aware, including breaches of unsecured PHI as required
              at 45 C.F.R. § 164.410, and any security incident or other
              incident involving ePHI of which it becomes aware.
            </p>
            <br />
            Obligations of Covered Entity.
            <p>
              (a) Covered Entity shall notify Business Associate of any
              limitation(s) in its notice of privacy practices of Covered Entity
              under 45 C.F.R. § 164.520, to the extent that such limitation(s)
              may affect Business Associate’s use or disclosure of PHI.
            </p>
            <br />
            <p>
              (b) Covered Entity shall notify Business Associate of any changes
              in, or revocation of, permission by an individual to use or
              disclose PHI, to the extent that such changes may affect Business
              Associate’s use or disclosure of PHI
            </p>
            <br />
            <p>Term and Termination:</p>
            <br />
            <p>
              This HIPAA Business Associate Agreement ("Agreement") shall become
              effective as of the date of execution by both parties and shall
              continue in effect for the term specified in Section 2 below.
            </p>
            <br />
            <p>
              Term. This Agreement shall remain in effect until the earlier of:
              (a) the date this Agreement is terminated by either party in
              accordance with Section 3 of this Agreement; or (b) the date that
              Covered Entity terminates its services with Business Associate
              that involve the disclosure of Protected Health Information
              ("PHI").
            </p>
            <br />
            <p>Termination.</p>
            <br />
            <p>
              (a) Termination by Mutual Agreement. This Agreement may be
              terminated by mutual written agreement of the Parties at any time.
            </p>
            <p></p>
            <br />
            <p>
              (b) Termination by Either Party. Either party may terminate this
              Agreement upon written notice to the other party if the other
              party breaches a material term of this Agreement and does not cure
              the breach within 30 days of receipt of notice of the breach.
            </p>
            <br />
            <p>
              (c) Obligations upon Termination. Upon termination of this
              Agreement, Business Associate shall return or destroy all PHI
              received from Covered Entity, or created, maintained, or received
              by Business Associate on behalf of Covered Entity, that Business
              Associate still maintains in any form. If Business Associate
              determines that returning or destroying the PHI is infeasible,
              Business Associate shall provide to Covered Entity notification of
              the conditions that make return or destruction infeasible. Upon
              mutual agreement of the Parties that return or destruction of PHI
              is infeasible, Business Associate shall extend the protections of
              this Agreement to such PHI and limit further uses and disclosures
              of such PHI to those purposes that make the return or destruction
              infeasible, for so long as Business Associate maintains such PHI.
            </p>
            <br />
            <p>
              (d) Survival. The obligations of Business Associate under this
              Section 3 shall survive the termination of this Agreement.
            </p>
            <br />
            <h3>Intellectual Property Agreement</h3>
            <p>
              This Intellectual Property Agreement (the "Agreement") is entered
              into by and between Etesal Health (the "Company") and Contractor
              (as mentioned before) (the "Contractor") engaged by the Company to
              provide services related to intellectual property.
            </p>
            <br />
            <p>
              Ownership of Intellectual Property. Any and all intellectual
              property created, discovered, or developed by the Contractor,
              either solely or in conjunction with others, during the term of
              their engagement with the Company shall be the exclusive property
              of the Company. Such intellectual property includes, but is not
              limited to, trademarks, trade secrets, patents, copyrights, and
              any related works of authorship.
            </p>
            <br />
            <p>
              Disclosure of Intellectual Property. The Contractor agrees to
              promptly and fully disclose any and all intellectual property
              created, discovered, or developed by the Contractor in the course
              of providing services to the Company, and to promptly assign and
              transfer all right, title, and interest in such intellectual
              property to the Company.
            </p>
            <p></p>
            <br />
            <p>
              Assistance in Intellectual Property Protection. The Contractor
              agrees to reasonably cooperate with the Company, at the Company's
              expense, to protect and enforce the Company's rights in any
              intellectual property developed by the Contractor during the
              course of their engagement with the Company.
            </p>
            <br />
            <p>
              Non-Disclosure of Confidential Information. The Contractor agrees
              to maintain the confidentiality of all confidential and
              proprietary information of the Company, including but not limited
              to trade secrets, customer lists, and business plans. The
              Contractor agrees not to disclose such information to any third
              party without the express written consent of the Company.
            </p>
            <br />
            <p>
              Term and Termination. This Agreement shall commence on the
              effective date and shall continue until the Contractor's
              engagement with the Company is terminated. The provisions of this
              Agreement shall survive the termination of the Contractor's
              engagement with the Company.
            </p>
            <br />
            <p>
              Governing Law. This Agreement shall be governed by and construed
              in accordance with the laws of the state in which the Company is
              located, without giving effect to its conflict of laws principles.
            </p>
            <br />
            <h3>
              Employee and Contractor Agreement - Etesal Health a Subsidiary of
              Payless Staffing and Consultants Inc
            </h3>
            <br />
            <p>
              <b>
                Etesal Health a Subsidiary of Payless Staffing and Consultants
                Inc{" "}
              </b>{" "}
              is a staffing platform (Federal Tax ID: 46-2354043). All
              pharmacists work as per-diem or are assigned to work on a
              temporary short term basis for contractors.
            </p>
            <br />
            <p>
              All freelancers of Payless Staffing and Consultants Inc. are
              signed, contracted and obligated to work under contract. Violation
              of the expectations listed below can result in the contractor or
              the freelancer being permanently removed from the platform.
            </p>
            <br />
            <p>
              Therefore, it is imperative that the following boundaries be in
              place:
            </p>
            <br />
            <ol type="1">
              <li>
                The contractor or other staff should refrain from discussion of
                our employee wages and their agreement with Payless Staffing and
                Consultants Inc.
              </li>
              <li>
                The contractor shall not engage our employees so as to hire them
                per say, thus causing them to breach their contract with Payless
                Staffing and Consultants Inc.
              </li>
              <li>
                The contractor will make the payment for services directly to
                Payless Staffing and Consultants Inc through the agreement upon
                payment method. Payless Staffing and Consultants Inc and our
                employees are solely responsible for reporting to the IRS for
                the labor we provide, and wages therefore paid. We and our
                employees are solely responsible for all withholding and
                deductions to be taken for the assigned labor. It is our
                expectation that the contractor will take responsibility only
                for their part in using this platform.
              </li>
              <li>
                Should there be an issue with one of our employees at the
                worksite, it is expected that the contractor will address this
                with Etesal Health - Payless Staffing and Consultants Inc, not
                directly with our employee. Our employees are expected to
                conduct themselves in a professional manner and be presentable
                for their working period.
              </li>
              <li>
                The contractor will be in compliance with the OSHA Act of the
                Department of Labor, thus guaranteeing our employee's safe and
                hazard free work site. As a per-diem platform, we are not
                responsible to cover leased or temporary employees under workers
                compensation or other insurance coverage on the job. It is our
                expectation that the pharmacy owner and permanent staff will
                treat our employee with courtesy. Harassment, intimidation,
                bullying and other unprofessional behavior on the job will not
                be tolerated. We reserve the right to remove our employee from
                such premises and not provide another hire until the problem is
                resolved. (State of New York Healthy Workplace Bill 2020).
              </li>
            </ol>
            <br />
            <p>
              This Non-Compete Agreement is entered into between Contractor
              (Business Client) and Payless Staffing and Consultants (Company)on
              the date terms and conditions are accepted and approved. Payless
              Staffing and Consultants is located at 1332 Commerce Avenue Suite
              #3, Bronx, NY 10461 and is represented by Shahzad Tariq in this
              agreement. WHEREAS, the Company is in the business of providing
              healthcare staffing to businesses. WHEREAS, the Contractor and
              Company have entered into a formal Employment agreement where the
              freelancer will perform duties related to pharmacy operations; and
            </p>
            <br />
            <p>
              WHEREAS, the Contractor agrees to the restrictions described
              herein as binding. THEREFORE, the Company and the Contractor agree
              to the following terms:
            </p>
            <br />
            <ol type="1">
              <li>
                NON-COMPETITION. For the entire duration of this agreement, and
                for 2 years after the Contractor’s relationship with the
                Contractor has been terminated for any reason, the Contractor
                will not hire the Freelancer as an employee, officer, director,
                partner, consultant, agent, owner or engage in any other
                capacity with the Contractor while being employed and 2 years
                after termination of relationship. This means that Freelancer
                must not perform any pharmacy operational duties or work with or
                for the Contractor for 2 years after termination of
                relationship.
              </li>
              <li>
                CONTRACTOR ACKNOWLEDGEMENTS. The Contractor acknowledges that
                they have been provided with the opportunity to negotiate this
                agreement, have had the opportunity to seek legal counsel before
                signing this agreement, and that the restrictions imposed are
                fair and necessary for the Company’s business interests.
                Finally, the Freelancer agrees that these restrictions are
                reasonable and do not constitute a threat to their livelihood.
              </li>
              <li>
                APPLICABLE LAW. This agreement and its interpretation shall be
                governed by the laws of New York.
              </li>
            </ol>
            <br />
            IN WITNESS WHEREOF, by approving and agreeing to the terms of this
            entire agreement, both parties agree to these terms and give their
            consent and authority to this agreement below.
            <br /> <br />
            <h1>
              Payments and Shift Coverage Policy for Etesal Health Platform
            </h1>
            <br />
            <ul>
              <li>
                Your payment method will be charged for the “Budget” amount you
                create once you create a job post using the platform “Etesal
                Health”.
              </li>
              <li>
                If no “Freelancer” candidate is approved for the job post or no
                “Freelancer” applies to the job post, then the “Budget” amount
                will be refunded back into your account.
              </li>
              <li>
                Once you approve a “Freelancer” Pharmacist, you will be charged
                on the payment method you indicated for the Budget amount posted
                on the Job Post.
              </li>
              <li>
                It will be Business Owner/Company Manager’s responsibility to
                report any discrepancy in shift coverage for the job post.
              </li>
              <li>
                Once a “Freelancer” Pharmacist is approved, they are scheduled
                to come in for that shift to the address indicated on the job
                post for the time period indicated on the job post.
              </li>
              <li>
                If the “Freelancer” Pharmacist does not show up within half an
                hour to one hour of the job post start time, it is the
                responsibility of the business owner/manager to make Etesal
                Health aware of the situation to take appropriate action.
              </li>
              <li>
                If there is a no show of the “Freelancer” Pharmacist after
                multiple attempts of reaching the Freelancer Pharmacist, Etesal
                Health will attempt to find a covering “Freelancer” Pharmacist
                for that day.
              </li>
              <li>
                If the Business Owner decides to cancel the job post with no
                Freelancer approved and scheduled for that job post, 72 hours
                before the scheduled job shift, then 100% of the job post budget
                will be refunded.
              </li>
              <li>
                If the Business Owner decides to cancel the job post with a
                Freelancer approved and scheduled for that job post, 72 hours
                before the scheduled job shift, then 90% of the job post budget
                will be refunded.
              </li>
              <li>
                If the Business Owner decides to cancel the job post with no
                Freelancer approved and scheduled for that job post, 72 hours
                before the scheduled job shift, then{" "}
                <b>100% of the job post budget</b> will be refunded
              </li>
              <li>
                If the Business Owner decides to cancel the job post with a
                Freelancer approved and scheduled for that job post, 72 hours
                before the scheduled job shift, then{" "}
                <b>90% of the job post budget</b> will be refunded.
              </li>
              <li>
                If the Business Owner decides to cancel the job post with no
                Freelancer approved and scheduled for that job post, within 72
                hours of the scheduled job shift, then{" "}
                <b>90% of the job post budget</b> will be refunded.
              </li>
              <li>
                If the Business Owner decides to cancel the job post with a
                Freelancer approved for that job post, within 48 hours of the
                scheduled job shift, then <b> 90% of the job post budget</b>
                will be refunded.
              </li>
              <li>
                If the Business Owner decides to cancel the job post with a
                Freelancer approved for that job post, within 24-36 hours of the
                scheduled job shift, then <b>50% of the job post budget</b>
                will be refunded.
              </li>
              <li>
                f the Business Owner decides to cancel the job post with a
                Freelancer approved for that job post, within 0-12 hours of the
                scheduled job shift, then <b>40% of the job post budget</b>
                will be refunded.
              </li>
            </ul>
          </div>
        </>
      ) : (
        <>
          <div style={{ marginTop: "40px" }}>
            <h2>Freelancer Independent Contractor Agreement Etesal Health</h2>
            <br />
            <p>
              This Independent Contractor Agreement ("Agreement") is made and
              entered into by and between <b>the Freelancer</b> ("Contractor")
              and Etesal Health, a subsidiary of Payless Staffing and
              Consultants ("Company"), effective as of{" "}
              <b>
                <u>the date the job is posted.</u>
              </b>
            </p>
            <br />
            <p>
              <i>Services</i>. Contractor agrees to provide Staff Pharmacist
              services to the Company as an independent contractor on
              etesalhealth.com platform. Contractor shall provide the services
              in a professional and workmanlike manner and in accordance with
              all applicable laws and regulations. Contractor will ensure
              compliance to all pharmacy laws and regulations, ensure the
              accurate dispensing of patient medications and consult patients
              and prescribers on pharmaceuticals safety and effectiveness.
            </p>
            <br />
            <p>
              <i>Term</i>. The term of this Agreement shall be for{" "}
              <b>time period of the job post being signed up for </b> unless
              earlier terminated as provided herein.
            </p>
            <br />
            <p>
              <i>Compensation</i>. Company shall pay Contractor{" "}
              <b>Job Post Amount minus 12%</b>
              for the services rendered under this Agreement.{" "}
              <b>
                {" "}
                Payment shall be made via direct deposit one week following the
                completion of the services.
              </b>
              . Contractor shall be solely responsible for all taxes and other
              expenses related to the compensation.
            </p>
            <br />
            <p>
              <i>Expenses</i>. Contractor shall be responsible for all expenses
              incurred in connection with the services, including travel, meals,
              and lodging.
            </p>
            <br />
            <p>
              <i>Confidentiality</i>. Contractor agrees to maintain the
              confidentiality of all information disclosed by Company, its
              clients, or customers, and to use such information solely for the
              purpose of providing the services under this Agreement.
            </p>
            <br />
            <p>
              <i>Ownership of Work Product.</i> All work products, including
              reports, documents, and other materials created by Contractor in
              connection with the services, shall be the sole property of the
              Company. Contractor hereby assigns all right, title, and interest
              in such work product to Company.
            </p>
            <br />
            <p>
              <i>Termination</i>. Either party may terminate this Agreement at
              any time, with or without cause, upon 14 days written notice to
              the other party. In the event of termination, no additional
              compensation apart from all services rendered prior to the
              termination date will be made.
            </p>
            <br />
            <p>
              <i>Independent Contractor Status</i>. Contractor is an independent
              contractor and not an employee, partner, or agent of Company.
              Contractor shall be responsible for all taxes, insurance, and
              other obligations arising from the performance of the services
              under this Agreement
            </p>
            <br />
            <p>
              <i>Governing Law</i>. This Agreement shall be governed by and
              construed in accordance with the laws of the state of New York,
              without regard to its conflicts of law provisions.
            </p>
            <br />
            <p>
              <i>Entire Agreement</i>. This Agreement constitutes the entire
              agreement between the parties with respect to the subject matter
              hereof and supersedes all prior and contemporaneous agreements and
              understandings, whether written or oral
            </p>
            <br />
            <p>
              Etesal Health a Subsidiary of Payless Staffing and Consultants
              Inc. will be held harmless against all other lawsuits, claims or
              demands and liability of any kind during the term of the contract
              agreement for services provided by employee/candidates not
              employed under Etesal Health a Subsidiary of Payless Staffing and
              Consultants. There will be no lapse in providing workers
              compensation or liability insurance for the duration of the
              contract for services that Payless Staffing and Consultant
              candidates are not liable for. The Contractor also agrees to
              provide and maintain their own liability insurance in an adequate
              amount to cover third party claims for death or bodily injury
              arising from or in connection with the contract for services.
            </p>
            <br />
            <p>
              Contractor agrees that it will not contact the Business Clients of
              Etesal Health, a Subsidiary of Payless Staffing and Consultants
              directly or indirectly for two years after contract termination or
              completion. We are contractually obligated to keep a file copy of
              the licenses and credentials and copy of visa or resident status
              along with two professional references.
            </p>
            <br />
            <h2>Sexual Harassment Notice and Policy for Etesal Health</h2>
            <br />
            <p>
              Etesal Health, a subsidiary of Payless Staffing and Consultants,
              is committed to providing a work environment free of sexual
              harassment or sexualized behavior. We strictly adhere to state and
              federal laws that prohibit all types of discrimination, including
              sexual harassment by employees, managers, vendors, and customers.
            </p>
            <br />
            <p>
              Sexual harassment is defined as any harassment based on a person's
              sex, such as unwelcome sexual advances, requests for sexual
              favors, and other verbal or physical harassment of a sexual
              nature, including offensive remarks about a person’s gender.
            </p>
            <br />
            <p>
              Both males and females can be victims of sexual harassment.
              Prohibited behavior includes any behavior that creates an
              offensive work environment [hostile environment] or that results
              in an adverse employment decision, such as the victim being fired
              or demoted (Quid pro Quo).
            </p>
            <br />
            <p>Examples of sexual harassment include:</p>
            <br />
            <ul>
              <li> Unwanted physical contact or advances.</li>
              <li> Sexual jokes or comments.</li>
              <li>Displaying sexually explicit material.</li>
              <li>
                Offering employment benefits in exchange for sexual favors.
              </li>
              <li>Using gender-based insults or epithets.</li>
              <li>
                Spreading rumors or making false statements about an employee's
                personal life
              </li>
            </ul>
            <br />
            <p>
              Any individual who violates this policy will be subject to
              disciplinary action, up to and including termination of
              employment. All employees, regardless of their gender, are
              required to report suspected sexual harassment directly to their
              supervisor, HR manager, or the owner of the company immediately in
              person, via phone call or e-mail.
            </p>
            <br />
            <p>
              Any individual who violates this policy will be subject to
              disciplinary action, up to and including termination of
              employment. All employees, regardless of their gender, are
              required to report suspected sexual harassment directly to their
              supervisor, HR manager, or the owner of the company immediately in
              person, via phone call or e-mail.
            </p>
            <br />
            <p>
              By signing this policy, I, the Contractor’s agent, acknowledge
              that I have read, understood, and agree to abide by this policy.
            </p>
            <br />
            <h5>Non-Disclosure Agreement (NDA)</h5>
            <br />
            <p>
              This Non-Disclosure Agreement (the "Agreement") is entered into as
              of the date signed by the parties below, between Etesal Health, a
              Subsidiary of Payless Staffing and Consultants ("Company"), and
              [Independent Contractor Name] ("Contractor").
            </p>
            <br />
            purpose
            <br />
            <p>
              The purpose of this Agreement is to protect the confidentiality of
              certain proprietary or confidential information of the Company
              that the Contractor may access or be exposed to in the course of
              the engagement.
            </p>
            <br />
            Definition of Confidential Information
            <br />
            <p>
              "Confidential Information" means any information that is
              proprietary to the Company or its clients, not generally known to
              the public, and that the Contractor may access or be exposed to in
              the course of the engagement, including but not limited to, trade
              secrets, business plans, financial information, customer lists,
              marketing strategies, and any other information designated as
              confidential by the Company.
            </p>
            <br />
            Obligations of Contractor
            <br />
            <p>
              The Contractor agrees to hold all Confidential Information in
              strict confidence and to use such information only for the purpose
              of performing services for the Company. The Contractor shall not
              disclose or allow access to any Confidential Information to any
              third party, including its employees or subcontractors, without
              the prior written consent of the Company.
            </p>
            <br />
            Exceptions
            <br />
            <p>
              The obligations of confidentiality and non-disclosure set forth in
              this Agreement shall not apply to any information that: (a) is or
              becomes generally known to the public through no fault of the
              Contractor; (b) was in the Contractor's possession before
              receiving it from the Company and was not acquired directly or
              indirectly from the Company; (c) is rightfully obtained by the
              Contractor from a third party without any obligation of
              confidentiality; (d) is required to be disclosed by law,
              regulation, or court order, provided that the Contractor gives the
              Company prompt notice of such requirement to allow the Company to
              seek a protective order or other appropriate remedy.
            </p>
            <br />
            Ownership
            <br />
            <p>
              The Contractor acknowledges and agrees that all Confidential
              Information is the exclusive property of the Company and its
              clients, and that the Contractor shall not acquire any ownership
              interest or license to use any Confidential Information by virtue
              of this Agreement or by the performance of services for the
              Company.
            </p>
            <br />
            Term and Termination
            <br />
            <p>
              This Agreement shall remain in effect until terminated by either
              party upon written notice. The obligations of confidentiality and
              non-disclosure under this Agreement shall survive the termination
              of the engagement
            </p>
            <br />
            Remedies
            <br />
            <p>
              The Contractor acknowledges that any breach of this Agreement may
              cause irreparable harm to the Company and its clients, and that
              monetary damages may not be an adequate remedy. In the event of a
              breach or threatened breach of this Agreement, the Company shall
              be entitled to injunctive relief, specific performance, or any
              other available equitable relief, without the need to post a bond
              or prove actual damages.
            </p>
            <br />
            Governing Law and Jurisdiction
            <br />
            <p>
              This Agreement shall be governed by and construed in accordance
              with the laws of the state where the Company is headquartered,
              without giving effect to its conflict of laws provisions. Any
              dispute arising out of or in connection with this Agreement shall
              be resolved exclusively in the state or federal courts located in
              the same jurisdiction.
            </p>
            <br />
            Entire Agreement
            <br />
            <p>
              This Agreement contains the entire understanding between the
              parties with respect to the subject matter hereof and supersedes
              all prior or contemporaneous agreements or understandings, whether
              oral or written. This Agreement may not be amended or modified
              except in writing signed by both parties.
            </p>
            <br/>
            <h3>HIPAA BUSINESS ASSOCIATE AGREEMENT</h3>
            <br />
            <p>
              This HIPAA Business Associate Agreement (“Agreement”) is entered
              into by and between Etesal Health, a subsidiary of Payless
              Staffing and Consultants (“Covered Entity”) and “Contractor”
              (“Business Associate”), as of t
              <b>he date terms and conditions are approved and accepted.</b>
            </p>
            <br />
            <p>
              WHEREAS, Covered Entity and Business Associate may have entered
              into an agreement for Business Associate to provide certain
              services to Covered Entity, pursuant to which Business Associate
              may create, receive, maintain, or transmit Protected Health
              Information (“PHI”) on behalf of Covered Entity, and
            </p>
            <br />
            <p>
              WHEREAS, Covered Entity and Business Associate intend to comply
              with the requirements of the Health Insurance Portability and
              Accountability Act of 1996, as amended, and the regulations
              promulgated thereunder, including, but not limited to, 45 C.F.R.
              Parts 160, 162, and 164, as amended from time to time, and the
              Health Information Technology for Economic and Clinical Health Act
              (“HITECH Act”) and the Omnibus Rule, collectively referred to
              herein as “HIPAA”;
            </p>
            <br />
            <p>NOW, THEREFORE, the parties agree as follows:</p>
            <br />
            <p>
              Definitions. Terms used, but not otherwise defined, in this
              Agreement shall have the same meaning as those terms in the HIPAA
              Rules. For purposes of this Agreement, the following terms shall
              have the following meanings: (a) “HIPAA Rules” shall mean the
              Privacy, Security, Breach Notification, and Enforcement Rules at
              45 C.F.R. Part 160 and Part 164, as amended from time to time,
              including the HITECH Act and the Omnibus Rule.
            </p>
            <br />
            <p>
              (b) “Protected Health Information” or “PHI” shall have the same
              meaning as the term “protected health information” in 45 C.F.R. §
              160.103, and shall be limited to the PHI created, received,
              maintained, or transmitted by Business Associate on behalf of
              Covered Entity pursuant to the Agreement.
            </p>
            <br />
            <p>
              (c) “Breach” shall have the same meaning as the term “breach” in
              45 C.F.R. § 164.402.
            </p>
            <br />
            <p>
              (d) “Electronic PHI” or “ePHI” shall have the same meaning as the
              term “electronic protected health information” in 45 C.F.R. §
              160.103.
            </p>
            <br />
            <p>
              (d) “Electronic PHI” or “ePHI” shall have the same meaning as the
              term “electronic protected health information” in 45 C.F.R. §
              160.103.
            </p>
            <br />
            <p>
              (b) Business Associate shall use appropriate safeguards to prevent
              Use or. disclosure of the PHI other than as provided for by this
              Agreement.
            </p>
            <br />
            <p>
              Obligations of Business Associate. (a) Business Associate agrees
              to use, disclose, or request only the minimum necessary PHI to
              accomplish the purpose of the Agreement.
            </p>
            <br />
            <p>
              (b) Business Associate agrees to report to Covered Entity any use
              or disclosure of PHI not provided for by this Agreement of which
              it becomes aware, including breaches of unsecured PHI as required
              at 45 C.F.R. § 164.410, and any security incident or other
              incident involving ePHI of which it becomes aware
            </p>
            <br />
            <p>
              Obligations of Covered Entity. (a) Covered Entity shall notify
              Business Associate of any limitation(s) in its notice of privacy
              practices of Covered Entity under 45 C.F.R. § 164.520, to the
              extent that such limitation(s) may affect Business Associate’s use
              or disclosure of PHI.
            </p>
            <br />
            <p>
              (b) Covered Entity shall notify Business Associate of any changes
              in, or revocation of, permission by an individual to use or
              disclose PHI, to the extent that such changes may affect Business
              Associate’s Use or disclosure of PHI.
            </p>
            <br />
            <p>Term and Termination:</p>
            <br />
            <p>
              This HIPAA Business Associate Agreement ("Agreement") shall become
              effective as of the date of execution by both parties and shall
              continue in effect for the term specified in Section 2 below.
            </p>
            <br />
            <p>
              Term. This Agreement shall remain in effect until the earlier of:
              (a) the date this Agreement is terminated by either party in
              accordance with Section 3 of this Agreement; or (b) the date that
              Covered Entity terminates its services with Business Associate
              that involve the se or disclosure of Protected Health Information
              ("PHI").
            </p>
            <br />
            
            <p>Termination:</p>
            <br/>
            <p>(a) Termination by Mutual Agreement. This Agreement may be terminated by mutual written
agreement of the Parties at any time.</p>
            <br />
            <p>
              (b) Termination by Either Party. Either party may terminate this
              Agreement upon written notice to the other party if the other
              party breaches a material term of this Agreement and does not cure
              the breach within 30 days of receipt of notice of the breach
            </p>
            <br />
            <p>
              (c) Obligations upon Termination. Upon termination of this
              Agreement, Business Associate shall return or destroy all PHI
              received from Covered Entity, or created, maintained, or received
              by Business Associate on behalf of Covered Entity, that Business
              Associate still maintains in any form. If Business Associate
              determines that returning or destroying the PHI is infeasible,
              Business Associate shall provide to Covered Entity notification of
              the conditions that make return or destruction infeasible. Upon
              mutual agreement of the Parties that return or destruction of PHI
              is infeasible, Business Associate shall extend the protections of
              this Agreement to such PHI and limit further uses and disclosures
              of such PHI to those purposes that make the return or destruction
              infeasible, for so long as Business Associate maintains such PHI.
            </p>
            <br />
            <p>
              (d) Survival. The obligations of Business Associate under this
              Section 3 shall survive the termination of this Agreement.
            </p>
            <br />
            <h3>Intellectual Property Agreement</h3>
            <p>
              This Intellectual Property Agreement (the "Agreement") is entered
              into by and between Etesal Health (the "Company") and Freelancer
              (as mentioned before) (the "Contractor") engaged by the Company to
              provide services related to intellectual property.
            </p>
            <br />
            <p>
              Ownership of Intellectual Property. Any and all intellectual
              property created, discovered, or developed by the Contractor,
              either solely or in conjunction with others, during the term of
              their engagement with the Company shall be the exclusive property
              of the Company. Such intellectual property includes, but is not
              limited to, trademarks, trade secrets, patents, copyrights, and
              any related works of authorship.
            </p>
            <br />
            <p></p>
            <br />
            <p>
              Disclosure of Intellectual Property. The Contractor agrees to
              promptly and fully disclose any and all intellectual property
              created, discovered, or developed by the Contractor in the course
              of providing services to the Company, and to promptly assign and
              transfer all right, title, and interest in such intellectual
              property to the Company.
            </p>
            <br />
            <p>
              Assistance in Intellectual Property Protection. The Contractor
              agrees to reasonably cooperate with the Company, at the Company's
              expense, to protect and enforce the Company's rights in any
              intellectual property developed by the Contractor during the
              course of their engagement with the Company.
            </p>
            <br />
            <p>
              Non-Disclosure of Confidential Information. The Contractor agrees
              to maintain the confidentiality of all confidential and
              proprietary information of the Company, including but not limited
              to trade secrets, customer lists, and business plans. The
              Contractor agrees not to disclose such information to any third
              party without the express written consent of the Company.
            </p>
            <br />
            <p>
              Term and Termination. This Agreement shall commence on the
              effective date and shall continue until the Contractor's
              engagement with the Company is terminated. The provisions of this
              Agreement shall survive the termination of the Contractor's
              engagement with the Company.
            </p>
            <br />
            <p>
              Governing Law. This Agreement shall be governed by and construed
              in accordance with the laws of the state in which the Company is
              located, without giving effect to its conflict of laws principles.
            </p>
            <br />
            <h3>Non-Compete Agreement - Freelancer</h3>
            <br />
            <p>
              This Non-Compete Agreement is entered into between the Freelancer
              (Freelancer) and Etesal Health a subsidiary of Payless Staffing
              and Consultants (Company) on the date terms and conditions are
              accepted and approved. Payless Staffing and Consultants is located
              at 1332 Commerce Avenue Suite #3, Bronx, NY 10461 and is
              represented by Shahzad Tariq in this agreement. WHEREAS, the
              Company is in the business of providing healthcare staffing to
              businesses. WHEREAS, the Freelancer and the Company have entered
              into a formal Employment agreement where the Freelancer will
              perform duties related to their position as a Staff Pharmacist;
              and WHEREAS, the Freelancer agrees to the restrictions described
              herein as binding.
            </p>
            <br />
            <p>
              THEREFORE, the Company and the Freelancer agree to the following
              terms:
            </p>
            <br />
            <ol type="1">
              <li>
                NON-COMPETITION. For the entire duration of this agreement, and
                for 2 years after the Company’s relationship with the Freelancer
                has been terminated for any reason, the Freelancer will not work
                as an employee, officer, director, partner, consultant, agent,
                owner or engage in any other capacity with the Business Client
                while being employed and 2 years after termination of
                relationship.
              </li>
              <li>
                FREELANCER ACKNOWLEDGEMENTS. The Freelancer acknowledges that
                they have been provided with the opportunity to negotiate this
                agreement, have had the opportunity to seek legal counsel before
                signing this agreement, and that the restrictions imposed are
                fair and necessary for the Company’s business interests.
                Finally, the Freelancer agrees that these restrictions are
                reasonable and do not constitute a threat to their livelihood.
              </li>
              <li>
                APPLICABLE LAW. This agreement and its interpretation shall be
                governed by the laws of New York.
              </li>
            </ol>
            <br />
            IN WITNESS WHEREOF, by approving and agreeing to the terms of this
            entire agreement, both parties agree to these terms and give their
            consent and authority to this agreement below.
            <br />
            <h4>
              <u>Role and Responsibilities for Freelancer Staff Pharmacist</u>
            </h4>
            <br />
            <ul>
              <li>
                Applicant must love helping people and be dedicated to
                delivering an incredible customer experience.
              </li>
              <li>
                Must have enthusiasm and strong knowledge of pharmacy,
                compounding and OTC products.
              </li>
              <li>Must have knowledge of at least 1 Pharmacy Software.</li>
              <li>
                Able to build relationships with a vast range of patients.
              </li>
              <li>
                Must be mature, responsible, motivated and possess superior
                customer service skills.
              </li>
              <li>
                Execute special projects, additional duties and other
                responsibilities as assigned.
              </li>
            </ul>
            <br />
            <h4>
              The job duties and responsibilities of the staff pharmacist also
              include:
            </h4>
            <br />
            <ol type="1">
              <li>
                Dispensing medications to patients accurately and efficiently.
              </li>
              <li>
                Collaborating with physicians and other healthcare providers to
                ensure appropriate medication therapy management for patients.
              </li>
              <li>
                Answering patient questions about their medications and
                potential drug interactions.
              </li>
              <li>
                Monitoring medication adherence and making recommendations for
                adjustments to medication regimens as needed.
              </li>
              <li>
                Providing patient education on medication usage, side effects,
                and proper storage
              </li>
              <li>
                Overseeing and mentoring pharmacy technicians and interns.
              </li>
              <li>
                Ensuring compliance with state and federal laws and regulations,
                including record keeping and reporting requirements
              </li>
              <li>
                Keeping up-to-date on industry developments and new medication
              </li>
              <li>Maintaining a clean and organized pharmacy environment.</li>
              <li>
                . Providing exceptional customer service to patients and
                healthcare providers
              </li>
              <li>
                Collaborating with other healthcare providers to manage patient
                care, including coordinating prescription refills and prior
                authorizations
              </li>
            </ol>
            <br />
            <h4>Pharmacist License In Good Standing Authorization</h4>
            <br />
            <p>
              By Agreeing and approving the terms and conditions of this
              agreement, the Freelancer Pharmacist agrees that their license is
              in good standing.
            </p>
            <br />
            <p>
              By Agreeing and approving the terms and conditions of this
              agreement, the Freelancer Pharmacist also agrees that they do not
              have any current or past violations under their license.
            </p>
            <br />
            <p>
              By Agreeing and approving the terms and conditions of this
              agreement, the Freelancer Pharmacist must report any of the
              following health conditions to Etesal Health subsidiary of Payless
              Staffing and Consultants Inc
            </p>
            <br />
            <h3>Health Conditions:</h3>
            <ul>
              <li>Autism</li>
              <li>
                Autoimmune disorder, for example, lupus fibromyalgia, rheumatoid
                arthritis or HIV/AIDS
              </li>
              <li>blind or low vision</li>
              <li>cance</li>
              <li>cardiovascular or heart disease</li>
              <li>celiac disease</li>
              <li>cerebral palsy</li>
              <li>deaf or hard of hearing</li>
              <li>depression or anxiety</li>
              <li>diabetes</li>
              <li>epilepsy</li>
              <li>
                gastrointestinal disorders, for example, Crohn's disease or
                irritable bowel syndrome
              </li>
              <li>intellectual disability</li>
              <li>missing limbs or partially missing limbs</li>
              <li>
                nervous system condition for example, migraine headaches
                Parkinson's disease or multiple sclerosis
              </li>
              <li>
                psychiatric condition, for example, bipolar disorder,
                schizophrenia, PTSD, or major depression
              </li>
            </ul>
            <br />
            <h1>
              Payments and Shift Coverage Policy for Etesal Health Platform
            </h1>
            <br />
            <ul>
              <li>
                Only apply for Job Posts/Shifts that you will be able to cover.
              </li>
              <li>
                When a Freelancer applies for the job post, job status will
                change to “Pending” after which this must be approved by the
                Pharmacy to change the status to “Approved” if the pharmacy
                owner approves the Freelancer for the shift. Upon approving the
                Freelancer, you are approved to take that shift.
              </li>
              <li>
                Once a “Freelancer” Pharmacist is approved, you are scheduled to
                come in for that shift to the address indicated on the job post
                for the time period indicated on the job post.
              </li>
              <li>
                Any job shift that you see status is approved, you, the
                “Freelancer” are signed up to cover that shift at the time of
                the posted shift at the location of the job post.
              </li>
              <li>
                {" "}
                Payment for any covered “Approved” shift will be made via direct
                deposit every 1-2 weeks.
              </li>
              <li>
                The payment amount to the freelancer will be the ‘Job Post
                amount minus 12%’ for the job shift.
              </li>
              <li>
                If for any circumstances you are not able to cover a job post
                for 1 day coverage that you are approved for, then you have to
                notify Etesal Health immediately 72 hours before the job post is
                scheduled for.
              </li>
              <li>
                If for any circumstances you are not able to cover a job post
                for 1 day coverage that you are approved for, more than one
                time, then you will be removed from the platform.{" "}
                <b>
                  It is advised to only apply for jobs that you will be able to
                  cover.
                </b>
              </li>
              <li>
                If the job post is for 2-5 days coverage and you are approved
                for the job post, please notify 2 weeks in advance before the
                job post is scheduled to Etesal Health. {" "}
                <b>
                   It is advised to only apply for jobs that you will be able to
                  cover.
                </b>
              </li>
              <li>
                It will be the Freelancer’s responsibility to report any
                discrepancy in shift coverage for the job post to Etesal Health
                and Business Owner.
              </li>
              <li>
                If the “Freelancer” Pharmacist is having trouble with reaching
                the location at the time indicated then the freelancer has to
                contact the Business immediately to not breach the contract and
                get a penalty.
              </li>
              <li>
                {" "}
                If there is a no show of the “Freelancer” Pharmacist to the job
                post location for an Approved Shift after multiple attempts of
                reaching the Freelancer Pharmacist, the freelancer will be
                disqualified from the Etesal Health platform
              </li>
            </ul>
          </div>
        </>
      )}
    </div>
  );
};
TermsAndConditionsContainer.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  isAuth: PropTypes.bool.isRequired,
  isSuccess: PropTypes.bool.isRequired,
  isError: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
  addPostRequest: PropTypes.func.isRequired,
  removePostRequest: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  isLoading: state.shared.loading.isLoading,
  isAuth: state.auth.signin.isAuth,
  isError: state.auth.signup.isError,
  isSuccess: state.auth.signup.isSuccess,
  errorMessage: state.auth.signup.errorMessage,
  user: state.auth.signin.user,
});

export const TermsAndConditions = withRouter(
  connect(mapStateToProps, {
  
  })(TermsAndConditionsContainer)
);
