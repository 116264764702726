import React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import style from "./index.module.scss";
import { Col } from "antd";
import { Form, Input, Button, Checkbox, message } from "antd";
import Icon from "@ant-design/icons";
import TextArea from "antd/lib/input/TextArea";
import { sendSupportEmail } from "../Service";

import { connect } from "react-redux";

import { withRouter } from "./../../../../helpers/withRouter";

const Support = (props) => {
  const [messageApi, contextHolder] = message.useMessage();

  // const history = useNavigate();
  // const [form] = Form.useForm();
  // const [isInitialLoading, setIsInitialLoading] = useState(true);
 
  //   const success = () => {
  //     messageApi.open({
  //       type: "success",
  //       content: "Message has been successfully sent",
  //       duration: 2,
  //     });
  //     //reload company
  //     // setIsInitialLoading(true);
  //   };

 

  const onComplete = (values) => {
    try {
      props.sendSupportEmail({ ...values });
    message.info("Message has been successfully sent")
    } catch (err) {
      console.log("there is an error", err);
    }
  };

  return (
    <div>
      <div
        style={{
          float: "left",
          width: "20%",
          marginTop: "8%",
          marginLeft: "5%",
          font: "14px",
        }}
      >
        <h2>Tech Support</h2>
        <p> Email: etesalhealth@gmail.com</p>
        <p> Phone: 929 529 2819</p>
        <hr />
        <p>Days Open Mon - Fri</p>
        <p>Hours: 9am-7pm </p>
      </div>
      <div>
        <Form className={style.signinFormWrapper} onFinish={onComplete}>
          <div>
            <h1
              style={{
                marginLeft: "5%",
                marginRight: "5%",
                marginTop: "3%",
                marginBottom: "3%",
              }}
            >
              Customer Support
            </h1>
          </div>

          <Form.Item
            name="name"
            rules={[{ required: true, message: "Please input your name!" }]}
            style={{
              color: "rgba(0,0,0,.25)",
              marginLeft: "5%",
              marginRight: "5%",
            }}
          >
            <Input
              prefix={<Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />}
              type="text"
              placeholder="Full Name"
            />
          </Form.Item>
          <Form.Item
            name="email"
            rules={[
              { required: true, message: "Please input your email!" },
              { type: "email", message: "The input is not valid E-mail!" },
            ]}
            style={{
              color: "rgba(0,0,0,.25)",
              marginLeft: "5%",
              marginRight: "5%",
            }}
          >
            <Input
              prefix={<Icon type="mail" style={{ color: "rgba(0,0,0,.25)" }} />}
              placeholder={props.user?.email}
              label="Email"
            />
          </Form.Item>

          {/* <Form.Item
            name="type"
            rules={[
              { required: true, message: "Please input your type!" },
              { type: "text" },
            ]}
            style={{
              color: "rgba(0,0,0,.25)",
              marginLeft: "5%",
              marginRight: "5%",
            }}
          >
            <Input prefix={<Icon type="text" />} placeholder="Select" />
          </Form.Item> */}

          <Form.Item
            name="message"
            rules={[
              { required: true, message: "Please input your message!" },
              { type: "text" },
            ]}
            style={{
              // color: "rgba(0,0,0,.25)",
              marginLeft: "5%",
              marginRight: "5%",
            }}
          >
            <TextArea
              name="message"
              placeholder="Type your messsage here!"
              cols="79"
              rows="4"
              // style={{ color: "rgba(0,0,0,.25)", marginRight: "5%" }}
            />
          </Form.Item>

          <Form.Item>
            <Button
              type="danger"
              className={style.button}
              htmlType="submit"
              // htmlType="submit"
            >
              Send Message
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  isLoading: state.shared.loading.isLoading,
  isAuth: state.auth.signin.isAuth,
  isError: state.auth.signup.isError,
  isSuccess: state.auth.signup.isSuccess,
  errorMessage: state.auth.signup.errorMessage,
  user: state.auth.signin.user,
});

export const SupportContainer = withRouter(
  connect(mapStateToProps, { sendSupportEmail })(Support)
);
