import React from "react";
import { Form, Input, Button } from "antd";
import Icon from "@ant-design/icons";
import style from "./index.module.scss";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { getAuthUserData } from "../../../../../helpers/auth";

export const SigninForm = (props) => {
  const onFinish = (values) => {
    props.onSubmit(values);
    // alert(props.isLoading)
  
  };

  return (
    <Form
      onFinish={onFinish}
      className={style.signinForm}
      size="large"
      layout="vertical"
    >
      <h1 className={style.authHeader}>Sign In</h1>

      {/* import { useEffect } from "react";

export const SigninForm = (props) => {
  const history= useNavigate();
  const isAuthUser = getAuthUserData();


  // 
   const onFinish = (values) => {
    props.onSubmit(values);
   

  };

  return (
    <Form onFinish={onFinish} className={style.signinForm}>
      <h1 className={style.authHeader}>Sign in</h1> */}
      <Form.Item
        name="email"
        rules={[
          { required: true, message: "Please input your email!" },
          { type: "email", message: "The input is not valid E-mail!" },
        ]}
        label="Email"
      >
        <Input
          prefix={<Icon type="mail" style={{ color: "rgba(0,0,0,.25)" }} />}
          placeholder="Enter your email"
        />
      </Form.Item>
      <Form.Item
        {...(props.isError && {
          help: props.errorMessage,
          validateStatus: "error",
        })}
        name="password"
        rules={[
          { required: true, message: "Please input your password!" },
          {
            min: 6,
            message: "Password must be at least 6 characters long",
          },
        ]}
        label="Password"
      >
        <Input
          prefix={<Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />}
          type="password"
          placeholder="Enter your password"
        />
      </Form.Item>
      <Form.Item>
        <Button
          type="danger"
          loading={props.isLoading}
          className={style.signin_btn}
          htmlType="submit"
        >
          Sign in
        </Button>
        <div className={style.singupLinks}>
          Don't have an account yet?{" "}
          <Link to="/signup" className={style.signup_link}>
            Create account
          </Link>
        </div>
        {/* <div className={style.singupLinks}>
          <Link to="/restore-password" style={{ color: "#f44336" }}>
            Forgot your password
          </Link>
        </div> */}
      </Form.Item>
    </Form>
  );
};
const mapStateToProps = (state) => ({
  isLoading: state.auth.signin.isLoading,
  isAuth: state.auth.signin.isAuth,
  isError: state.auth.signin.isError,
  isProfileCompleted: state.auth.signin?.isProfileCompleted,
  errorMessage: state.auth.signin.errorMessage,
});

export const App = connect(mapStateToProps)(SigninForm);
