import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { withRouter } from "../../../../helpers/withRouter";
import style from "./index.module.scss";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router";
import {
  getCandidatesRequest,
  getPostByIdRequest,
  updateCandidateRequest,
  getMyScheduleRequest,
  updateContracts,
  getContractsByFilter,
} from "../service";
import { CandidateCard } from "../components/CandidateCard";
import { Loader } from "../../../Common/Loader";
import { ItemNotFound } from "../../../Common/ItemNotFound";

const Candidates = (props) => {
  const { id } = useParams();
  console.log("ID heree", id);
  const history = useNavigate();

  const [candidates, setCandidates] = useState(null);
  const [post, setPost] = useState(null);
  const [postDetail, setPostDetail] = useState([]);

  useEffect(() => {
    // alert(props?.user?.company?._id)
    const getPostRequest = async (id) => {
      const response = await props.getPostByIdRequest(id);
      // console.log(response?.data?.post?.postedBy?.company?._id, "sji")
      setPostDetail(response?.data?.post);
      console.log(response, "ID for post");
    };
    getPostRequest(id);
  }, []);

  const onPharmacyFeedbackUpdate = async (caandidatesList, candidateID) => {
    const data = caandidatesList?.map((candidate) => {
      if (candidate.candidate._id === candidateID) {
        return candidate.isPharmmcyFeedback;
      }
    });
    return data;
  };
  const getCandidates = async () => {
    const response = await props.getCandidatesRequest(id);
    setPost(response.data.post);
    setCandidates(response.data.post.candidates);
  };
  const onMarkInComplete = async (postID, caandidates, id) => {
    const data = caandidates?.map((candidate) => {
      if (candidate.candidate._id === id) {
        candidate.isComplete = false;
      }
      return candidate;
    });

    const data1 = {
      candidates: data,
    };
    const response = await props.updateCandidateRequest(postID, data1);
    console.log(response);

    const contract = await props.getContractsByFilter(
      postID,
      postDetail?.postedBy?.company?._id,
      id
    );
    console.log(contract?.data.contarct, "contract");
    let contractID = "";
    contract?.data.contarct?.map((user) => {
      contractID = user._id;
      return contractID;
    });
    // console.log(contractID);
    const updatedData = {
      status: "In Progress",
    };
    const contracts = await props.updateContracts(contractID, updatedData);
    console.log("In Progress ", contracts);

    getCandidates();
  };

  const onPharmacyFeedback = async (postID, caandidates, id, ) => {
    const data = caandidates?.map((candidate) => {
      if (candidate.candidate._id === id) {
        candidate.isPharmmcyFeedback = true;
      }
      return candidate;
    });

    const data1 = {
      candidates: data,
    };
    const response = await props.updateCandidateRequest(postID, data1);
    console.log(response);
    history('/all-posts')
    history(`/candidates/${postID}`)
  };
  useEffect(() => {
    getCandidates();
  }, [id]);

  const onLearnMoreClick = (id, status) => {
    history(`/candidates/information/${id}`, { state: post });
  };

  return (
    <div className={style.container}>
      {props.isLoading ? (
        <Loader />
      ) : candidates?.length > 0 ? (
        candidates.map((candidate) => (
          <CandidateCard
            candidate={candidate}
            postName={post?.name}
            post={postDetail}
            candId = {id}
            // post={posts}
            size={props.size}
            onLearnMoreClick={onLearnMoreClick}
            onMarkInComplete={onMarkInComplete}
            userID={props.user?.id}
            onPharmacyFeedback={onPharmacyFeedback}
            onPharmacyFeedbackUpdate={onPharmacyFeedbackUpdate}
            getCandidatesRequest = {getCandidates}
            // onPharmacyFeedbackUpdate= {onPharmacyFeedbackUpdate}
          />
        ))
      ) : (
        <ItemNotFound>
          <h1 style={{ color: "#808080" }}>No candidates has applied yet.</h1>
        </ItemNotFound>
      )}
    </div>
  );
};

Candidates.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  isAuth: PropTypes.bool.isRequired,
  isSuccess: PropTypes.bool.isRequired,
  isError: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
  getCandidatesRequest: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  isLoading: state.shared.loading.isLoading,
  isAuth: state.auth.signin.isAuth,
  isError: state.auth.signup.isError,
  isSuccess: state.auth.signup.isSuccess,
  errorMessage: state.auth.signup.errorMessage,
  user: state.auth.signin.user,
});

export const CandidatesContainer = withRouter(
  connect(mapStateToProps, {
    getCandidatesRequest,
    getPostByIdRequest,
    updateCandidateRequest,
    getMyScheduleRequest,
    updateContracts,
    getContractsByFilter,
  })(Candidates)
);
