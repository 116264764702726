import React from "react";
import { Typography, Button } from "antd";
import style from "./index.module.scss";

export const CandidateInfo = (props) => {
  const { candidate } = props;
  return (
    <div class={style.container}>
      <Typography.Title level={2}>Candidate Information</Typography.Title>
      <Typography.Paragraph strong>
        Full Name:{" "}
        <Typography.Text>{`${
          candidate?.firstName ? candidate?.firstName : ""
        } ${candidate?.middleName ? candidate?.middleName : ""} ${
          candidate?.lastName ? candidate?.lastName : ""
        }`}</Typography.Text>
      </Typography.Paragraph>
      {/* <Typography.Paragraph strong>
        Email: <Typography.Text>{candidate?.email}</Typography.Text>
      </Typography.Paragraph> */}
      <Typography.Paragraph strong>
        City: <Typography.Text>{candidate?.city}</Typography.Text>
      </Typography.Paragraph>
      <Typography.Paragraph strong>
        State: <Typography.Text>{candidate?.state}</Typography.Text>
       
      </Typography.Paragraph>
      {props?.userstatus === "Approved" ?(
      <Typography.Paragraph strong>
        Phone: <Typography.Text>{candidate?.phone}</Typography.Text>
      </Typography.Paragraph>
      ):""}
      <Typography.Paragraph strong>
        Availability:{" "}
        <Typography.Text>
          {candidate?.availability ? candidate?.availability : "-"}
        </Typography.Text>
      </Typography.Paragraph>
      <Typography.Paragraph strong>
        BLS:{" "}
        <Typography.Text>
          {candidate?.bls ? candidate?.bls : "-"}
        </Typography.Text>
      </Typography.Paragraph>
      <Typography.Paragraph strong>
        Education:{" "}
        <Typography.Text>
          {candidate?.education ? candidate?.education : "-"}
        </Typography.Text>
      </Typography.Paragraph>
      <Typography.Paragraph strong>
        Immunizer:{" "}
        <Typography.Text>
          {candidate?.immunizer ? candidate?.immunizer : "-"}
        </Typography.Text>
      </Typography.Paragraph>
      {/* <Typography.Paragraph strong>
        Status:{" "}
        <Typography.Text>
          {props?.status}
        </Typography.Text>
      </Typography.Paragraph> */}
      <div className={style.controls}>
        <Button
          type="danger"
          onClick={() => props.onChangeStatus("Disapproved", candidate._id)}
          loading={props.isLoading}
          // disabled = {props.status === "Approved" ? true :false}
        hidden = {props.status == "Approved" || props.status ==='Disapproved'? true :false }
        >
          Disapprove
        </Button>
        <Button
          type="primary"
          onClick={() => props.onChangeStatus("Approved", candidate._id)}
          loading={props.isLoading}
          hidden = {props.status == "Approved" || props.status ==='Disapproved'? true :false }
        >
          Approve
        </Button>
      </div>
    </div>
  );
};
