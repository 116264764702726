import { api } from "../../../helpers/api";
import { setAuthUserData, removeAuthUserData } from "../../../helpers/auth.js";

export const SIGNIN_REQUEST_PROCESS = "SIGNIN_REQUEST_PROCESS";
export const SIGNIN_REQUEST_ERROR = "SIGNIN_REQUEST_ERROR";
export const SIGNIN_SUCCESS = "SIGNIN_SUCCESS";
export const LOGOUT = "LOGOUT";
export const PROFILE_COMPLETED = "PROFILE_COMPLETED";
export const PROFILE_NOT_COMPLETED = "PROFILE_NOT_COMPLETED";
export const signinRequestProcess = () => ({ type: SIGNIN_REQUEST_PROCESS });
export let bool = false;
export const signinSuccess = (data) => ({
  type: SIGNIN_SUCCESS,
  data,
});

export const profileCompleted = (data) => ({
  type: PROFILE_COMPLETED,
  data,
});
export const profileNotCompleted = (data) => ({
  type: PROFILE_NOT_COMPLETED,
  data,
});

export const signinRequestError = (error) => ({
  type: SIGNIN_REQUEST_ERROR,
  error,
});

export const logout = () => ({ type: LOGOUT });

export const signinRequest = (formData) => async (dispatch) => {
  let cmpltData = {};

  try {
    console.log(formData, "formData");

    const profile = await api("post", "auth/signin_profile", formData);
    console.log(profile, "profileeee");
    bool = profile?.isProfileComplete;
    console.log("BOOL", bool);
    cmpltData.isProfileComplete = true;

    dispatch(profileCompleted(profile));
    setAuthUserData(cmpltData);
  } catch (error) {
    console.log("here another! ", error);
    bool = false;
    console.log(bool);
    dispatch(profileNotCompleted(bool));
  }

  try {
    dispatch(signinRequestProcess());

    const data = await api("post", "auth/signin ", formData);
    console.log(data, "data");
    setAuthUserData(data);

    dispatch(signinSuccess(data));
    cmpltData = data;
    // console.log(formData.email)
  } catch (error) {
    dispatch(signinRequestError(error.response ? error.response.data : error));
  }
};

export const logoutHandler = () => (dispatch) => {
  removeAuthUserData();
  dispatch(logout());
};
