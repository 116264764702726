import { api } from "../../../helpers/api";
import {
  isLoading,
  isLoaded,
} from "../../Common/Layout/components/Cabinet/actions";

export const addPostRequest =
  (formData, userID, email, name, city, role) => async (dispatch) => {
    const data = {
      ...formData,
      // startTime: formData.startTime._d,
      // endTime: formData.endTime._d,
      startDate: formData.date[0]._d,
      endDate: formData.date[1]._d,
      date:formData.date._d,
      postedBy: userID,
      email: email,
      firstName: name,
      role: role,
      city: city,
    };
    console.log(data.startDate, "start date heree")
    // const emailData = {
    //   email: email,
    // };
    console.log(data, "sdiusdisau");
    dispatch(isLoading());
    try {
      const response = await api(
        "post",
        `posts/create-post?city=${city}&role=${role}`,
        data
      );
      // const sendEmail = await api("post", "/post/send-email", emailData);

      dispatch(isLoaded());
      console.log("In the service file", response);
      // console.log("In the service file of email", sendEmail);
      return response;
    } catch (err) {
      console.log(err);
      dispatch(isLoaded());
    }
  };

export const removePostRequest = (postID) => async (dispatch) => {
  dispatch(isLoading());
  try {
    const response = await api("delete", `posts/delete-post/${postID}`, null);
    dispatch(isLoaded());
    console.log("In the service file", response);
    return response;
  } catch (err) {
    console.log(err);
    dispatch(isLoaded());
  }
};
export const removeFeedbackRequest = (postID) => async (dispatch) => {
  dispatch(isLoading());
  try {
    const response = await api(
      "delete",
      `feedback/delete-postID/${postID}`,
      null
    );
    dispatch(isLoaded());
    console.log("In the service file", response);
    return response;
  } catch (err) {
    console.log(err);
    dispatch(isLoaded());
  }
};
export const fetchUser = (userID) => async (dispatch) => {
  dispatch(isLoading());
  try {
    const response = await api("get", `users/${userID}`, null);
    dispatch(isLoaded());
    console.log("In the service file for getting user", response);
    return response;
  } catch (err) {
    console.log(err);
    dispatch(isLoaded());
  }
};

// export const sendFreelancerEmail = (city, role) => async (dispatch) => {
//   dispatch(isLoading());
//   try {
//     const response = await api(
//       "get",
//       `users/filters-city?city=${city}&role=${role}`,
//       null
//     );

//     dispatch(isLoaded());
//     console.log("In the service file for sending email", response);

//     return response;
//   } catch (err) {
//     console.log(err);
//     dispatch(isLoaded());
//   }
// };
export const sendFreelancer = (data) => async (dispatch) => {
  dispatch(isLoading());
  try {
    console.log(data, "Plaese");
    const response = await api("post", `/post/send-email-freelancer`, data);
    dispatch(isLoaded());
    console.log("In the service file for freelancer", response);
    return response;
  } catch (err) {
    console.log(err);
    dispatch(isLoaded());
  }
};
export const getCompany = (id) => async (dispatch) => {
  dispatch(isLoading());
  try {
    // console.log(data, "Plaese")
    const response = await api("get", `/companies/get-company/${id}`, null);
    dispatch(isLoaded());
    console.log("In the service file ", response);
    return response;
  } catch (err) {
    console.log(err);
    dispatch(isLoaded());
  }
};

// /companies/get-company/:companyID
// /feedback/delete-postID"
// /post/send-email-freelancer
