import { api } from "../../../helpers/api";
import {
  isLoading,
  isLoaded,
} from "../../Common/Layout/components/Cabinet/actions";

export const getPostByIdRequest = (id) => async (dispatch) => {
  dispatch(isLoading());
  try {
    const response = await api("get", `posts/${id}`, null);
    dispatch(isLoaded());
    console.log("In the service file", response);
    return response;
  } catch (err) {
    console.log(err);
    dispatch(isLoaded());
  }
};

export const applyToPostRequest = (data, id) => async (dispatch) => {
  dispatch(isLoading());
  try {
    const response = await api("patch", `posts/edit-post/${id}`, data);
    dispatch(isLoaded());
    console.log("In the service file", response);
    return response;
  } catch (err) {
    console.log(err);
    dispatch(isLoaded());
  }
};
// export const shiftEmailRequest = (email,freelancer, business, address, date, hours) => async (dispatch) => {
//   dispatch(isLoading());
//   try {
//     const data = {
//       email:email,
//       name:freelancer,
//       pharmacy: business,
//       address: address,
//       date: date,
//       hours:hours
//     }
//     const response = await api("post", `post/send-email-shiftcard`, data);
//     dispatch(isLoaded());
//     console.log("In the service file", response);
//     return response;
//   } catch (err) {
//     console.log(err);
//     dispatch(isLoaded());
//   }

// }


//   export const matchedEmailRequest = (email, name,firstname, lastName, city, address, date) => async (dispatch) => {
//     dispatch(isLoading());
//     try {
//       const data = {
//         email:email,
//         name:name,
//         firstname: firstname,
//         lastName: lastName,
//         city:city,
//         address: address,
//         date: date,
//       }
//       const response = await api("post", `post/send-email-matched`, data);
//       dispatch(isLoaded());
//       console.log("In the service file", response);
//       return response;
//     } catch (err) {
//       console.log(err);
//       dispatch(isLoaded());
//     }}