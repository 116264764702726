import { api } from "../../../helpers/api";
import {
  isLoading,
  isLoaded,
} from "../../Common/Layout/components/Cabinet/actions";

export const getCandidateInformationRequest = (id) => async (dispatch) => {
  dispatch(isLoading());
  try {
    const response = await api("get", `users/${id}`, null);
    dispatch(isLoaded());
    console.log("In the service file", response);
    return response;
  } catch (err) {
    console.log(err);
    dispatch(isLoaded());
  }
};
export const getCompanyInformationRequest = (id) => async (dispatch) => {
  dispatch(isLoading());
  try {
    const response = await api("get", `companies/get-company/${id}`, null);
    dispatch(isLoaded());
    console.log("In the service file", response);
    return response;
  } catch (err) {
    console.log(err);
    dispatch(isLoaded());
  }
};
export const filterUser =
  (user) => async (dispatch) => {
    dispatch(isLoading());

    try {
      const response = await api(
        "get",
        `users/filter?role=${user}`,
        null
      );
      dispatch(isLoaded());

      console.log("In the service file", response);
      return response;
    } catch (err) {
      console.log(err);
      dispatch(isLoaded());
    }
  };

// /users/filter