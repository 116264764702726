import React, { useState, useEffect } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import { routes } from "./routes";
import { AuthRoute } from "./feature/Common/AuthRoute";
import { Layout } from "./feature/Common/Layout";
import { Provider } from "react-redux";
import { store } from "./store";
import { bool, profileCompleted } from "./feature/Auth/Signin/actions";
import { getAuthUserData } from "./helpers/auth.js";
import { signinSuccess, logout } from "./feature/Auth/Signin/actions";
import { initAuthInterceptor } from "./helpers/api";
import { connect } from "react-redux";

import { Routes } from "react-router";
import { ProfileContatiner } from "./feature/Auth/Profile";

const userAuth = getAuthUserData();
if (userAuth) {
  store.dispatch(signinSuccess(userAuth));
}

initAuthInterceptor(store, logout);

const App = (prop) => {
  // const [isProfileComplete, setIsProfileComplete] = useState(prop.isComplete);

  // useEffect(() => {
  //   setIsProfileComplete(prop.isComplete);
  // }, [isProfileComplete]);

  return (
    <Provider store={store}>
      <BrowserRouter>
        <Layout>
          <Routes>
            {routes.map((item, i) => {
              const El = item.component;
              return item.isAuth ? (
                <Route
                  path={item.path}
                  element={
                    <AuthRoute>
                      <El />
                    </AuthRoute>
                  }
                  key={i}
                />
              ) : (
                <Route path={item.path} element={<El />} key={i} />
              );
            })}
          </Routes>
        </Layout>
      </BrowserRouter>
    </Provider>
  );
};
const mapStateToProps = (state) => ({
  ...state,
});

export default connect(mapStateToProps)(App);
// export default App;
