import React, { useState } from "react";
import { Form, Input, Button, Steps, message } from "antd";
import style from "./index.module.scss";
import { formatStrategyValues } from "rc-tree-select/lib/utils/strategyUtil";
import { useEffect } from "react";
import { useNavigate } from "react-router";
// import { signupRequest } from "../../actions";

const ProfileForm = (props) => {
  const [form] = Form.useForm();

  const history = useNavigate();

  const { user } = props;

  console.trace(user);

  useEffect(() => {
    if (user) {
      form.setFieldsValue({
        firstName: user?.firstName,
        lastName: user?.lastName,
        email: user?.email,
      });
    }
  }, [form, user]);

  const onComplete = (values) => {
    const name = values.firstName;
    // console.log(values, "values")
    try {
      if (
        props.user.isProfileComplete === false ||
        props.user.isProfileComplete === undefined
      ) {
        props.profileRequest(props.user?.email, name);

        console.log(values);
        props.signupRequest(
          { ...values, isProfileComplete: true },
          user?.email
        );
        message.info("Profile has been successfully saved");

        history("/all-posts");
      } else {
        //  {props.profileRequest(props.user?.email, props.user?.firstName)

        console.log(values);
        props.signupRequest(
          { ...values, isProfileComplete: true },
          user?.email
        );
        message.info("Profile has been successfully saved");

        history("/all-posts");
      }
      //   setSteps(steps + 1);
    } catch (err) {
      console.log("there is an error", err);
    }
  };

  return (
    <div
      style={{
        background: "#fff",
        padding: "1rem 2rem",
        height: "30rem",
        borderRadius: "1rem",
      }}
    >
      <h1 className={style.authHeader}>Profile</h1>
      <Form
        onFinish={onComplete}
        className={style.signupForm}
        layout="vertical"
        form={form}
      >
        <div className={style.formItem}>
          <Form.Item
            {...(props.isError && {
              help: props.errorMessage,
              validateStatus: "error",
            })}
            name="firstName"
            rules={[{ type: "string", message: "The input is not valid" }]}
            label="First Name"
          >
            <Input placeholder="Enter First Name" />
          </Form.Item>
        </div>

        <div className={style.formItem}>
          <Form.Item
            {...(props.isError && {
              help: props.errorMessage,
              validateStatus: "error",
            })}
            name="lastName"
            rules={[{ type: "string", message: "The input is not valid" }]}
            label="Last Name"
          >
            <Input placeholder="Enter Last Name" />
          </Form.Item>
        </div>

        <div className={style.formItem}>
          <Form.Item
            {...(props.isError && {
              help: props.errorMessage,
              validateStatus: "error",
            })}
            name="email"
            rules={[{ type: "email", message: "The input is not valid" }]}
            label="Email"
          >
            <Input disabled placeholder="xyz@gmail.com" />
          </Form.Item>
        </div>

        <div className={style.formItem}>
          <Form.Item
            {...(props.isError && {
              help: props.errorMessage,
              validateStatus: "error",
            })}
            name="password"
            rules={[
              { type: "string", message: "The input is not valid" },
              //length must be greater or equal to 6
              { min: 6, message: "Password must be at least 6 characters" },
            ]}
            label="Password"
          >
            <Input 
            placeholder="Enter new password"
            type="password" />
          </Form.Item>
        </div>

        <Button
          loading={props.isLoading}
          type="danger"
          style={{ float: "right" }}
          htmlType="submit"
        >
          Submit
        </Button>
      </Form>
    </div>
  );
};

export default ProfileForm;
