import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Layout, Menu } from "antd";
import Icon, { LogoutOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";
import "./index.scss";

const { Sider } = Layout;

const SidebarComponent = (props) => {
  const history = useNavigate();

  return (
    <Sider width={200} style={{ background: "#fff" }}>
      <Menu
        mode="inline"
        defaultSelectedKeys={["0"]}
        style={{ height: "100%", borderRight: 0 }}
      >
        {props.menuList.map((item, i) => {
          return (
            <Menu.Item key={i}>
              <Link to={item.path}>
                <Icon component={item.icon} />
                <span>{item.label}</span>
              </Link>
            </Menu.Item>
          );
        })}
        <Menu.Item
          onClick={() => {
            props.logout();
            history("/signin");
          }}
        >
          <p>
            <LogoutOutlined />
            <span>Logout</span>
          </p>
        </Menu.Item>
      </Menu>
    </Sider>
  );
};

SidebarComponent.propTypes = {
  menuList: PropTypes.array,
};

export const Sidebar = SidebarComponent;
