import React from "react";
import PropTypes from "prop-types";
import { Layout, Menu, Avatar } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import style from "./index.module.scss";
import NotFoundImage from "../../../../../../assets/etesal.png";

const { SubMenu } = Menu;
const { Header: AntHeader } = Layout;

const HeaderComponent = ({ user, logout }) => {
  const history = useNavigate();
  const profile = () => {
    history("profile");
  };

  const imageStyle = {
    width: "100px",
    height: "50px",
    padding: "0 24px",
  };

  return (
    <AntHeader style={{ padding: "0 24px", backgroundColor: "#f44336" }}>
      {/* <div className={style.logo} > */}
      <img src={NotFoundImage} className={style.logo} />

      {/* </div> */}
      <Menu
        theme="dark"
        mode="horizontal"
        className={style.menu}
        style={{
          lineHeight: "64px",
          backgroundColor: "#f44336",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <SubMenu
          style={{ float: "right" }}
          key="username"
          title={
            <span>
              <span style={{ marginRight: "10px" }}>
                {user?.firstName} {user?.lastName}
              </span>
              <Avatar size="medium" icon={<UserOutlined />} />
            </span>
          }
        >
          {/* <Menu.Item onClick={() => profile()} key="2">
            Profile
          </Menu.Item> */}
          <Menu.Item
            onClick={() => {
              logout();
              history("/signin");
            }}
            key="1"
          >
            Logout
          </Menu.Item>
        </SubMenu>
      </Menu>
    </AntHeader>
  );
};

HeaderComponent.propTypes = {
  user: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
};

export const Header = HeaderComponent;
