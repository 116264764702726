import { useEffect, useState, React } from "react";
import {
  Form,
  Input,
  Button,
  DatePicker,
  minDate,
  InputNumber,
  message,
  TimePicker,
  Popconfirm,
  Tooltip,
  Select,
  Menu,
  Checkbox,
} from "antd";

import moment, { months, now } from "moment";
import Icon from "@ant-design/icons";
import style from "./index.module.scss";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
// import { useEffect } from "react";
export const PostForm = (props) => {
  const inlineSize = 12;
  const now = moment();
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();
  const [software1, setSoftware1] = useState();
  const [language1, setlanguage1] = useState();
  const [terms, setTerms] = useState(false);

  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);

  const history = useNavigate();
  const { RangePicker } = DatePicker;
  // alert(props.user.role)
  const data = "data goes here";
  const success = (message) => {
    messageApi.open({
      type: "success",
      content: message,
      duration: 2,
    });
    // TODO: CLEAR THE FORM AND REDIRECT TO THE ALL POSTS PAGE
    form.resetFields();

    setTimeout(() => {
      history("/all-posts");
    }, 1500);
  };

  const handleChange = (value) => {
    console.log(`selected ${value}`);
    if (value == "Other") {
      setShow(true);
    }
  };
  const handleChange1 = (value) => {
    console.log(`selected ${value}`);
    if (value == "Other") {
      setShow1(true);
    }
  };
  const onFinish = async (values) => {
    const data = await props.fetchUser(props.userID);
    const data1 = await props.getCompany(props.user?.company);
    console.log(values, "VALUES");
    values.software1 = software1;
    values.language1 = language1;
    await props.onSubmit(
      {
        ...values,
        name: data1?.data?.company?.name,
        _id: props?.post?._id ? props.post._id : null,
      },
      props.userID,
      props.user?.email,
      props.user?.firstName,
      data.data?.user?.company?.city,
      "Freelancer"
    );
    console.log(values, "values");
    success(
      "Job post was successfully published, You will be redirected to the posts page"
    );
  };

  const onDelete = async () => {
    await props.onDelete(props.post._id);
    await props.removeFeedbackRequest(props.post._id);

    success(
      "Job post was successfully deleted, You will be redirected to the posts page"
    );
  };

  useEffect(() => {
    // alert(props.user.role)
    const e = moment(props.post?.endDate);
    const s = moment(props.post?.startDate);
    const d = [];
    d.push(s);
    d.push(e);

    console.log(d, "dd");
    props.initialValues.date = d;
    console.log(props.initialValues.date, "iniiii");
    console.log(props.post);
    // console.log(moment(d).format('yyyy-DD-MM'), "dj")
    console.log(props.initialValues, "initial");

    if (props.post) {
      form.setFieldsValue({
        // name: props.initialValues.name,
        // availableShifts: props.initialValues.availableShifts,
        // shiftDuration: props.initialValues.shiftDuration,
        // description: props.initialValues.description,
        // dateTime: props.initialValues.dateTime,

        ...props.initialValues,
      });
    }
  }, []);
  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current < moment().endOf("day");
  };
  const onChange = (e) => {
    console.log(`checked = ${e.target.checked}`);
    if (e.target.checked === true) setTerms(true);
    else setTerms(false);
  };
  return (
    <>
      {contextHolder}
      <Form
        form={form}
        onFinish={onFinish}
        size="large"
        layout="vertical"
        initialValues={{ remember: true }}
      >
        <Form.Item
          {...(props.isError && {
            help: props.errorMessage,
            validateStatus: "error",
          })}
          name="payment"
          // label ="Budget"
          rules={[
            {
              required: true,
              message: "please enter the payment",
            },
          ]}
          className={style.smField}
        >
          <InputNumber
            placeholder="Total Budget (in dollars)"
            size={inlineSize}
            style={{ width: "100%" }}
          />
        </Form.Item>

        {show ? (
          <Form.Item
            {...(props.isError && {
              help: props.errorMessage,
              validateStatus: "error",
            })}
            name="software1"
            rules={[
              {
                //  required: true,
                message: "please select the software",
              },
            ]}
            className={style.smField}
          >
            <Input
              style={{ marginTop: "10px" }}
              placeholder="Enter Software used at Locatione"
              onChange={(e) => setSoftware1(e.target.value)}
            />
            <br />
          </Form.Item>
        ) : (
          <Form.Item
            {...(props.isError && {
              help: props.errorMessage,
              validateStatus: "error",
            })}
            name="software"
            rules={[
              {
                required: true,
                message: "please select the software",
              },
            ]}
            className={style.smField}
          >
            <Select
              placeholder="Select Software used at Location"
              size={inlineSize}
              style={{ width: "100%" }}
              onChange={handleChange}
              options={[
                {
                  value: "AbacusRx",
                  label: "AbacusRx",
                },
                {
                  value: "Best Rx",
                  label: "Best Rx",
                },
                {
                  value: "cerner",
                  label: "cerner",
                },
                {
                  value: "Datascan",
                  label: "Datascan",
                },
                {
                  value: "EPIC",
                  label: "EPIC",
                },
                {
                  value: "FrameworkLTC",
                  label: "FrameworkLTC",
                },
                {
                  value: "Cerner",
                  label: "Cerner",
                },
                {
                  value: "McKesson",
                  label: "McKesson",
                },
                {
                  value: "NRx",
                  label: "NRx",
                },
                {
                  value: "PDX Classic",
                  label: "PDX Classic",
                },
                {
                  value: "PioneerRx",
                  label: "PioneerRx",
                },
                {
                  value: "PrimeRx",
                  label: "PrimeRx",
                },
                {
                  value: "Rx30",
                  label: "Rx30",
                },

                {
                  value: "Winpharm",
                  label: "Winpharm",
                },
                {
                  value: "Other",
                  label: "Other",
                },
              ]}
            />
          </Form.Item>
        )}

        {show1 ? (
          <Form.Item
            {...(props.isError && {
              help: props.errorMessage,
              validateStatus: "error",
            })}
            name="language1"
            rules={[
              {
                //  required: true,
                message: "please enter the language",
              },
            ]}
            className={style.smField}
          >
            <Input
              style={{ marginTop: "10px" }}
              placeholder="Enter Languages spoken other than English"
              onChange={(e) => setlanguage1(e.target.value)}
            />
            <br />
          </Form.Item>
        ) : (
          <Form.Item
            {...(props.isError && {
              help: props.errorMessage,
              validateStatus: "error",
            })}
            name="language"
            rules={[
              {
                required: true,
                message: "please select  language",
              },
            ]}
            className={style.smField}
          >
            <Select
              placeholder="Select Languages spoken other than English"
              size={inlineSize}
              style={{ width: "100%" }}
              onChange={handleChange1}
              options={[
                {
                  value: "Arabic",
                  label: "Arabic",
                },
                {
                  value: "Chinese (Mandarin, Cantonese)",
                  label: "Chinese (Mandarin, Cantonese)",
                },
                {
                  value: "French",
                  label: "French",
                },
                {
                  value: "German ",
                  label: "German ",
                },
                {
                  value: "Greek",
                  label: "Greek",
                },
                {
                  value: "Hebrew",
                  label: "Hebrew",
                },
                {
                  value: "Hindi",
                  label: "Hindi",
                },
                {
                  value: "Italian",
                  label: "Italian",
                },
                {
                  value: "Korean",
                  label: "Korean",
                },
                {
                  value: "Polish",
                  label: "Polish",
                },
                {
                  value: "Punjabi",
                  label: "Punjabi",
                },
                {
                  value: "Russian",
                  label: "Russian",
                },
                {
                  value: "Spanish",
                  label: "Spanish",
                },

                {
                  value: "Tagalog",
                  label: "Tagalog",
                },
                {
                  value: "Urdu",
                  label: "Urdu",
                },

                {
                  value: "Yiddish",
                  label: "Yiddish",
                },
                {
                  value: "Other",
                  label: "Other",
                },
              ]}
            />
          </Form.Item>
        )}

        <Form.Item
          {...(props.isError && {
            help: props.errorMessage,
            validateStatus: "error",
          })}
          name="days"
          rules={[
            {
              required: true,
              message: "please select day",
            },
          ]}
          className={style.smField}
        >
          <Select
            placeholder="Select Number of Days Coverage Needed"
            size={inlineSize}
            style={{ width: "100%" }}
            onChange={handleChange}
            options={[
              {
                value: "1",
                label: "1",
              },
              {
                value: "2",
                label: "2",
              },
              {
                value: "3",
                label: "3",
              },
              {
                value: "4",
                label: "4",
              },
              {
                value: "5",
                label: "5",
              },
            ]}
          />
        </Form.Item>

        <Form.Item
          {...(props.isError && {
            help: props.errorMessage,
            validateStatus: "error",
          })}
          name="work"
          rules={[
            {
              required: true,
              message: "please select work",
            },
          ]}
          className={style.smField}
        >
          <Select
            placeholder="Select Work Load "
            size={inlineSize}
            style={{ width: "100%" }}
            onChange={handleChange}
            options={[
              {
                value: "Low (0-50)",
                label: "Low (0-50)",
              },
              {
                value: "Moderately Medium (51-150)",
                label: "Moderately Medium (51-150)",
              },
              {
                value: "Medium (151-250)",
                label: "Medium (151-250)",
              },
              {
                value: "High (251+)",
                label: "High (251+)",
              },
            ]}
          />
        </Form.Item>

        <Form.Item
          {...(props.isError && {
            help: props.errorMessage,
            validateStatus: "error",
          })}
          name="date"
          rules={[
            {
              required: true,
              message: "please select the date",
            },
          ]}
          className={style.smField}
        >
          <RangePicker
            disabledDate={disabledDate}
            // placeholder="Budget"
            size={inlineSize}
            style={{ width: "100%" }}
            // format={"mm-DD-yyyy"}
          />
        </Form.Item>
        <Form.Item
          {...(props.isError && {
            help: props.errorMessage,
            validateStatus: "error",
          })}
          name="dateTime"
          rules={[
            {
              required: true,
              message: "please select start time",
            },
          ]}
          className={style.smField}
        >
          <TimePicker
            // placeholder="Budget"
            size={inlineSize}
            style={{ width: "100%" }}
            format={"HH:mm"}
            placeholder={"Start Time"}
            minuteStep={30}
          />
        </Form.Item>

        <Form.Item
          {...(props.isError && {
            help: props.errorMessage,
            validateStatus: "error",
          })}
          name="endTime"
          rules={[
            {
              required: true,
              message: "please  select start time",
            },
          ]}
          className={style.smField}
        >
          <TimePicker
            // placeholder="Budget"
            size={inlineSize}
            style={{ width: "100%" }}
            format={"HH:mm"}
            placeholder={"End Time"}
            minuteStep={30}
          />
        </Form.Item>
        <Form.Item
          {...(props.isError && {
            help: props.errorMessage,
            validateStatus: "error",
          })}
          name="description"
          rules={[{ required: true, message: "Please enter a description" }]}
        >
          <Input.TextArea placeholder="Description" autoSize={{ minRows: 7 }} />
        </Form.Item>

        {/* </div> */}
        {!props.post ? (
          <div>
            <Checkbox onClick={onChange} /> <span> I accept the Terms and Conditions. Please review the </span>
            <span>
              {" "}
              <Link to="/terms" state={props.user?.role} target="_blank">
                <u>terms and conditions</u>
              </Link>
            </span>
            <span>{" "}before posting your job</span>
            <br />
            <br />
            <Button
              type="danger"
              htmlType="submit"
              loading={props.isLoading}
              disabled={!terms}
            >
              Publish
            </Button>
            <br />
          </div>
        ) : (
          <div className={style.controls}>
            <Popconfirm
              placement="topLeft"
              title={"Are you sure you want to delete"}
              description={"This action cannot be undone"}
              onConfirm={onDelete}
              okText="Yes"
              cancelText="No"
            >
              <Button
                type="link"
                loading={props.isLoading}
                // style={{ width: "50%" }}
              >
                Delete
              </Button>
            </Popconfirm>
            <Button
              type="danger"
              htmlType="submit"
              loading={props.isLoading}
              // style={{ width: "50%" }}
            >
              Update
            </Button>
          </div>
        )}
        {/* </div> */}

        {/* <Form.Item
          {...(props.isError && {
            help: props.errorMessage,
            validateStatus: "error",
          })}
          name="description"
          rules={[{ required: true, message: "Please enter a description" }]}
        >
          <Input.TextArea
            placeholder="Description"
            autoSize={{ minRows: 12 }}
          />
        </Form.Item> */}
      </Form>
    </>
  );
};
