import React, { useEffect } from "react";
import { Form, Input, Button, message } from "antd";
import Icon from "@ant-design/icons";
import style from "./index.module.scss";
import { useNavigate } from "react-router";
export const CompanyForm = (props) => {
  const [form] = Form.useForm();
  const history = useNavigate();

  const onFinish = async (values) => {
    console.log(values, "valuess");
    
        await props.onSubmit(
          { values, companyID: props.company ? props.company._id : null },
          props.userID
        );

      
        if (props.user.status ==='Active') {
          history("/all-posts");
        } 
       else
          history("/under-review");
         
          props.onSuccess(); 
  };

  if (props.company) {
    form.setFieldsValue({
      ...props?.company,
    });
  }
useEffect(()=>{
  console.log(props.company, "Company")

})
  return (
    <Form onFinish={onFinish} size="large" layout="vertical" form={form}>
      <Form.Item
        {...(props.isError && {
          help: props.errorMessage,
          validateStatus: "error",
        })}
        name="name"
        rules={[{ required: true, message: "Please enter the company name" }]}
        label="Company Name"
      >
        <Input
          prefix={<Icon type="mail" style={{ color: "rgba(0,0,0,.25)" }} />}
          placeholder="Prudent Care"
        />
      </Form.Item>

      <Form.Item
        {...(props.isError && {
          help: props.errorMessage,
          validateStatus: "error",
        })}
        name="phone"
        rules={[{ required: true, message: "Please enter the phone number" }]}
        label="Phone number"
      >
        <Input
          prefix={<Icon type="phone" style={{ color: "rgba(0,0,0,.25)" }} />}
          placeholder="1.888.888.888"
        />
      </Form.Item>

      <Form.Item
        {...(props.isError && {
          help: props.errorMessage,
          validateStatus: "error",
        })}
        name="email"
        rules={[{ required: true, message: "Please enter the business email" },
        { type: "email", message: "The input is not valid E-mail!" },

      
      
      ]}
       
        label="Business Email"
      >
        <Input
          prefix={<Icon type="mail" style={{ color: "rgba(0,0,0,.25)" }} />}
          placeholder="email@website.com"
        />
      </Form.Item>

      <Form.Item
        {...(props.isError && {
          help: props.errorMessage,
          validateStatus: "error",
        })}
        name="address"
        rules={[{ required: true, message: "Please enter the street address" }]}
        label="Full Street Address"
      >
        <Input
          prefix={<Icon type="mail" style={{ color: "rgba(0,0,0,.25)" }} />}
          placeholder="11143 West st"
        />
      </Form.Item>

      <Form.Item
        {...(props.isError && {
          help: props.errorMessage,
          validateStatus: "error",
        })}
        name="city"
        rules={[{ required: true, message: "Please enter the city" }]}
        label="City"
      >
        <Input
          prefix={<Icon type="mail" style={{ color: "rgba(0,0,0,.25)" }} />}
          placeholder="New York"
        />
      </Form.Item>

      <Form.Item
        {...(props.isError && {
          help: props.errorMessage,
          validateStatus: "error",
        })}
        name="state"
        rules={[{ required: true, message: "Please enter the state" }]}
        label="State"
      >
        <Input
          prefix={<Icon type="mail" style={{ color: "rgba(0,0,0,.25)" }} />}
          placeholder="New York"
        />
      </Form.Item>


      <Form.Item
        {...(props.isError && {
          help: props.errorMessage,
          validateStatus: "error",
        })}
        name="zip"
        rules={[{ required: true, message: "Please enter the zip code" }]}
        label="Zip Code"
      >
        <Input
          prefix={<Icon type="mail" style={{ color: "rgba(0,0,0,.25)" }} />}
          placeholder="22222"
        />
      </Form.Item>

      <Form.Item
        {...(props.isError && {
          help: props.errorMessage,
          validateStatus: "error",
        })}
        name="county"
        rules={[{ required: true, message: "Please enter the county name" }]}
        label="County"
      >
        <Input
          prefix={<Icon type="mail" style={{ color: "rgba(0,0,0,.25)" }} />}
          placeholder="New York"
        />
      </Form.Item>
      <Button
        type="danger"
        htmlType="submit"
        loading={props.isLoading}
        className={style.submitBtn}
      >
        Save
      </Button>
    </Form>
  );
};
