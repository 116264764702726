import { api } from "../../../helpers/api";
import {
  isLoading,
  isLoaded,
} from "../../Common/Layout/components/Cabinet/actions";

export const addCompanyRequest = (data, userID) => async (dispatch) => {
  dispatch(isLoading());
  try {
    const response = await api(
      "post",
      `companies/create-company/${userID}`,
      data
    );
    dispatch(isLoaded());
    console.log("In the service file", response);
    return response;
  } catch (err) {
    console.log(err);
    dispatch(isLoaded());
  }
};

export const getCompanyRequest = (id) => async (dispatch) => {
  dispatch(isLoading());
  try {
    const response = await api("get", `users/${id}`, null);
    dispatch(isLoaded());
    console.log("In the service file", response);
    return response;
  } catch (err) {
    console.log(err);
    dispatch(isLoaded());
  }
};
