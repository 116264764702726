import { HomeContainer } from "./Home";
import { UsersContainer } from "./Users";
import { PharmacyHomeContainer } from "./PharmacyHome";
import { PharmacistHomeContainer } from "./PharmacistHome";
import { MakePostContainer } from "./MakePost";
import { AvailableShiftsContainer } from "./AvailableShifts";
import { MyScheduleContainer } from "./MySchedule";
import { SupportContainer } from "./Support";
import { MyCompanyContainer } from "./MyCompany";
import { AllPostsContainer } from "./AllPost";
import { PostDetailsContainer } from "./PostDetails";
import { CandidatesContainer } from "./Candidates";
import { CandidateInformationContainer } from "./CandidateInformation";
import { AccountsPendingContainer } from "./Accounts/AccountPending";
import { AccountsContainer } from "./Accounts";
import { MatchedOpportunityContainer } from "./MatchedOpportunity";
import { PaymentsContainer } from "./Payments";
import { AccountsBlockedContainer } from "./Accounts/AccountsBlocked";
import { AccountsActiveContainer } from "./Accounts/AccountsActive";
import { CompletedContainer } from "./MatchedOpportunity/Completed";
import { InProgressContainer } from "./MatchedOpportunity/Inprogress";
import { UnderReview } from "../Common/UnderReview";
import {TermsAndConditions} from '../Common/TermsAndConditions'
import { BusinessOwnerContainer } from "./Payments/BusinessOwner";
import { FreelancerContainer } from "./Payments/Freelancer";
import { CompletedPaymentsContainer } from "./Payments/Completed";
import { AllFeedbackContainer } from "./AllFeedback";
import { AgencyContainer } from "./UserDetails/Agency";
import { PharmacistContainer } from "./UserDetails/Freelancer";
import { UserContainer } from "./UserDetails/container";
import { FileViewerContainer } from "./FileViewer";
import { PostsContainer } from "./AllPosts/container";
export const routes = [
  {
    path: "/available-shift",
    component: AvailableShiftsContainer,
    isAuth: true,
    exact: true,
  },
  {
    path: "/my-schedule",
    component: MyScheduleContainer,
    isAuth: true,
    exact: true,
  },
  // {
  //   path: "/",
  //   component: HomeContainer,
  //   isAuth: true,
  //   exact: true,
  // },
  {
    path: "/support",
    component: SupportContainer,
    isAuth: true,
    exact: true,
  },
  {
    path: "/users",
    component: UsersContainer,
    isAuth: true,
    exact: true,
  },
  {
    path: "/pharmacist-home",
    component: PharmacistHomeContainer,
    isAuth: true,
    exact: true,
  },
  {
    path: "/pharmacy-home",
    component: PharmacyHomeContainer,
    isAuth: true,
    exact: true,
  },
  {
    path: "/make-post",
    component: MakePostContainer,
    isAuth: true,
    exact: true,
  },
  {
    path: "/my-company",
    component: MyCompanyContainer,
    isAuth: true,
    exact: true,
  },
  {
    path: "/all-posts",
    component: AllPostsContainer,
    isAuth: true,
    exact: true,
  },
  {
    path: "/post-details/:id",
    component: PostDetailsContainer,
    isAuth: true,
    exact: true,
  },
  {
    path: "/candidates/:id",
    component: CandidatesContainer,
    isAuth: true,
    exact: true,
  },
  {
    path: "/candidates/information/:id",
    component: CandidateInformationContainer,
    isAuth: true,
    exact: true,
  },
  {
    path: "/accounts-pending",
    component: AccountsPendingContainer,
    isAuth: true,
    exact: true,
  },
  {
    path: "/accounts-active",
    component: AccountsActiveContainer,
    isAuth: true,
    exact: true,
  },

  {
    path: "/accounts-blocked",
    component: AccountsBlockedContainer,
    isAuth: true,
    exact: true,
  },
  {
    path: "/payments",
    component: PaymentsContainer,
    isAuth: true,
    exact: true,
  },
  {
    path: "/matched-opportunity",
    component: MatchedOpportunityContainer,
    isAuth: true,
    exact: true,
  },
  {
    path: "/completed",
    component: CompletedContainer,
    isAuth: true,
    exact: true,
  },
  {
    path: "/in-progress",
    component: InProgressContainer,
    isAuth: true,
    exact: true,
  },

  {
    path: "/under-review",
    component: UnderReview,
    isAuth: true,
    exact: true,
  },

  {
    path: "/business-owner-pendings",
    component: BusinessOwnerContainer,
    isAuth: true,
    exact: true,
  },

  {
    path: "/freelancer-pendings",
    component: FreelancerContainer,
    isAuth: true,
    exact: true,
  },
  {
    path: "/completed-payments",
    component: CompletedPaymentsContainer,
    isAuth: true,
    exact: true,
  },
  {
    path: "/accounts",
    component: AccountsContainer,
    isAuth: true,
    exact: true,
  },
  {
    path: "/all-feedbacks",
    component: AllFeedbackContainer,
    isAuth: true,
    exact: true,
  },

  {
    path: "/agency/:id",
    component: AgencyContainer,
    isAuth: true,
    exact: true,
  },
  {
    path: "/freelancer/:id",
    component: PharmacistContainer,
    isAuth: true,
    exact: true,
  },
  {
    path: "/user-details/:id",
    component: UserContainer,
    isAuth: true,
    exact: true,
  },
  {
    path: "/file-view",
    component: FileViewerContainer,
    isAuth: true,
    exact: true,
  },
  {
    path: "/posts",
    component: PostsContainer,
    isAuth: true,
    exact: true,
  },
  {
    path: "/terms",
    component: TermsAndConditions,
    isAuth: true,
    exact: true,
  },
];
