import {
  legacy_createStore as createStore,
  applyMiddleware,
  compose,
} from "redux";
import logger from "redux-logger";
import thunk from "redux-thunk";
import config from "./config/app";
import { reducers } from "./reducers";

const composeEnhancers =
  typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
      })
    : compose;

// const middlewares = applyMiddleware(thunk, config.LOGGER_ENABLE && logger);

export const store = createStore(reducers, applyMiddleware(thunk, logger));
