const common = {};

const dev = {
  LOGGER_ENABLE: true,
  API_BASE_URL: "https://etesalhealth.com/production/api/",
};

const prod = {
  LOGGER_ENABLE: false,
  API_BASE_URL: "https://etesalhealth.com/production/api/",
};

const config = process.env.NODE_ENV === "production" ? prod : dev;

export default {
  ...common,
  ...config,
};
