import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { withRouter } from "../../../../helpers/withRouter";
import { Input } from "antd";
import style from "./index.module.scss";
import { connect } from "react-redux";
import { getAllPostsRequest, searchPostsRequest } from "../service";
import { useNavigate } from "react-router";
import { ShiftCard } from "../../../Common/Shift";
import { ItemNotFound } from "../../../Common/ItemNotFound";
import { Loader } from "../../../Common/Loader";

const AvailableShifts = (props) => {
  const [posts, setPosts] = useState([]);
  // const [postData, setPostData] = useState([]);
  const [discount, setDiscount] = useState();
  const history = useNavigate();

  // console.log(props.getAllPostsRequest(props.user?.id));
  const getAllPosts = async () => {
    const response = await props.getAllPostsRequest();
    // const data = [];
    console.log("Inside UseEffect", response);
    setPosts(response?.data?.posts);

    const postData = [];

    const idPost = [];
    for (let i = 0; i < response?.data?.posts.length; i++) {
      // console.log(response?.data?.posts[i], "yes")
      response?.data?.posts[i].candidates?.map((candidate) => {
        if (
          // candidate.candidate._id === props.user.id &&
          candidate.status === "Approved"
        ) {
          idPost.push(response?.data?.posts[i]._id);
        }
      });
    }
    let bool = true;
   
    for (let i = 0; i < response?.data?.posts.length; i++) {
      for (let j = 0; j < idPost.length; j++) {
        if (response?.data?.posts[i]._id === idPost[j]) {
          bool = false;

          break;
        }
        bool = true;
      }
      if (bool === true) {
        postData.push(response?.data?.posts[i]);
        // console.log("yes")
        bool = false;
      }
    }
  
    console.log(idPost, "id");
    console.log(postData, "postData");
    setDiscount(response?.data.discount);
    if(idPost.length>0)
    setPosts(postData);
  };

  useEffect(() => {
    getAllPosts();
  }, [setPosts]);

  const onPickupShiftClick = (postID) => {
    history(`/post-details/${postID}`, { state: "available-shift" });
  };

  const onSearch = async (data) => {
    try {
      if (data === "") {
        getAllPosts();
      } else {
        const response = await props.searchPostsRequest(data);
        setPosts(response.data.posts);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className={style.container}>
      <div className={style.searchContainer}>
        <Input.Search
          placeholder="Search by name or location"
          allowClear
          onSearch={onSearch}
          onChange={(e) => {
            console.log(e.target.value);
            if (e.target.value.length === 0) {
              getAllPosts();
            }
          }}
          style={{ width: 800, margin: "2rem auto 0 auto" }}
        />
      </div>

      {props.isLoading ? (
        <Loader />
      ) : posts && posts.length > 0 ? (
        posts.map((post) => {
          return (
            <ShiftCard
              key={post._id}
              post={post}
              role={props.user?.role}
              onPickupShiftClick={onPickupShiftClick}
              discount={discount}
            />
          );
        })
      ) : (
        <ItemNotFound>
          <h1 style={{ color: "#808080" }}>There are no posts</h1>
        </ItemNotFound>
      )}
    </div>
  );
};

AvailableShifts.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  isAuth: PropTypes.bool.isRequired,
  isSuccess: PropTypes.bool.isRequired,
  isError: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
  getAllPostsRequest: PropTypes.func.isRequired,
  searchPostsRequest: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  isLoading: state.shared.loading.isLoading,
  isAuth: state.auth.signin.isAuth,
  isError: state.auth.signup.isError,
  isSuccess: state.auth.signup.isSuccess,
  errorMessage: state.auth.signup.errorMessage,
  user: state.auth.signin.user,
});

export const AvailableShiftsContainer = withRouter(
  connect(mapStateToProps, { getAllPostsRequest, searchPostsRequest })(
    AvailableShifts
  )
);
