import React from "react";
import { Button, Space, Input } from "antd";
import style from "./index.module.scss";
import { Table, Tag, ColumnsType } from "antd";
import {
  getUserByStatusRequest,
  updateUserRequest,
  searchUser,
} from "../../service";
import { Loader } from "../../../../Common/Loader";
import { ItemNotFound } from "../../../../Common/ItemNotFound";
import { getFullDate } from "../../../../../constants/DATE_FORMATE";
import { randomNumberInRange } from "../../../../../constants/UNIQUE_ID";
import { useEffect, useState } from "react";
import { withRouter } from "../../../../../helpers/withRouter";
import { connect } from "react-redux";
import { useNavigate } from "react-router";
import { AccountsContainer } from "../../../Accounts";
import moment from "moment";
// import { useNavigate } from "react-router";
const AccountsActive = (props) => {
  const [users, setUsers] = useState([]);
  const history = useNavigate();

  const [columns, setColumns] = useState([
    {
      title: "Name",
      dataIndex: ["firstName"],
      key: "_id",
      render: (text, record) => (
        <a onClick={() => showDetails(record?._id)}>
          {record?.firstName ? record?.firstName : record?.email}
        </a>
      ),
    },
    {
      title: "Type",
      dataIndex: "role",
      key: randomNumberInRange(),
    },
    {
      title: "CreatedAt",
      dataIndex: "createdAt",
      key: randomNumberInRange(),
      render: (date) => moment(getFullDate(date)).format("MM/DD/YYYY"),
    },

    {
      title: "Action",
      dataIndex: "_id",
      key: randomNumberInRange(),
      render: (text, record) => (
        <Space size="middle">
          <Button type="primary" danger onClick={(e) => onBlock(record?._id)}>
            Block
          </Button>
        </Space>
      ),
    },
  ]);
  const showDetails = (id) => {
    history(`/user-details/${id}`);
  };
  const onBlock = async (_id) => {
    const data = {
      status: "Blocked",
    };
    const blockRequestRequest = async () => {
      const response = await props.updateUserRequest(_id, data);
      console.log("response", response);
      console.log(response);
    };

    await blockRequestRequest();

    await getActiveUser();
  };
  const onSearch = async (data) => {
    try {
      if (data === "") {
        getActiveUser();
      } else {
        const response = await props.searchUser("Active", data);
        setUsers(response?.data?.users);
        console.log(response, "shdhdsj");
      }
    } catch (err) {
      console.log(err);
    }
  };
  const getActiveUser = async () => {
    console.log(props.user?.id);
    const response = await props.getUserByStatusRequest("Active");
    console.log("Inside UseEffect", response);

    setUsers(response?.data?.users);
  };
  useEffect(() => {
    getActiveUser();
  }, []);

  return (
    <div className={style.container}>
      {props.isLoading ? (
        <Loader />
      ) : users && users.length > 0 ? (
        <div style={{ justifyContent: "center" }}>
          <div style={{ justifyContent: "center", display: "flex" }}>
            <div className={style.container}>
              <AccountsContainer />

              <div className={style.searchContainer}>
                <Input.Search
                  placeholder="Search by name or email"
                  allowClear
                  onSearch={onSearch}
                  onChange={(e) => {
                    console.log(e.target.value);
                    if (e.target.value.length === 0) {
                      getActiveUser();
                    }
                  }}
                  style={{ width: 600, marginBottom: "20px", float: "left" }}
                />
              </div>
            </div>{" "}
          </div>
          <div style={{ justifyContent: "center", display: "flex" }}>
            <Table
              columns={columns}
              dataSource={users}
              style={{ width: "100%", alignContent: "center" }}
            />
          </div>
        </div>
      ) : (
        <ItemNotFound>
          <h1 style={{ color: "#808080" }}>There is no Data</h1>
          <AccountsContainer />
        </ItemNotFound>
      )}
    </div>
  );
};
const mapStateToProps = (state) => ({
  isLoading: state.shared.loading.isLoading,
  isAuth: state.auth.signin.isAuth,
  isError: state.auth.signup.isError,
  isSuccess: state.auth.signup.isSuccess,
  errorMessage: state.auth.signup.errorMessage,
  user: state.auth.signin.user,
});

export const AccountsActiveContainer = withRouter(
  connect(mapStateToProps, {
    getUserByStatusRequest,
    updateUserRequest,
    searchUser,
  })(AccountsActive)
);
