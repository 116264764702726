import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { SigninForm } from "../components/SigninForm";
import style from "./index.module.scss";
import { connect } from "react-redux";
import { signinRequest } from "../actions";
import { withRouter } from "./../../../../helpers/withRouter";
import { useNavigate } from "react-router-dom";
import { Col } from "antd";
import { ROLES } from "../../../../constants/ROLES";
import { STATUS } from "../../../../constants/STATUS";

const Signin = (props) => {
  const history = useNavigate();

  // alert("here at the login container");

  // console.log(props.user);
  // console.trace(props.user?.role, props.isProfile);

  useEffect(() => {
 
    if (props.user?.role === ROLES.admin) {
      props.isAuth && history("/accounts-pending");
    } else if (props.user?.role === ROLES.pharmacy) {
      if(props.user?.company===' ' || props.user?.company ===undefined || props.user?.company === null)
      props.isAuth && history('/my-company')
     else if (props.user?.status === STATUS.active && props.user?.company) {
        props.isAuth && history("/all-posts");

      } else props.isAuth && history("/under-review");
    } else {
      if (props.user?.isProfileComplete) {
        if (props.user?.status === STATUS.active)
          props.isAuth && history("/available-shift");
        else props.isAuth && history("/under-review");
      } else {
        props.isAuth && history("/profile");
      }
    }
  }, [
    props.isAuth,
    props.user?.isProfileComplete,
    history,
    props.user?.role,
    props.user?.status,
  ]);
  return (
    <Col
      xs={{ span: 14, offset: 5 }}
      sm={{ span: 12, offset: 6 }}
      md={{ span: 10, offset: 7 }}
      lg={{ span: 8, offset: 8 }}
      xl={{ span: 6, offset: 9 }}
    >
      <div className={style.signinFormWrapper}>
        <SigninForm
          onSubmit={props.signinRequest}
          isLoading={props.isLoading}
          isError={props.isError}
          errorMessage={props.errorMessage}
          isProfile = {props.isProfile}
        />
      </div>
    </Col>
  );
};

Signin.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  isAuth: PropTypes.bool.isRequired,
  isError: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
  isProfile: PropTypes.bool.isRequired,
  signinRequest: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  console.log("HERE AT MAP ", state);
  return {
    isLoading: state.auth.signin.isLoading,
    isAuth: state.auth.signin.isAuth,
    isError: state.auth.signin.isError,
    isProfile: state.auth.signin?.isProfileCompleted,
    errorMessage: state.auth.signin.errorMessage,
    user: state.auth.signin?.user,
  };
};

export const SigninContainer = withRouter(
  connect(mapStateToProps, { signinRequest })(Signin)
);
