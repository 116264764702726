import React, { useEffect, useState } from "react";
import { Rate, Button, Input, Modal, Form, message, Popconfirm } from "antd";
import { useNavigate } from "react-router";
export const RatingComponents = (props) => {
  const [data, setData] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm();
  const text = "Are you sure to Mark this job as Incomplete?";
  const description = "Mark Incomplete";
  const history = useNavigate();
  const confirm = () => {
    props.onMarkInComplete(props.postID, props.candidates, props.candidateID);
    setIsModalOpen(false);
    message.info("Marked Incomplete.");
  };
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleChangeForm = async (comment) => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setOpen(false);
      setIsModalOpen(false);
    }, 1000);
    // const feedback = await props.getFeedbacksByPostID(props.postID);
    
    // console.log("sjksd", feedback);
    // if (!feedback?.data?.feedback) {
      await props.onSubmit(
        comment,
        props.postID,
        props.userID,
        props.candidateID,
        data
      );
      props.onPharmacyFeedback(
        props.postID,
        props.candidates,
        props.candidateID
      );
      message.info("Feedback Submited! ");
    
    }
    // } else {
    //   const id = feedback?.data?.feedback?.map((user) => {
    //     if (user?.postID._id === props.postID) {
    //       if (user?.reviewerID._id === props.userID) {
    //         if (user?.reviewedID._id === props.candidateID) {
    //           return user?._id;
    //         }
    //       }
    //     }
    //   });
    //   const feedbackID = id.filter((id) => {
    //     return id !== undefined;
    //   });
    //   console.log(data.toString(), "feedbackID");
    //   if (id) {
    //     console.log(id, " ", "id");
    //     await props.updateFeedback(feedbackID.toString(), data, comment);
    //     message.info("Feedback Updated!");
    //   } else {
    //     await props.onSubmit(
    //       comment,

    //       props.postID,
    //       props.userID,
    //       props.candidateID,
    //       data
    //     );
    //   }
  //   }
  // };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const desc = ["terrible", "bad", "normal", "good", "wonderful"];

  const handleChange = (value) => {
    setData(value);
  };
  useEffect(() => {
    console.log(props.initialValues);
    if (props.feedback) {
      form.setFieldsValue({
        ...props.initialValues,
      });
    }
  }, []);

  return (
    <div>
      <Form
        onFinish={handleChangeForm}
        form={form}
        id="submit-data"
        initialValues={{ remember: true }}
      >
        <Button
          type="danger"
          style={{ marginRight: "1rem" }}
          size={props.size}
          onClick={showModal}
        >
          {props.feedbackBtn}
        </Button>

        <Modal
          title="Feedback"
          open={isModalOpen}
          onCancel={handleCancel}
          footer={[
            <Popconfirm
              placement="top"
              title={text}
              description={description}
              onConfirm={confirm}
              okText="Yes"
              cancelText="No"
            >
              <Button key="back">Reject</Button>
            </Popconfirm>,
            <Button
              type="primary"
              loading={props.isLoading}
              form="submit-data"
              htmlType="submit"
            >
              Submit
            </Button>,
          ]}
        >
          <span>
            <Rate tooltips={desc} onChange={handleChange} value={data} />
            {data ? (
              <span className="ant-rate-text">{desc[data - 1]}</span>
            ) : (
              ""
            )}
          </span>

          <Form.Item
            name="comment"
            rules={[{ required: true, message: "Please enter a description" }]}
            style={{ marginTop: "10px" }}
          >
            <Input.TextArea
              placeholder="Enter Description here "
              autoSize={{ minRows: 8 }}
            />
          </Form.Item>
        </Modal>
      </Form>
    </div>
  );
};