import { combineReducers } from "redux";

import { authReducers } from "./feature/Auth";
import { cabinetReducers } from "./feature/Cabinet";
import { sharedReducers } from "./feature/Common/Layout/components/Cabinet/reducers";

export const reducers = combineReducers({
  auth: authReducers,
  cabinet: cabinetReducers,
  shared: sharedReducers,
});
