import { api } from "../../../helpers/api";
import {
  isLoading,
  isLoaded,
} from "../../Common/Layout/components/Cabinet/actions";


export const submitFeedbackRequest =
  (comment, postID, reviewerID, reviewedID, rating) => async (dispatch) => {
    const data = {
  
        reviewedID: reviewedID,
        reviewerID: reviewerID,
        ...comment,
        ratings: rating,
    
      postID: postID,
    };

    dispatch(isLoading());
    try {
      const response = await api("post", "feedback/create-feedback", data);
      dispatch(isLoaded());
      console.log("In the service file", response);
      return response;
    } catch (err) {
      console.log(err);
      dispatch(isLoaded());
    }
  };
  export const getFeedbacksByPostID =
  (postID) => async (dispatch) => {
    
    dispatch(isLoading());
    try {
      const response = await api("get", `/feedback/postID-feedback/${postID}`, null);
      dispatch(isLoaded());
      console.log("In the service file", response);
      return response;
    } catch (err) {
      console.log(err);
      dispatch(isLoaded());
    }
  };


  export const updateFeedback =
  (id,  rating, comment) => async (dispatch) => {
    const data = {
  
    
      ...comment,
      ratings: rating,
  
    
  };

    dispatch(isLoading());
    try {
      const response = await api("patch", `/feedbacks/update-feedback/${id}`, data);
      dispatch(isLoaded());
      console.log("In the service file", response);
      return response;
    } catch (err) {
      console.log(err);
      dispatch(isLoaded());
    }
  };


  export const filterFeedback = (postID, reviewedID, reviewerID) => async (dispatch) => {
    
    dispatch(isLoading());
    try {
      const response = await api("get",  `/feedback/filter?postID=${postID}&reviewedID=${reviewedID}&reviewerID=${reviewerID}`)
  
    
    
      console.log("In the service file", response);
      return response;
    } catch (err) {
      console.log(err);
      dispatch(isLoaded());
    }
  };

