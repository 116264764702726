// import React, { useState, useEffect } from "react";
// import { connect } from "react-redux";
// import { getCandidateInformationRequest } from "../service";
// import { ItemNotFound } from "../../../Common/ItemNotFound";
// import PropTypes from "prop-types";
// import { withRouter } from "../../../../helpers/withRouter";
// import { Button } from "antd";
// import { UserDetails } from "../component";
// import { Loader } from "../../../Common/Loader";
// const User = (props) => {
//   const [user, setUser] = useState();
//   useEffect(() => {
//     if (props.id !== undefined) {
//       getCandidateData(props.id);
//     }
//   }, []);

//   const getCandidateData = async (candidateID) => {
//     const response = await props.getCandidateInformationRequest(candidateID);
//     setUser(response?.data?.user);
//     console.log(response, "in the User");
//   };
//   return (
//     <div>
//       {props.isLoading ? (
//         <Loader />
//       ) : (
//         <UserDetails candidate={user} />
//         //   ) :(
//         //     <ItemNotFound>
//         //     <h1 style={{ color: "#808080" }}>No User With this id</h1>
//         //   </ItemNotFound>
//         //   )}
//       )}
//     </div>
//   );
// };

// user?.propTypes = {
//   isLoading: PropTypes.bool.isRequired,
//   isAuth: PropTypes.bool.isRequired,
//   isSuccess: PropTypes.bool.isRequired,
//   isError: PropTypes.bool.isRequired,
//   errorMessage: PropTypes.string.isRequired,
//   user: PropTypes.object.isRequired,
//   getCandidatesRequest: PropTypes.func.isRequired,
// };

// const mapStateToProps = (state) => ({
//   isLoading: state.shared.loading.isLoading,
//   isAuth: state.auth.signin.isAuth,
//   isError: state.auth.signup.isError,
//   isSuccess: state.auth.signup.isSuccess,
//   errorMessage: state.auth.signup.errorMessage,
//   user: state.auth.signin.user,
// });

// export const UserContainer = withRouter(
//   connect(mapStateToProps, {
//     getCandidateInformationRequest,
//   })(User)
// );

import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { ItemNotFound } from "../../../Common/ItemNotFound";
import PropTypes from "prop-types";
import { withRouter } from "../../../../helpers/withRouter";
import { Button } from "antd";
import { UserDetails } from "../component";
import { Loader } from "../../../Common/Loader";
import { useNavigate, useParams } from "react-router";
import {
  getCandidateInformationRequest,
  getCompanyInformationRequest,
} from "../service";
// import { useParams } from "react-router";
const User = (props) => {
  const { id } = useParams();
  const [user, setUser] = useState();
  const [companyID, setConpamyID] = useState();
  useEffect(() => {
    console.log(id);

    if (id !== undefined) {
      getCandidateData(id);
    }
  }, []);

  const getCandidateData = async (candidateID) => {
    const response = await props.getCandidateInformationRequest(candidateID);
    setUser(response?.data?.user);
    console.log(response, "in the User");
    console.log( response?.data?.user?.company)
  const copmanyDetails = await props.getCompanyInformationRequest(response?.data?.user?.company._id);
  setConpamyID(copmanyDetails?.data?.company)
  };
  return (
    <div>
      {props.isLoading ? (
        <Loader />
      ) : user ? (
        <UserDetails candidate={user} company={companyID} />
      ) : (
        <ItemNotFound>
          <h1 style={{ color: "#808080" }}>No Data Found </h1>
        </ItemNotFound>
      )}
    </div>
  );
};

User.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  isAuth: PropTypes.bool.isRequired,
  isSuccess: PropTypes.bool.isRequired,
  isError: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
  getCandidatesRequest: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  isLoading: state.shared.loading.isLoading,
  isAuth: state.auth.signin.isAuth,
  isError: state.auth.signup.isError,
  isSuccess: state.auth.signup.isSuccess,
  errorMessage: state.auth.signup.errorMessage,
  user: state.auth.signin.user,
});

export const UserContainer = withRouter(
  connect(mapStateToProps, {
    getCandidateInformationRequest,
    getCompanyInformationRequest,
  })(User)
);
