import { api } from "../../../helpers/api";
import {
  isLoading,
  isLoaded,
} from "../../Common/Layout/components/Cabinet/actions";
export const getMyScheduleRequest = (candidateID) => async (dispatch) => {
  dispatch(isLoading());
  try {
    const response = await api("get", `posts/candidates/${candidateID}`, null);
    dispatch(isLoaded());
    console.log("In the service file", response);
    return response;
  } catch (err) {
    console.log(err);
    dispatch(isLoaded());
  }
};
export const updateCandidateRequest = (postID, data) => async (dispatch) => {
  dispatch(isLoading());
  try {
    const response = await api("patch", `posts/edit-post/${postID}`, data);
    dispatch(isLoaded());
    console.log("In the service file", response);
    return response;
  } catch (err) {
    console.log(err);
    dispatch(isLoaded());
  }
};

export const submitFeedbackRequest =
  (comment, postID, reviewedID, reviewerID, rating) => async (dispatch) => {
    const data = {
      ...comment,
      reviewedID: reviewedID,
      reviewerID: reviewerID,
      ratings: rating,
      postID: postID,
    };

    dispatch(isLoading());
    try {
      const response = await api("post", "feedback/create-feedback", data);
      dispatch(isLoaded());
      console.log("In the service file", response);
      return response;
    } catch (err) {
      console.log(err);
      dispatch(isLoaded());
    }
  };
export const getFeedbacksByPostID = (postID) => async (dispatch) => {
  dispatch(isLoading());
  try {
    const response = await api(
      "get",
      `/feedback/postID-feedback/${postID}`,
      null
    );
    dispatch(isLoaded());
    console.log("In the service file", response);
    return response;
  } catch (err) {
    console.log(err);
    dispatch(isLoaded());
  }
};

export const updateFeedback = (id, rating, comment) => async (dispatch) => {
  const data = {
    ...comment,
    ratings: rating,
  };

  dispatch(isLoading());
  try {
    const response = await api(
      "patch",
      `/feedbacks/update-feedback/${id}`,
      data
    );
    dispatch(isLoaded());
    console.log("In the service file", response);
    return response;
  } catch (err) {
    console.log(err);
    dispatch(isLoaded());
  }
};
export const filterFeedback =
  (postID, reviewedID, reviewerID) => async (dispatch) => {
    dispatch(isLoading());
    try {
      const response = await api(
        "get",
        `/feedback/filter?postID=${postID}&reviewedID=${reviewedID}&reviewerID=${reviewerID}`
      );

      console.log("In the service file", response);
      return response;
    } catch (err) {
      console.log(err);
      dispatch(isLoaded());
    }
  };
export const getContractsByFilter =
  (post, agency, freelancer) => async (dispatch) => {
    dispatch(isLoading());

    try {
      const response = await api(
        "get",
        `contracts/filters?post=${post}&agency=${agency}&freelancer=${freelancer}`,
        null
      );

      dispatch(isLoaded());

      console.log("In the service file of", response);
      return response;
    } catch (err) {
      console.log(err);
      dispatch(isLoaded());
    }
  };

export const updateContracts = (contractID, status) => async (dispatch) => {
  dispatch(isLoading());
console.log(status)
  try {
    const response = await api(
      "patch",
      `/contracts/edit-contract/${contractID}`,
      status
    );

    dispatch(isLoaded());

    console.log("In the service file of", response);
    return response;
  } catch (err) {
    console.log(err);
    dispatch(isLoaded());
  }
};
