import React from "react";
import style from "./index.module.scss";
import { Table } from "antd";
import { getContractsByStatusRequest } from "../../service";
import { ItemNotFound } from "../../../../Common/ItemNotFound";
import { Loader } from "../../../../Common/Loader";
import moment from "moment";
import { useEffect, useState } from "react";
import { withRouter } from "../../../../../helpers/withRouter";
import { getFullDate } from "../../../../../constants/DATE_FORMATE";
import { connect } from "react-redux";
import { MatchedOpportunityContainer } from "../../../MatchedOpportunity";
const Completed = (props) => {
  const [users, setUsers] = useState([]);
  const [columns, setColumns] = useState([
    {
      title: "Agency",
      dataIndex: ["agency", "name"],
      key: "_id",
    },
    {
      title: "Freelancer",
      dataIndex: ["freelancer", "firstName"],
      key: "_id",
    },
    {
      title: "StartDate",
      dataIndex: "startDate",
      render: (date) => moment(getFullDate(date)).format("MM/DD/YYYY"),
      key: "_id",
    },
    {
      title: "EndDate",
      dataIndex: "endDate",
      render: (date) => moment(getFullDate(date)).format("MM/DD/YYYY"),
      key: "_id",
    },

    {
      title: "Amount",
      dataIndex: "amount",
      key: "_id",
    },
  ]);
  const getCompleted = async () => {
    const response = await props.getContractsByStatusRequest("Completed");
    console.log("Inside UseEffect", response);
    setUsers(response?.data?.contracts);
  };
  useEffect(() => {
    getCompleted();
  }, []);
  return (
    <div className={style.container}>
      {props.isLoading ? (

      <Loader/>
      ) : users && users.length > 0 ? (
        <div style={{ justifyContent: "center" }}>
          <div style={{ justifyContent: "center", display: "flex" }}>
            <MatchedOpportunityContainer />
          </div>
          <div
            style={{
              justifyContent: "center",
              display: "flex",
              marginTop: "25px",
            }}
          >
            <Table
              columns={columns}
              dataSource={users}
              style={{ width: "100%", alignContent: "center" }}
            />
          </div>
        </div>
      ) : (
        <ItemNotFound>
        <h1 style={{ color: "#808080" }}>There is no Data</h1>
        <MatchedOpportunityContainer />
      </ItemNotFound>
      )}
    </div>
   
  );
};
const mapStateToProps = (state) => ({
  isLoading: state.shared.loading.isLoading,
  isAuth: state.auth.signin.isAuth,
  isError: state.auth.signup.isError,
  isSuccess: state.auth.signup.isSuccess,
  errorMessage: state.auth.signup.errorMessage,
  user: state.auth.signin.user,
});

export const CompletedContainer = withRouter(
  connect(mapStateToProps, { getContractsByStatusRequest })(Completed)
);













