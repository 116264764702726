import React from "react";
import style from "./index.module.scss";
import { Row } from "antd";

export const Auth = ({ children }) => {
  return (
    <div className={style.authLayout}>
      <Row style={{height:"100%"}}>
          {children}
      </Row>
    </div>
  );
};
