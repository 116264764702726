import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { withRouter } from "../../../../helpers/withRouter";
import style from "./index.module.scss";
import { PostForm } from "../components/PostForm";
import { connect } from "react-redux";
import {
  addPostRequest,
  removePostRequest,
  getCompany,
  removeFeedbackRequest,
  fetchUser,
  sendFreelancer,
} from "../service";
import { useLocation } from "react-router";
import moment from "moment";

const MakePost = (props) => {

  const location = useLocation();
  const post = location?.state;
  useEffect(()=>{
    console.log(moment(post?.endDate).format('MM D, '), "date")
  })
 
  console.log(post);
 
  
    const initialValues = post
    ? {
        name: post?.name,
        description: post?.description,
        software: post?.software,
        language: post?.language,
        days: post?.days,
        work: post?.work,
        date: post?.date, 
        dateTime: moment(post?.dateTime),
        endTime: moment(post?.endTime),
        payment: post?.payment,
        software1: post?.software1,
        language1:post?.languag1,
      }
    : {
        name: "",
        startTime: "",
        endTime: "",
        date: "",
        payment: 0,
        work:"",
        language:"",
        days:"",
        software:""
      };

  return (
    <div className={style.container}>
      <PostForm
        post={post}
        initialValues={initialValues}
        onSubmit={props.addPostRequest}
        onDelete={props.removePostRequest}
        isLoading={props.isLoading}
        isError={props.isError}
        errorMessage={props.errorMessage}
        userID={props.user?.id}
        removeFeedbackRequest={props.removeFeedbackRequest}
        sendFreelancerEmail={props.sendFreelancerEmail}
        user={props.user}
        fetchUser={props.fetchUser}
        sendFreelancer={props.sendFreelancer}
        getCompany={props.getCompany}
      />
    </div>
  );
};

MakePost.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  isAuth: PropTypes.bool.isRequired,
  isSuccess: PropTypes.bool.isRequired,
  isError: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
  addPostRequest: PropTypes.func.isRequired,
  removePostRequest: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  isLoading: state.shared.loading.isLoading,
  isAuth: state.auth.signin.isAuth,
  isError: state.auth.signup.isError,
  isSuccess: state.auth.signup.isSuccess,
  errorMessage: state.auth.signup.errorMessage,
  user: state.auth.signin.user,
});

export const MakePostContainer = withRouter(
  connect(mapStateToProps, {
    addPostRequest,
    removePostRequest,
    removeFeedbackRequest,
    sendFreelancer,
    fetchUser,
    getCompany,
  })(MakePost)
);
