import React from "react";
import { Button, Space, Input } from "antd";
import style from "./index.module.scss";
import { Tabs } from "antd";
import { useNavigate } from "react-router";
import { useEffect, useState } from "react";
// import style from "./index.module.scss";

export const AccountsContainer = (props) => {
  const history = useNavigate();

  const accountBlocked = () => {
    history("/accounts-blocked");
    console.log(process.env , "REACT_APP_DISCOUNT")
  };
  const accountActive = () => {
    history("/accounts-active");
  };
  const accountPending = () => {
    history("/accounts-pending");
  };

  return (
    <>
      <div className={style.container}>
        <div style={{ justifyContent: "center", display: "flex" }}>
          <Space wrap className={style.btnStyle}>
            <Button type="primary" size="large" danger onClick={accountPending}>
              Pending
            </Button>
            <Button type="primary" size="large" danger onClick={accountBlocked}>
              Blocked
            </Button>
            <Button type="primary" size="large" danger onClick={accountActive}>
              Active
            </Button>
          </Space>
        </div>
        {/* <p>&nbsp</p> */}
      </div>
    </>
  );
};
