import { api } from "../../../helpers/api";

import {
  isLoading,
  isLoaded,
} from "../../Common/Layout/components/Cabinet/actions";

export const updateUserRequest = (userID, data) => async (dispatch) => {
  dispatch(isLoading());
  try {
    const response = await api("patch", `users/update-user/${userID}`, data);
    dispatch(isLoaded());
    console.log("In the service file", response);
    return response;
  } catch (err) {
    console.log(err);
    dispatch(isLoaded());
  }
};

export const getUserByStatusRequest = (status) => async (dispatch) => {
  dispatch(isLoading());
  try {
    const response = await api("get", `users/filter?keyword=${status}`, null);
    dispatch(isLoaded());

    console.log("In the service file", response);
    return response;
  } catch (err) {
    console.log(err);
    dispatch(isLoaded());
  }
};
export const searchUser = (status, keyword) => async (dispatch) => {
  dispatch(isLoading());
  try {
    const response = await api(
      "get",
      `users/filters?status=${status}&keyword=${keyword}`,
      null
    );
    dispatch(isLoaded());

    console.log("In the service file", response);
    return response;
  } catch (err) {
    console.log(err);
    dispatch(isLoaded());
  }
};
