import { api } from "../../../helpers/api";
import {
  isLoading,
  isLoaded,
} from "../../Common/Layout/components/Cabinet/actions";
export const getPaymentsrequest =
  (business_owner, freelancer) => async (dispatch) => {
    dispatch(isLoading());

    try {
      const response = await api(
        "get",
        `payments/filter?businessOwnerBool=${business_owner}&freelancerBool=${freelancer}`,
        null
      );
    
      dispatch(isLoaded());

      console.log("In the service file", response);
      return response;
    } catch (err) {
      console.log(err);
      dispatch(isLoaded());
    }
  };

export const updatePaymentsrequest = (id, data) => async (dispatch) => {
  dispatch(isLoading());
  try {
    const response = await api("patch", `payments/update-payment/${id}`, data);
    dispatch(isLoaded());
    console.log("In the service", id, data)
    console.log("In the service file", response);
  
    return response;
  } catch (err) {
    console.log(err);
    dispatch(isLoaded());

  }
};
export const getPaymentsByIDrequest = (id) => async (dispatch) => {
  dispatch(isLoading());
  try {
  
    const response = await api("get", `payments/get-payments/${id}`, null);
  
    dispatch(isLoaded());
    console.log("In the service", id)
    console.log("In the service file", response);

    return response;
  } catch (err) {
    console.log(err);
    dispatch(isLoaded());
  }
};
export const updatePaymentsrequestAndNotification = (id, data, email,name) => async (dispatch) => {
  dispatch(isLoading());
  try {
    const response = await api("patch", `payments/update-payment/${id}`, data);
    dispatch(isLoaded());
    console.log("In the service", id, data)
    console.log("In the service file", response);
    const data1 = {
      email: email,
      name:name
    }
    const response2 = await api(
      "post",
      `/payment/send-email`,
      data1
    );
    // /payment/send-email
    console.log(response2, "Respoxe")
    return response;
  } catch (err) {
    console.log(err);
    dispatch(isLoaded());

  }
};