import FileViewer from "react-file-viewer";

import { useLocation } from "react-router-dom";

import styles from "./index.module.scss";

export const FileViewerContainer = () => {
  const location = useLocation();

  const { type, url } = location.state;

  return (
    // <div className={styles.container}>
      <FileViewer
        fileType={type}
        filePath={url}
      width={100}
      />
    // </div>
  );
};
