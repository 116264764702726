import {
  ContainerOutlined,
  BankOutlined,
  ClockCircleOutlined,
  PhoneOutlined,
  EditOutlined,
  HomeOutlined,
  UserOutlined,
  CreditCardOutlined,
  FormOutlined
} from "@ant-design/icons";

export const pharmacistMenuList = [
  {
    label: "Available Shifts",
    path: "/available-shift",
    icon: BankOutlined,
  },
  {
    label: "My Schedule",
    path: "/my-schedule",
    icon: ClockCircleOutlined,
  },
  {
    label: "Support",
    path: "/support",
    icon: PhoneOutlined,
  },
  {
    label: "Profile",
    path: "/profile",
    icon: UserOutlined,
  },
  {
    label: "Feedback",
    path: "/all-feedbacks",
    icon: FormOutlined,
  },
];

export const pharmacyMenuList = [
  {
    label: "My Posts",
    path: "/all-posts",
    icon: ContainerOutlined,
  },
  {
    label: "Make a post",
    path: "/make-post",
    icon: EditOutlined,
  },
  // {
  //   label: "Candidates",
  //   path: "/pharmacy-home",
  //   icon: "appstore-o",
  // },
  {
    label: "My Company",
    path: "/my-company",
    icon: HomeOutlined,
  },
  {
    label: "Support",
    path: "/support",
    icon: PhoneOutlined,
  },
  {
    label: "Profile",
    path: "/profile",
    icon: UserOutlined,
  },
  {
    label: "Feedback",
    path: "/all-feedbacks",
    icon: FormOutlined,
  },
  // {
  //   label: "Logout",
  //   path: "/pharmacy-home",
  //   icon: "appstore-o",
  // },
];


export const adminMenuList = [
  {
    label: "All Posts",
    path: "/posts",
    icon: BankOutlined,
  },
  {
    label: "Accounts",
    path: "/accounts-pending",
    icon: UserOutlined,
  },
  {
    label: "Matched Opportunity",
    path: "/in-progress",
    icon: FormOutlined,
  },
  // {
  //   label: "Candidates",
  //   path: "/pharmacy-home",
  //   icon: "appstore-o",
  // },
  {
    label: "Payments",
    path: "/business-owner-pendings",
    icon: CreditCardOutlined,
  },
  // {
  //   label: "Agency",
  //   path: "/agency",
  //   icon: UserOutlined,
  //   },


  //   {
  //     label: "Freelancer",
  //     path: "/freelancer",
  //     icon: UserOutlined,
  //     },
  // {
  //   label: "Freelancer",
  //   path: "/user",
  //   icon: UserOutlined,  },
 
  
];
