import React, { useState } from "react";
import {
  Form,
  Input,
  Button,
  Steps,
  message,
  Upload,
  Select,
  CheckboxOptionType,
} from "antd";
import style from "./index.module.scss";

import { UploadOutlined } from "@ant-design/icons";
import FilePreview from "../FilePreview";
import { useNavigate } from "react-router";
import { useEffect } from "react";

const ProfileForm = (props) => {
  const [name, setName] = useState();
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [termsChecked, setTermsChecked] = useState(true);

  const [identification, setIdentification] = useState({
    driverLiscenceCard: "",
    idCard: "",
    passport: "",
  });

  const [workAuthorization, setWorkAuthorization] = useState({
    visa: "",
    greenCard: "",
    workPermit: "",
  });
  const [steps, setSteps] = useState(0);
  const history = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const [type, setType] = useState("");
  const { userInfo } = props;

  console.trace(userInfo);
  const [form] = Form.useForm();

  useEffect(() => {
    if (userInfo) {
      form.setFieldsValue({
        firstName: userInfo?.firstName,
        lastName: userInfo?.lastName,
        middleName: userInfo?.middleName,
        addressLine1: userInfo?.addressLine1,
        city: userInfo?.city,
        state: userInfo?.state,
        zipCode: userInfo?.zipCode,
        education: userInfo?.education,
        pharmacistLicenseNumber: userInfo?.pharmacistLicenseNumber,
        phone: userInfo?.phone,
        liscenseState: userInfo?.liscenseState,
        npi: userInfo?.npi,
        immunizer: userInfo?.immunizer,
        bls: userInfo?.bls,
        visa:  userInfo?.visa,
      
        availability: userInfo?.availability,
        language1: userInfo?.language1,
        language2: userInfo?.language2,
        driverLiscenceCard: userInfo?.driverLiscenceCard,
        idCard: userInfo?.idCard,
        software1: userInfo?.software1,
        software2: userInfo?.software2,
        proficiency2: userInfo?.proficiency2,
        proficiency1: userInfo?.proficiency1,
      });
    }
    // alert(userInfo?.liscenseState)
  }, [form, userInfo]);
  const config = {
    name: "file",
    action: "https://etesalhealth.com/production/api/users/upload",
    headers: {
      authorization: "authorization-text",
    },
    onChange(info, type) {
      console.log(info);

      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        if (steps === 1) {
          setIdentification({
            ...identification,
            [type]: info.file.response.data.file.location,
          });
        }
        if (steps === 2) {
          setWorkAuthorization({
            ...workAuthorization,
            [type]: info.file.response.data.file.location,
          });
        }

        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  useEffect(() => {
    console.log(identification, workAuthorization);
  }, [identification, workAuthorization]);

  const handleChange = (value) => {
    console.log(`selected ${value}`);
    if (value == "Other") {
      setShow(true);
    }
  };
  const handleChange1 = (value) => {
    console.log(`selected ${value}`);
    if (value == "Other") {
      setShow1(true);
    }
  };
  const onFinish = (values) => {
    if (values.firstName !== undefined) setName(values.firstName);
    try {
      // console.log("Values",values.firstName)
      if (steps === 0) {
        props.signupRequest(values, props.user?.email);
      } else if (steps === 1) {
        if(!identification.driverLiscenceCard)
        {
          if(userInfo.driverLiscenceCard)
          {
            identification.driverLiscenceCard = userInfo.driverLiscenceCard
          }
        }
        if(!identification.idCard)
        {
          if(userInfo.idCard)
          {
            identification.idCard = userInfo.idCard
          }
        }
        
        props.signupRequest(identification, props.user?.email);
      } else if (steps === 2) {
        if(!workAuthorization.visa)
        {
          if(userInfo.visa)
          {
            workAuthorization.visa = userInfo.visa
          }
        }
        props.signupRequest(workAuthorization, props.user?.email);
      }
      setSteps(steps + 1);
    } catch (err) {
      console.log("there is an error", err);
    }
  };
  const onComplete = (values) => {
    console.log(values, "Values heree");
    // values.availability = values.availability.map((dat)=>{
    //   return data
    // });
    if(values?.language_1)
    values.language1= values.language_1;
    if(values?.language_2)
    values.language2= values.language_2;
    values.availability = values.availability.toString();
    console.log(values.availability, "hsu");
    try {
      props.signupRequest(
        { ...values, isProfileComplete: true },
        props.user?.email
      );
      if (props.user.isProfileComplete) {
        history("/available-shift");
      } else {
        // alert(props.user?.isProfileComplete)
        console.log(name, "Name");
        props.profileRequest(props.user?.email, name);
        history("/under-review");
        setSteps(steps + 1);
        message.info("Profile has been successfully saved");
      }
      //  history("/available-shift")
    } catch (err) {
      console.log("there is an error", err);
    }
  };

  const profileFormFields = [
    [
      {
        name: "firstName",
        rules: [
          { required: true, message: "Please enter first name" },
          // { type: "string", message: "The input is not valid" },
        ],
        placeholder: "First Name",
        label: "First Name",
        infoType: "basicInfo",
      },
      {
        name: "middleName",
        rules: [{ type: "string", message: "The input is not valid" }],
        placeholder: "Middle Name",
        label: "Middle Name",
        infoType: "basicInfo",
      },
      {
        name: "lastName",
        rules: [
          { required: true, message: "Please enter last name" },
          // { type: "string", message: "The input is not valid" },
        ],
        placeholder: "Last Name",
        label: "Last Name",
        infoType: "basicInfo",
      },
      {
        name: "addressLine1",
        rules: [
          { required: true, message: "Please enter address" },
          // { type: "string", message: "The input is not valid" },
        ],
        placeholder: "Address Line 1",
        label: "addressLine1",
        infoType: "basicInfo",
      },
      {
        name: "city",
        rules: [
          { required: true, message: "Please enter city" },
          // { type: "string", message: "The input is not valid" },
        ],
        placeholder: "City",
        label: "City",
        infoType: "basicInfo",
      },
      {
        name: "state",
        rules: [
          { required: true, message: "Please enter state" },
          // { type: "string", message: "The input is not valid" },
        ],
        label: "State",
        placeholder: "State",
        infoType: "basicInfo",
      },
      {
        name: "zipCode",
        rules: [
          { required: true, message: "Please enter zip code" },
          // { type: "string", message: "The input is not valid" },
        ],
        placeholder: "Zip Code",
        infoType: "basicInfo",
        label: "zipCode",
      },
    ],
    [
      {
        name: "driverLiscenceCard",
        // rules: [
        //   { required: true, message: "Please enter driving liscence" },
        //   // { type: "string", message: "The input is not valid" },
        // ],
        // placeholder: "One File Upload",
        infoType: "identification",
        file : <FilePreview 
        file={userInfo?.driverLiscenceCard?.split("/")[3]}
        url={userInfo?.driverLiscenceCard}
        />
        // label:"driverLiscenceCard",
      },
      {
        name: "idCard",
        // rules: [
        //   { required: true, message: "Please enter id card" },
        //   // { type: "string", message: "The input is not valid" },
        // ],
        placeholder: (
          <>
            <h3 style={{ color: "red" }}>
              Pharmacist Licence and Registration{" "}
            </h3>{" "}
            <p style={{ color: "red" }}>
              Please upload license and registration below:
            </p>
          </>
        ),
        infoType: "identification",
        file : <FilePreview 
        file={userInfo?.idCard?.split("/")[3]}
        url={userInfo?.idCard}
        />
        // label:"ID Card"
      },
      // {
      //   name: "passport",
      //   // rules: [
      //   //   { required: true, message: "Please enter passport" },
      //   //   // { type: "string", message: "The input is not valid" },
      //   // ],
      //   placeholder: "Passport",
      //   infoType: "identification",
      //   // label:"Passport"
      // },
    ],
    [
      // {
      //   name: "passport",
      //   rules: [
      //     { required: true, message: "Please enter passport" },
      //     // { type: "string", message: "The input is not valid" },
      //   ],
      //   placeholder: "Passport",
      //   infoType: "workAuthorization",
      // },
      {
        name: "visa",
        // rules: [
        //   { required: true, message: "Please enter visa" },
        //   // { type: "string", message: "The input is not valid" },
        // ],
        // placeholder: "one File Upload",
        infoType: "workAuthorization",
        file : <FilePreview 
        file={userInfo?.visa?.split("/")[3]}
        url={userInfo?.visa}
        />
        
        // label:"Visa"
      },
      // {
      //   name: "greenCard",
      //   // rules: [
      //   //   { required: true, message: "Please enter green card" },
      //   //   // { type: "string", message: "The input is not valid" },
      //   // ],
      //   placeholder: "Green Card",
      //   infoType: "workAuthorization",
      //   // label:"Green card"
      // },
      // {
      //   name: "workPermit",
      //   // rules: [
      //   //   { required: true, message: "Please enter work permit" },
      //   //   // { type: "string", message: "The input is not valid" },
      //   // ],
      //   placeholder: "Work Permit",
      //   infoType: "workAuthorization",
      //   // label:"Work Permit"
      // },
    ],
    [
      {
        name: "phone",
        rules: [
          { required: true, message: "Please enter Phone Number" },
          // { type: "string", message: "The input is not valid" },
        ],
        placeholder: "Phone Number",
        infoType: "phone",
        label: "Phone",
      },
      {
        name: "education",
        rules: [
          { required: true, message: "Please enter education" },
          // { type: "string", message: "The input is not valid" },
        ],
        placeholder: "Highest degree of Education",
        infoType: "generalInfo",
        label: "Education",
      },
      {
        name: "liscenseState",
        rules: [
          { required: true, message: "Please enter liscence state" },
          // { type: "string", message: "The input is not valid" },
        ],
        placeholder: "License State",
        label: "License State",
        infoType: "generalInfo",
        label: "License State",
      },
      {
        name: "pharmacistLicenseNumber",
        rules: [
          {
            required: true,
            message: "Please enter Freelancer liscense number",
          },
          // { type: "string", message: "The input is not valid" },
        ],
        placeholder: "Freelancer License Number",
        label: "Freelancer License Number",
        infoType: "generalInfo",
      },
      {
        name: "npi",
        rules: [
          { required: true, message: "Please enter NPI number" },
          // { type: "string", message: "The input is not valid" },
        ],
        placeholder: "NPI Number",
        infoType: "generalInfo",
        label: "NPI Number",
      },
    ],

    [
      {
        name: "immunizer",
        rules: [
          { required: true, message: "Please enter immunizer" },
          // { type: "string", message: "The input is not valid" },
        ],
        placeholder: "Immunizer",
        label: "Immunizer",
        options: [
          {
            value: "Yes",
            label: "Yes",
          },
          {
            value: "No",
            label: "No",
          },
        ],
        infoType: "generalInfo",
      },
      {
        name: "bls",
        rules: [
          { required: true, message: "Please enter bls" },
          // { type: "string", message: "The input is not valid" },
        ],
        placeholder: "Basic Life Support",
        label: "Basic Life Support",
        options: [
          {
            value: "Yes",
            label: "Yes",
          },
          {
            value: "No",
            label: "No",
          },
        ],
        infoType: "generalInfo",
      },
    ],
    [
      {
        name: "availability",
        rules: [
          { required: true, message: "Please enter days of availability" },
          // { type: "string", message: "The input is not valid" },
        ],
        placeholder: "Days of availability",
        label: "Days of availability",
        options: [
          {
            value: "Monday",
            label: "Monday",
          },
          {
            value: "Tuesday",
            label: "Tuesday",
          },
          {
            value: "Wednesday",
            label: "Wednesday",
          },
          {
            value: "Thursday",
            label: "Thursday",
          },
          {
            value: "Friday",
            label: "Friday",
          },
          {
            value: "Saturday",
            label: "Saturday",
          },
          {
            value: "Sunday",
            label: "Sunday",
          },
        ],
        infoType: "generalInfo",
      },
    ],

    [
      {
        name: "software1",
        rules: [
          { required: true, message: "Please Select Software 1" },
          // { type: "string", message: "The input is not valid" },
        ],
        // default: "Select Software 1",t
        placeholder: "Select Software 1",
        label: "Software 1",

        options: [
          {
            value: "AbacusRx",
            label: "AbacusRx",
          },
          {
            value: "Best Rx",
            label: "Best Rx",
          },
          {
            value: "cerner",
            label: "cerner",
          },
          {
            value: "Datascan",
            label: "Datascan",
          },
          {
            value: "EPIC",
            label: "EPIC",
          },
          {
            value: "FrameworkLTC",
            label: "FrameworkLTC",
          },
          {
            value: "Cerner",
            label: "Cerner",
          },
          {
            value: "McKesson",
            label: "McKesson",
          },
          {
            value: "NRx",
            label: "NRx",
          },
          {
            value: "PDX Classic",
            label: "PDX Classic",
          },
          {
            value: "PioneerRx",
            label: "PioneerRx",
          },
          {
            value: "PrimeRx",
            label: "PrimeRx",
          },
          {
            value: "Rx30",
            label: "Rx30",
          },

          {
            value: "Winpharm",
            label: "Winpharm",
          },
          {
            value: "Other",
            label: "Other",
          },
        ],

        infoType: "generalInfo",
      },

      {
        name: "proficiency1",
        rules: [
          { required: true, message: "Please Select Proficiency level" },
          // { type: "string", message: "The input is not valid" },
        ],
        // default: "Select proficiency level for Software 1 ",
        placeholder: "Select proficiency level for Software 1",
        label: "Proficiency level 1",
        options: [
          {
            value: "Beginner",
            label: "Beginner",
          },
          {
            value: "Intermediate",
            label: "Intermediate",
          },
          {
            value: "Advanced",
            label: "Advanced",
          },
        ],

        infoType: "generalInfo",
      },

      {
        name: "software2",
        rules: [
          { required: true, message: "Please Select Software 2" },
          // { type: "string", message: "The input is not valid" },
        ],
        // default: "Select Software 2",
        placeholder: "Select  Software 2",

        options: [
          {
            value: "AbacusRx",
            label: "AbacusRx",
          },
          {
            value: "Best Rx",
            label: "Best Rx",
          },
          {
            value: "cerner",
            label: "cerner",
          },
          {
            value: "Datascan",
            label: "Datascan",
          },
          {
            value: "EPIC",
            label: "EPIC",
          },
          {
            value: "FrameworkLTC",
            label: "FrameworkLTC",
          },

          {
            value: "McKesson",
            label: "McKesson",
          },
          {
            value: "NRx",
            label: "NRx",
          },
          {
            value: "PDX Classic",
            label: "PDX Classic",
          },
          {
            value: "PioneerRx",
            label: "PioneerRx",
          },
          {
            value: "PrimeRx",
            label: "PrimeRx",
          },
          {
            value: "Rx30",
            label: "Rx30",
          },

          {
            value: "Winpharm",
            label: "Winpharm",
          },
          {
            value: "Other",
            label: "Other",
          },
        ],
        label: "Software 2 ",
        infoType: "generalInfo",
      },

      {
        name: "proficiency2",
        rules: [
          { required: true, message: "Please Select proficiency level" },
          // { type: "string", message: "The input is not valid" },
        ],
        // default: "Select proficiency level for Software 2 ",
        placeholder: "Select proficiency level for Software 2",
        label: "Proficiency level 2",

        options: [
          {
            value: "Beginner",
            label: "Beginner",
          },
          {
            value: "Intermediate",
            label: "Intermediate",
          },
          {
            value: "Advanced",
            label: "Advanced",
          },
        ],

        infoType: "generalInfo",
      },
    ],
    [
      {
        name: "language1",
        rules: [
          { required: true, message: "Please Select Language 1" },
          // { type: "string", message: "The input is not valid" },
        ],
        // default: "Select Language 1",
        placeholder: "Select Language 1",

        options: [
          {
            value: "Arabic",
            label: "Arabic",
          },
          {
            value: "Chinese (Mandarin, Cantonese)",
            label: "Chinese (Mandarin, Cantonese)",
          },
          {
            value: "French",
            label: "French",
          },
          {
            value: "German ",
            label: "German ",
          },
          {
            value: "Greek",
            label: "Greek",
          },
          {
            value: "Hebrew",
            label: "Hebrew",
          },
          {
            value: "Hindi",
            label: "Hindi",
          },
          {
            value: "Italian",
            label: "Italian",
          },
          {
            value: "Korean",
            label: "Korean",
          },
          {
            value: "Polish",
            label: "Polish",
          },
          {
            value: "Punjabi",
            label: "Punjabi",
          },
          {
            value: "Russian",
            label: "Russian",
          },
          {
            value: "Spanish",
            label: "Spanish",
          },

          {
            value: "Tagalog",
            label: "Tagalog",
          },
          {
            value: "Urdu",
            label: "Urdu",
          },

          {
            value: "Yiddish",
            label: "Yiddish",
          },
          {
            value: "Other",
            label: "Other",
          },
        ],

        infoType: "generalInfo",
        label: "Language 1",
      },
    ],

    
    [
      {
        name: "language2",
        rules: [
          { required: true, message: "Please Select Language 2" },
          // { type: "string", message: "The input is not valid" },
        ],
        // default: "Select Language 2",
        placeholder: "Select Language 2",

        options: [
          {
            value: "Arabic",
            label: "Arabic",
          },
          {
            value: "Chinese (Mandarin, Cantonese)",
            label: "Chinese (Mandarin, Cantonese)",
          },
          {
            value: "French",
            label: "French",
          },
          {
            value: "German ",
            label: "German ",
          },
          {
            value: "Greek",
            label: "Greek",
          },
          {
            value: "Hebrew",
            label: "Hebrew",
          },
          {
            value: "Hindi",
            label: "Hindi",
          },
          {
            value: "Italian",
            label: "Italian",
          },
          {
            value: "Korean",
            label: "Korean",
          },
          {
            value: "Polish",
            label: "Polish",
          },
          {
            value: "Punjabi",
            label: "Punjabi",
          },
          {
            value: "Russian",
            label: "Russian",
          },
          {
            value: "Spanish",
            label: "Spanish",
          },

          {
            value: "Tagalog",
            label: "Tagalog",
          },
          {
            value: "Urdu",
            label: "Urdu",
          },

          {
            value: "Yiddish",
            label: "Yiddish",
          },
          {
            value: "Other",
            label: "Other",
          },
        ],

        infoType: "generalInfo",
        label: "Language 2",
      },
    ],

    // [
    //   {
    //     name: "language_1",
    //     rules: [
    //       { required: true, message: "Please Select Language 1" },
    //       // { type: "string", message: "The input is not valid" },
    //     ],
    //     // default: "Select Language 1",
    //     placeholder: "Enter Language 1",

              

    //     infoType: "generalInfo",
    //     label: "Language 1",
    //   },
    // ],
    // [
    //   {
    //     name: "language_2",
    //     rules: [
    //       { required: true, message: "Please Select Language 1" },
    //       // { type: "string", message: "The input is not valid" },
    //     ],
    //     // default: "Select Language 1",
    //     placeholder: "Enter Language 2",

              

    //     infoType: "generalInfo",
    //     label: "Language ",
    //   },
    // ],
  ];

  // let nextStep = () => {
  //   setSteps(steps+1)
  // }
  let prevStep = () => {
    setSteps(steps - 1);
  };

  return (
    <div
      style={{
        padding: "8px",
        background: "#f0f2f5",
        height: "100%",

        display: "block",
        alignContent: "center",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        // display: "flex",
        blockSize: "fit-content",
      }}
    >
      <div
        style={{
          padding: "24px 32px",
          paddingTop: "55px",
          background: "#fff",
          height: "100%",

          display: "block",
          alignContent: "center",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          // display: "flex",
          blockSize: "fit-content",
        }}
        className={style.profileBox}
      >
        <Steps
          progressDot
          current={steps}
          items={[
            {
              title: "Basic Info",
            },
            {
              title: "Identification",
            },
            {
              title: "Work Authorization",
            },
            {
              title: "General Information",
            },
          ]}
        />
        <h1 className={style.authHeader}>
          {steps === 0
            ? "Basic Information"
            : steps === 1
            ? `Identification`
            : steps === 2
            ? "Work Authorization"
            : steps === 3
            ? "General Information"
            : ""}
        </h1>
        <h3 style={{ color: "red" }}>
          {steps === 1
            ? `For Identification, please upload one of the following`
            : ""}
        </h3>

        {steps === 1 ? (
          <ul style={{ color: "red" }}>
            <li>Driver License</li>
            <li>State ID Card</li>
            <li>Passport </li>
          </ul>
        ) : (
          ""
        )}
        <h3 style={{ color: "blue" }}>
          {steps === 2
            ? `For work authorization, Please upload one of the following documents to verify your work authorization in US:`
            : ""}
        </h3>

        {steps === 2 ? (
          <ul style={{ color: "blue" }}>
            <li> Work Authorization </li>
            <li>Foreign Passport </li>
            <li>US Passport </li>
            <li>Green Card </li>
          </ul>
        ) : (
          ""
        )}
        {/* <h3 style={{ color: "blue" }}>
          {steps === 2
            ? `For work authorization, Please upload one of the following documents to verify your work authorization in US:
            `
            <li> Work Authorization e</li>
          <li>Foreign Passport </li>
          <li>US Passport </li>
          <li>Green Card </li>
            : ""} */}

        {/* </h3> */}

        <div>
          {steps === 0 ? (
            <Form
              onFinish={onFinish}
              className={style.signupForm}
              form={form}
              layout={"vertical"}
            >
              {profileFormFields[0].map((field, index) => {
                return (
                  <div
                    className={
                      steps === 0 &&
                      field.infoType !== "basicInfo" &&
                      style.formItem
                    }
                  >
                    <Form.Item
                      {...(props.isError && {
                        help: props.errorMessage,
                        validateStatus: "error",
                      })}
                      name={field.name}
                      rules={field.rules}
                      label={field.label}
                    >
                      <Input placeholder={field.placeholder} />
                    </Form.Item>
                    {/* <Form.Item
                      {...(props.isError && {
                        help: props.errorMessage,
                        validateStatus: "error",
                      })}
                      name={field.name}
                      rules={field.rules}
                    ></Form.Item> */}
                  </div>
                );
              })}
              <Form.Item>
                {steps !== 3 && (
                  <Button
                    loading={props.isLoading}
                    disabled={!termsChecked}
                    type="danger"
                    style={{ float: "right" }}
                    htmlType="submit"
                  >
                    Save & Continue
                  </Button>
                )}
                {steps > 0 && (
                  <Button
                    type="secondary"
                    style={{ float: "right", marginRight: "10px" }}
                    onClick={prevStep}
                  >
                    Back
                  </Button>
                )}
              </Form.Item>
            </Form>
          ) : steps === 1 ? (
            <Form onFinish={onFinish} className={style.signupForm} form={form}>
              {profileFormFields[1].map((field, index) => {
                return (
                                                        

                  <div
                    className={
                      steps === 0 &&
                      field.infoType !== "basicInfo" &&
                      style.formItem
                    }
                  >
    
                    <Form.Item
                      {...(props.isError && {
                        help: props.errorMessage,
                        validateStatus: "error",
                      })}
                      name={field.name}
                      rules={field.rules}
                      label={field.label}
                    >
                      <h4>{field.placeholder}</h4>
                      {/* <Input placeholder={field.placeholder} /> */}
                      {field.file}
                      <Upload
                        accept=".png,.jpg,.jpeg,.docx,.pdf"
                        // customRequest={(e) => onUpload(e, field.name)}
                        // onChange={(e) => onUpload(e, field.name)}
                        {...config}
                        progress={{
                          strokeColor: {
                            "0%": "#108ee9",
                            "100%": "#87d068",
                            "50%": "#f0f2f5",
                            "25%": "#832eef",
                          },
                          strokeWidth: 3,
                          format: (percent) =>
                            `${parseFloat(percent.toFixed(2))}%`,
                        }}
                        maxCount={1}
                        onChange={(e) => config.onChange(e, field.name)}
                      >
                        <Button
                          type="danger"
                          icon={<UploadOutlined />}
                        >{`Upload`}</Button>
                      </Upload>
                    </Form.Item>
                  </div>
                );
              })}
              <Form.Item>
                {steps !== 3 && (
                  <Button
                    loading={props.isLoading}
                    disabled={!termsChecked}
                    type="danger"
                    style={{ float: "right" }}
                    htmlType="submit"
                  >
                    Save & Continue
                  </Button>
                )}
                {steps > 0 && (
                  <Button
                    type="secondary"
                    style={{ float: "right", marginRight: "10px" }}
                    onClick={prevStep}
                  >
                    Back
                  </Button>
                )}
              </Form.Item>
            </Form>
          ) : steps === 2 ? (
            <Form onFinish={onFinish} className={style.signupForm} form={form}>
              {profileFormFields[2].map((field, index) => {
                return (
                  <div
                    className={
                      steps === 0 &&
                      field.infoType !== "basicInfo" &&
                      style.formItem
                    }
                  >
                    <Form.Item
                      {...(props.isError && {
                        help: props.errorMessage,
                        validateStatus: "error",
                      })}
                      name={field.name}
                      rules={field.rules}
                    >
                      <h4>{field.placeholder}</h4>
                      {field.file}
                      {/* <Input placeholder={field.placeholder} /> */}
                      <Upload
                        accept=".png,.jpg,.jpeg,.docx,.pdf"
                        // customRequest={(e) => onUpload(e, field.name)}
                        // onChange={(e) => onUpload(e, field.name)}
                        {...config}
                        progress={{
                          strokeColor: {
                            "0%": "#108ee9",
                            "100%": "#87d068",
                            "50%": "#f0f2f5",
                            "25%": "#832eef",
                          },
                          strokeWidth: 3,
                          format: (percent) =>
                            `${parseFloat(percent.toFixed(2))}%`,
                        }}
                        maxCount={1}
                        onChange={(e) => config.onChange(e, field.name)}
                      >
                        <Button
                          type="danger"
                          icon={<UploadOutlined />}
                        >{`Upload`}</Button>
                      </Upload>
                    </Form.Item>
                  </div>
                );
              })}
              <Form.Item>
                {steps !== 3 && (
                  <Button
                    loading={props.isLoading}
                    disabled={!termsChecked}
                    type="danger"
                    style={{ float: "right" }}
                    htmlType="submit"
                  >
                    Save & Continue
                  </Button>
                )}
                {steps > 0 && (
                  <Button
                    type="danger"
                    style={{ float: "right", marginRight: "10px" }}
                    onClick={prevStep}
                  >
                    Back
                  </Button>
                )}
              </Form.Item>
            </Form>
          ) : steps === 3 ? (
            <Form
              onFinish={onComplete}
              className={style.signupForm}
              form={form}
              layout={"vertical"}
            >
              {profileFormFields[3].map((field, index) => {
                return (
                  <div
                    className={
                      steps === 0 &&
                      field.infoType !== "basicInfo" &&
                      style.formItem
                    }
                  >
                    <Form.Item
                      {...(props.isError && {
                        help: props.errorMessage,
                        validateStatus: "error",
                      })}
                      name={field.name}
                      rules={field.rules}
                      label={field.label}
                    >
                      <Input placeholder={field.placeholder} />
                    </Form.Item>
                  </div>
                );
              })}

              {profileFormFields[4].map((field, index) => {
                return (
                  <div
                    className={
                      steps === 0 &&
                      field.infoType !== "basicInfo" &&
                      style.formItem
                    }
                  >
                    <Form.Item
                      {...(props.isError && {
                        help: props.errorMessage,
                        validateStatus: "error",
                      })}
                      name={field.name}
                      rules={field.rules}
                      label={field.label}
                    >
                      <Select
                        placeholder={field.placeholder}
                        options={field.options}
                      />
                    </Form.Item>
                  </div>
                );
              })}

              {profileFormFields[5].map((field, index) => {
                return (
                  <div
                    className={
                      steps === 0 &&
                      field.infoType !== "basicInfo" &&
                      style.formItem
                    }
                  >
                    <Form.Item
                      {...(props.isError && {
                        help: props.errorMessage,
                        validateStatus: "error",
                      })}
                      name={field.name}
                      rules={field.rules}
                      label={field.label}
                    >
                      <Select
                        mode="multiple"
                        placeholder={field.placeholder}
                        options={field.options}
                      />
                    </Form.Item>
                  </div>
                );
              })}
              {profileFormFields[6].map((field, index) => {
                return (
                  <div
                    className={
                      steps === 0 &&
                      field.infoType !== "basicInfo" &&
                      style.formItem
                    }
                  >
                    <Form.Item
                      {...(props.isError && {
                        help: props.errorMessage,
                        validateStatus: "error",
                      })}
                      name={field.name}
                      rules={field.rules}
                      label={field.label}
                    >
                      <Select
                        placeholder={field.placeholder}
                        options={field.options}
                      />
                    </Form.Item>
                  </div>
                );
              })}

              {profileFormFields[7].map((field, index) => {
                return (
                  <div
                    className={
                      steps === 0 &&
                      field.infoType !== "basicInfo" &&
                      style.formItem
                    }
                  >
                    {show ? (
                      <Form.Item
                        {...(props.isError && {
                          help: props.errorMessage,
                          validateStatus: "error",
                        })}
                        // name={field.name}
                        // rules={field.rules}
                        // label={field.label}
                         name='language_1'
                        rules={field.rules}
                        label="Language 1 "
                      >
                        <Input placeholder={"Enter Language 1"} />
                      </Form.Item>
                    ) : (
                      <Form.Item
                        {...(props.isError && {
                          help: props.errorMessage,
                          validateStatus: "error",
                        })}
                        name={field.name}
                        rules={field.rules}
                        label={field.label}
                      >
                        <Select
                          placeholder={field.placeholder}
                          options={field.options}
                          onChange={handleChange}
                          onClear ={show}
                        />
                      </Form.Item>
                    )}
                  </div>
                );
              })}

              {profileFormFields[8].map((field, index) => {
                return (
                  <div
                    className={
                      steps === 0 &&
                      field.infoType !== "basicInfo" &&
                      style.formItem
                    }
                  >
                    {show1 ? (
                      <Form.Item
                        {...(props.isError && {
                          help: props.errorMessage,
                          validateStatus: "error",
                        })}
                        // name={field.name}
                        // rules={field.rules}
                        // label={field.label}
                         name='language_2'
                        rules={field.rules}
                        label="Language 2 "
                      >
                        <Input placeholder={"Enter Language 2"} />
                      </Form.Item>
                    ) : (
                      <Form.Item
                        {...(props.isError && {
                          help: props.errorMessage,
                          validateStatus: "error",
                        })}
                        name={field.name}
                        rules={field.rules}
                        label={field.label}
                      >
                        <Select
                          placeholder={field.placeholder}
                          options={field.options}
                          onChange={handleChange1}
                        />
                      </Form.Item>
                    )}
                  </div>
                );
              })}
              <Form.Item>
                <Button
                  loading={props.isLoading}
                  disabled={!termsChecked}
                  type="danger"
                  style={{ float: "right" }}
                  htmlType="submit"
                >
                  Submit
                </Button>

                {steps > 0 && (
                  <Button
                    type="secondary"
                    style={{ float: "right", marginRight: "10px" }}
                    onClick={prevStep}
                  >
                    Back
                  </Button>
                )}
              </Form.Item>
            </Form>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default ProfileForm;
