import React from "react";
import Icon from "@ant-design/icons";
import { Input } from "antd";
import style from "./index.module.scss";

export const PostDescription = (props) => {
  return (
    <div className={style.container}>
      <h1>Post Description</h1>

      <Input.TextArea
        placeholder="Description"
        autoSize={{ minRows: 12 }}
        contentEditable={false}
        value={props.post?.description}
        style={{ border: "none", outline: "none" }}
      />
    </div>
  );
};