import { api } from "../../../helpers/api";
import { getAuthUserData } from "../../../helpers/auth.js";
export const SIGNUP_REQUEST_PROCESS = "SIGNUP_REQUEST_PROCESS";
export const SIGNUP_REQUEST_ERROR = "SIGNUP_REQUEST_ERROR";
export const SIGNUP_SUCCESS = "SIGNUP_REQUEST_SUCCESS";
export const SIGNIN_SUCCESS = "SIGNIN_SUCCESS";
export const PROFILE_COMPLETED = "PROFILE_COMPLETED";

const isAuth = getAuthUserData();
export const isProfile = [];
export const signupRequestProcess = () => ({
  type: SIGNUP_REQUEST_PROCESS,
});

export const signupSuccess = (data) => ({
  type: SIGNUP_SUCCESS,
  data,
});

export const signupRequestError = (error) => ({
  type: SIGNUP_REQUEST_ERROR,
  error,
});

export const signinSuccess = (data) => ({
  type: SIGNIN_SUCCESS,
  data,
});

export const profileCompleted = () => ({
  type: PROFILE_COMPLETED,
});
// var name = '';
export const signupRequest = (formData, email) => async (dispatch) => {
  try {
    
    // name = formData./firstName;
    console.log(formData, "formdata")
   
  console.log(formData.availability, "availability")
    formData.email = email;
    dispatch(signupRequestProcess());

    const data = await api("post", "/auth/profile", formData);
    dispatch(signupSuccess(data));

    if (data.data?.isProfileComplete) {
      dispatch(profileCompleted());
    }
  } catch (error) {
    dispatch(signupRequestError(error.response ? error.response.data : error));
  }
};

export const profileRequest = (email, name) => async (dispatch) => {
  try {
    // console.log(name, "In the email")
    const userEmail = {
      email: email,
      name: name
    };
  
    const emailNotification = await api("post", "/auth/send-email", userEmail);
    dispatch(signupSuccess(emailNotification));

    console.log(emailNotification, "email Nofication ", userEmail);
  } catch (error) {
    dispatch(signupRequestError(error.response ? error.response.data : error));
  }
};

export const getLoggedInUserRequest = (id) => async (dispatch) => {
  const response = await api("get", `/users/${id}`, null);

  return response;
};
