import React, { useEffect, useState } from "react";
import { Button, Col, Row, Rate, Tooltip, Space, Divider } from "antd";
import moment, { Moment } from "moment";
import { RatingComponents } from "../../Rating/component";
import {
  CarryOutOutlined,
  UserAddOutlined,
  FieldTimeOutlined,
  EnvironmentOutlined,
  IdcardOutlined,
  DollarOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import { Typography } from "antd";

import style from "./index.module.scss";
import { getDate } from "../../../../helpers/getDate";
import { timeSince } from "../../../../helpers/timeSince";

import { ROLES } from "../../../../constants/ROLES";
import { useNavigate } from "react-router";

const { Title, Text } = Typography;

export const ShiftCard = (props) => {
  const [dateTime, setDateTime] = useState(null);
  const [createTime, setcreateTime] = useState(null);

  const [endTime, setEndTime] = useState(null);
  const [hours, setHours] = useState(null);
  const [discount, setDiscount] = useState();
  const[dayCoverage, setDayCovergae] = useState();
  const [definedDiscount, setDefinedDiscount] = useState();

  const iconStyle = {
    color: "#f44336",
    fontSize: "20px",
    paddingLeft: "0px",
    paddingRight: "10px",
  };

  useEffect(() => {
    
    setDateTime(getDate(props.post.startDate));
    setcreateTime(getDate(props.post.createdAt));

    setEndTime(getDate(props.post?.endDate));
    setDayCovergae(Math.abs((endTime?.day-dateTime?.day ))  +1)
    // console.log(dateTime ,"dateTime");

    const hours = () => {
      var startDate = moment(props.post.dateTime);
      const endData = moment(props.post.endTime);
      const diff = endData.diff(startDate);
      const diffDuration = moment.duration(diff);
      return diffDuration.asHours();
    };
    const date = hours();
    // alert(Math.abs(date))
    setHours(Math.abs(Math.round(date)));
    const discount = () => {
      const off = props.post?.payment;
      let dis;
      if (props.discount)
        dis = `after applicable fees & deductions $${
          off - props.discount * off
        }  `;
      else dis = `after applicable fees & deductions $${off - 0.12 * off}  `;
      // console.log(props.discount, "hjjj")
      return dis;
    };
    const finalDiscount = discount();
    setDiscount(finalDiscount);
  }, [props.discount, dayCoverage]);

  return (
    <div
      style={{
        margin: "24px 0 0",
        marginBottom: "10px",
      }}
    >
      <Row>
        <Col span={24}>
          <Row className={style.cardStyle}>
            <Col span={19}>
              <span>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <Title
                    level={4}
                    style={{
                      marginTop: "0px",
                      lineHeight: "1",
                      fontSize: "23px",
                    }}
                  >
                    {props.post?.name}
                  </Title>
                  <span className={style.iconBox}>
                    {props.role === ROLES.pharmacist ? (
                      // <Text>
                      //   &nbsp;&nbsp; &nbsp;{" "}
                      //   <UserAddOutlined style={iconStyle} />
                      //   {/* {props.post.availableShifts}  */}
                      //   Full Shift Schedule time 900-1700

                      // </Text>
                      <></>
                    ) : (
                      <Text>
                        &nbsp;&nbsp; &nbsp;{" "}
                        <UserAddOutlined style={iconStyle} />
                        {props.post.candidates?.length} Applications
                      </Text>
                    )}
                  </span>
                </div>
              </span>
              <span>
                <Text>
                  By <Text>{props.post.postedBy?.company?.name}</Text>
                </Text>
              </span>
              <div style={{ margin: "31px 0px 11px 0" }}>
                {/* <span className={style.iconBox}>
                  {props.role === ROLES.pharmacist ? (
                    <Text>
                      <CarryOutOutlined style={iconStyle} />
                      {createTime?.month} {createTime?.day} {createTime?.hours}:
                      {createTime?.minutes}
                    </Text>
                  ) : (
                    <Text>
                      <EnvironmentOutlined style={iconStyle} />
                      {props.post.postedBy?.company?.city}
                    </Text>
                  )}
                </span> */}
                <span className={style.iconBox}>
                  {/* {props.role == ROLES.pharmacist ? ( */}
                  <Text style={{marginRight:"10px"}}>
                       <CarryOutOutlined style={iconStyle} />
                       {createTime?.month} {createTime?.day} {createTime?.hours}:
                       {createTime?.minutes}
                     </Text>
                    <Text>
                      <EnvironmentOutlined style={iconStyle} />
                      {props.post.postedBy?.company?.city}
                    </Text>
                  {/* ) : ( */}
                  
                  {/* )} */}
                </span>
                <span className={style.iconBox}>
                  <Text>
                    <FieldTimeOutlined style={iconStyle} />
                    {hours} Hours Shift
                  </Text>
                </span>

                <span className={style.iconBox}>
                  <Text>
                    {/* <CarryOutOutlined  style             ={{iconStyle}} */}
                    <CarryOutOutlined style={iconStyle} />
                    {dateTime?.month} {dateTime?.day}
                    {"-"}
                    {endTime?.month} {endTime?.day} {" "}

                    {/* {props?.startDate?.hours}:{props?.startDate?.minutes} */}
                  </Text>
                </span>


                <span className={style.iconBox}>
                  <Text>
                    <DollarOutlined style={iconStyle} />
                    {props.post?.payment}
                  </Text>
                </span>

                {/* {props.post?.payment}  */}

                <Space wrap>
                  <Tooltip title={discount} color={"#f50"}>
                    {/* <Button>{color}</Button> */}
                    <span className={style.iconBox}>
                      <Text>
                        <InfoCircleOutlined style={{ marginLeft: "-15px" }} />
                      </Text>
                    </span>
                  </Tooltip>
                </Space>
              </div>
              <span>
                <Text>
                  {timeSince(new Date(props.post.createdAt).getTime())} ago {" "}
                </Text>
              </span>
              {/* <span >
                <Text>
                {`${props.post?.days} Days Coverage `}
                </Text>
              </span> */}

             

            </Col>
            <Col
              span={4}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              {props.role === ROLES.pharmacist ? (
                <Button
                  type="danger"
                  block
                  size="large"
                  onClick={() => props.onPickupShiftClick(props.post._id)}
                >
                  Pickup Shift
                </Button>
              ) : props.role === ROLES.pharmacy ? (
                <div className={style.controls}>
                  <Button
                    type="primary"
                    block
                    size="large"
                    onClick={() => props.onCandidateClick(props.post._id)}
                  >
                    Candidates
                  </Button>
                  <Button
                    type="danger"
                    block
                    size="large"
                    onClick={() => props.onEditClick(props.post)}
                  >
                    Edit Post
                  </Button>
                </div>
              ) : (
                <Button
                  type="danger"
                  block
                  size="large"
                  onClick={() => props.onPickupShiftClick(props.post._id)}
                >
                  View Details
                </Button>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};
