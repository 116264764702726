import { IS_ERROR, IS_LOADED, IS_LOADING, IS_SUCCESS } from "./actions";
import { combineReducers } from "redux";

const initialState = {
  isLoading: false,
  isError: false,
  errorMessage: "",
};

const loadingReducer = (state = initialState, action) => {
  switch (action.type) {
    case IS_LOADING:
      return { ...state, isLoading: true };
    case IS_LOADED:
      return { ...state, isLoading: false };

    default:
      return state;
  }
};

const errorReducer = (state = initialState, action) => {
  switch (action.type) {
    case IS_ERROR:
      return { ...state, isError: true };
    case IS_SUCCESS:
      return { ...state, isError: false };
    default:
      return state;
  }
};

export const sharedReducers = combineReducers({
  loading: loadingReducer,
  error: errorReducer,
});
