import React, { useEffect } from "react";

import style from "./index.module.scss";
import { FileIcon, defaultStyles } from "react-file-icon";
import { Button } from "antd";
import { CloudDownloadOutlined, FolderViewOutlined , CloseOutlined } from "@ant-design/icons";

import { Link } from "react-router-dom";

const FilePreview = ({ file, url }) => {

  const getFileExtension = (fileName) => {
    
    return fileName?.split(".").pop();
  };
  const delteFile = () => {
    window.open(url);
  };

  return (
    <div class={style.container}>
      <div className={style.fileIcon}>
        <FileIcon
          extension={getFileExtension(file)}
          {...defaultStyles[getFileExtension(file)]}
        />
      </div>

      <span className={style.ViewBtn}>
        <Link to="/file-view" state={{ type: getFileExtension(file), url }} className={style.ViewBtn}>
          {/* <p className={style.fileName}>
            {file.length > 40 ? `${file.substring(0, 40)}...` : file}
          </p> */}
      {/* <FolderViewOutlined/>    */}
      View 
        </Link>
      </span>

      {/* <Button type="link" onClick={delteFile} className={style.ViewBtn}>
        <CloseOutlined /> 
      </Button> */}
    </div>
  );
};

export default FilePreview;
