import React from "react";
import { Button, Space } from "antd";
import style from "./index.module.scss";
import { Table, Tag, ColumnsType } from "antd";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import {Loader} from '../../../Common/Loader'
export const PaymentsContainer = () => {
  const history = useNavigate();
  const business_owner = () => {
    history("/business-owner-pendings");
  };
  const freelancer = () => {
    history("/freelancer-pendings" );
  };
  const completed = () => {
    history("/completed-payments");
  };
  return (
    <div style={{ justifyContent: "center", marginTop: "25px" }}>
      {/* <div style={{ justifyContent: "center", display: "flex" }}> */}
        <Space wrap className={style.btnStyle}>
          <Button type="primary" danger size="large" onClick={business_owner}>
            Business Owner Pending
          </Button>
          <Button type="primary" danger size="large" onClick={freelancer}>
            Freelancer Pending
          </Button>
          <Button type="primary" danger size="large" onClick={completed}>
            Completed
          </Button>
        </Space>
      </div>
    // </div>
  );
};
