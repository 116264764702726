import React from "react";
import PropTypes from "prop-types";
import { Spin } from "antd";

const LoaderComponent = ({ children }) => {
  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Spin size="large" />
    </div>
  );
};

export const Loader = LoaderComponent;
