import React from "react";
import { Typography, Tabs } from "antd";
import style from "./index.module.scss";
import { ROLES } from "../../../../constants/ROLES";
import { FileIcon, defaultStyles } from "react-file-icon";
import FilePreview from "./FilePreview";

export const UserDetails = (props) => {
  const { candidate } = props;
  const { company } = props;
  const { TabPane } = Tabs;

  console.log(candidate);

  function callback(key) {
    console.log(key);
  }

  // const getFileExtension = (fileName) => {
  //   return fileName.split(".").pop();
  // };

  return (
    <div>
      <div class={style.container}>
        <Tabs defaultActiveKey="1" onChange={callback}>
          <TabPane tab="User Details" key="1">
            <Typography.Title level={2}>User Information</Typography.Title>
            <Typography.Paragraph strong>
              Full Name:{" "}
              <Typography.Text>{`${
                candidate?.firstName ? candidate?.firstName : ""
              } ${candidate?.middleName ? candidate?.middleName : ""} ${
                candidate?.lastName ? candidate?.lastName : ""
              }`}</Typography.Text>
            </Typography.Paragraph>
            <Typography.Paragraph strong>
              Email: <Typography.Text>{candidate?.email}</Typography.Text>
              <br/>
              <Typography.Paragraph strong>
              Phone: <Typography.Text>{candidate?.phone ? candidate?.phone : company?.phone}</Typography.Text>
            </Typography.Paragraph>
            </Typography.Paragraph>
            
            {candidate?.role !== ROLES.pharmacy && (
              <>
             
                <Typography.Paragraph strong>
                  City: <Typography.Text>{candidate?.city}</Typography.Text>
                </Typography.Paragraph>
                <Typography.Paragraph strong>
                  State: <Typography.Text>{candidate?.state}</Typography.Text>
                </Typography.Paragraph>
                <Typography.Paragraph strong>
                  Availability:{" "}
                  <Typography.Text>
                    {candidate?.availability ? candidate?.availability : "-"}
                  </Typography.Text>
                </Typography.Paragraph>
                <Typography.Paragraph strong>
                  BLS:{" "}
                  <Typography.Text>
                    {candidate?.bls ? candidate?.bls : "-"}
                  </Typography.Text>
                </Typography.Paragraph>
                <Typography.Paragraph strong>
                  Education:{" "}
                  <Typography.Text>
                    {candidate?.education ? candidate?.education : "-"}
                  </Typography.Text>
                </Typography.Paragraph>
                <Typography.Paragraph strong>
                  Immunizer:{" "}
                  <Typography.Text>
                    {candidate?.immunizer ? candidate?.immunizer : "-"}
                  </Typography.Text>
                </Typography.Paragraph>

                <Typography.Paragraph strong>
                  Software used 1:{" "}
                  <Typography.Text>
                    {candidate?.software1 ? candidate?.software1 : "-"}
                    {" |  proficiency Level: "}{" "}
                    {candidate?.proficiency1 ? candidate?.proficiency1 : "-"}
                  </Typography.Text>
                </Typography.Paragraph>
                <Typography.Paragraph strong>
                  Software used 2:{" "}
                  <Typography.Text>
                    {candidate?.software2 ? candidate?.software2 : "-"}{" "}
                    {" | proficiency Level: "}{" "}
                    {candidate?.proficiency2 ? candidate?.proficiency2 : "-"}
                  </Typography.Text>
                </Typography.Paragraph>
                <Typography.Paragraph strong>
                  Language spoken 1:{" "}
                  <Typography.Text>
                    {candidate?.language1 ? candidate?.language1 : "-"}
                  </Typography.Text>
                </Typography.Paragraph>
                <Typography.Paragraph strong>
                  Language spoken 2:{" "}
                  <Typography.Text>
                    {candidate?.language2 ? candidate?.language2 : "-"}
                  </Typography.Text>
                </Typography.Paragraph>
              </>
            )}

            {company && (
              <>
                <Typography.Title level={3}>
                  Company Information
                </Typography.Title>
                <Typography.Paragraph strong>
                  Name: <Typography.Text>{company?.name}</Typography.Text>
                </Typography.Paragraph>
                <Typography.Paragraph strong>
                  Business Email:{" "}
                  <Typography.Text>{company?.email}</Typography.Text>
                </Typography.Paragraph>
                <Typography.Paragraph strong>
                  Country: <Typography.Text>{company?.county}</Typography.Text>
                </Typography.Paragraph>
                <Typography.Paragraph strong>
                  City: <Typography.Text>{company?.city}</Typography.Text>
                </Typography.Paragraph>
                <Typography.Paragraph strong>
                  Address: <Typography.Text>{company?.address}</Typography.Text>
                </Typography.Paragraph>
              </>
            )}
          </TabPane>

          {candidate?.role === ROLES.pharmacist && (
            <TabPane tab="Files" key="2">
              <Typography.Paragraph strong>Identification</Typography.Paragraph>
              <FilePreview
                file={candidate?.driverLiscenceCard?.split("/")[3]}
                url={candidate?.driverLiscenceCard}
              />
              <Typography.Paragraph strong>
                 Licence and Registration
              </Typography.Paragraph>
              <FilePreview
                file={candidate?.idCard?.split("/")[3]}
                url={candidate?.idCard}
              />
              {/* <Typography.Paragraph strong>Passport</Typography.Paragraph>
              <FilePreview
                file={candidate?.passport?.split("/")[3]}
                url={candidate?.passport}
              /> */}
              <Typography.Paragraph strong>
                Work Authorization
              </Typography.Paragraph>
              <FilePreview
                file={candidate?.visa?.split("/")[3]}
                url={candidate?.visa}
              />
              {/* <Typography.Paragraph strong>Green Card</Typography.Paragraph>
              <FilePreview
                file={candidate?.greenCard?.split("/")[3]}
                url={candidate?.greenCard}
              /> */}
              {/* <Typography.Paragraph strong>Work Permit</Typography.Paragraph>
              <FilePreview
                file={candidate?.workPermit?.split("/")[3]}
                url={candidate?.workPermit}
              /> */}
            </TabPane>
          )}
        </Tabs>
      </div>
    </div>
  );
};
