import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Rate,
  Modal,
  Input,
  Form,
  Button,
  Col,
  Row,
  Popconfirm,
  message,
  Space,
  Tooltip,
} from "antd";
import {
  CarryOutOutlined,
  FieldTimeOutlined,
  EnvironmentOutlined,
  DollarOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import { Typography } from "antd";
import { getDate } from "../../../../../helpers/getDate";
import moment, { Moment } from "moment";

const { Title, Text } = Typography;
export const ScheduleCard = (props) => {
  const post = props?.post;
  const candidateID = props?.candidateID;
  const status = useRef();
  const cmplt = useRef();
  const [discount, setDiscount] = useState();
  const [dateTime, setDateTime] = useState(null);
  const [feedbackCmplt, setfeedbackCmplt] = useState();
  const [loading, setLoading] = useState(false);
  const [complete, setComplete] = useState();
  const [rating, setRating] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm();
  const [comment, setComment] = useState(null);
  const [Candidatestatus, setStatus] = useState();
  const [feedbackBtn, setFeedbackBtn] = useState();
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);

  const [pharmacistFeedback, setPharmacistFeedback] = useState();
  // const [dateTime, setDateTime] = useState(null);
  const [hours, setHours] = useState(null);
  const text = "Are you sure to mark this job as complete?";
  const description = "Mark complete";
  const desc = ["terrible", "bad", "normal", "good", "wonderful"];

  const confirm = (id, company, data) => {
    props.onMarkComplete(id, company,data);
    message.info("Your job is submitted for a review from client!");
  };

  const showModal = (postID, postedby) => {
    props.postID(postID, postedby);
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleChange = (value) => {
    setRating(value);
  };
  const handleCommentChange = (event) => {
    // setComment(event.target.value);
    console.log(event.target.value);
    setComment({ comment: event.target.value });
  };

  const cardStyle = {
    padding: "24px 20px",
    margin: "8px 0",
    background: "#fff",
  };
  const iconBox = {
    marginRight: "12px",
  };
  const iconStyle = {
    color: "#f44336",
    fontSize: "17px",
    paddingLeft: "0px",
    paddingRight: "10px",
  };

  useEffect(() => {
    setDateTime(getDate(post.dateTime));
    status.current = getStatus();
    cmplt.current = getIsComplete();
  }, [post, getStatus, getIsComplete, props.posts]);

  const getStatus = useCallback(() => {
    if (post) {
      console.log(post.candidates);
      const candidate = post.candidates.find((candidate) => {
        if (candidate.candidate && candidateID === candidate.candidate._id) {
          setComplete(candidate.isComplete);
          setStatus(candidate?.status);
          return candidate?.status;
        }
        return null;
      });
      return candidate?.status;
    }
  });
  
  const getIsComplete = useCallback(() => {
    if (post) {
      console.log(post.candidates);

      post.candidates.find((candidate) => {
        if (candidateID === candidate.candidate?._id) {
          setfeedbackCmplt(candidate.isPharmmcyFeedback);
          setPharmacistFeedback(candidate.isPharmacistFeedback);
        }
        return null;
      });
    }
  });

  const getBgColor = () => {
    return status.current === "Approved" ? "#FFFF" : "#000000";
  };
  const getColor = () => {
    return status.current === "Approved"
      ? "#95EBB9"
      : status.current === "Disapproved"
      ? "#FFB9B9"
      : "#f8ecc4";
  };
  useEffect(() => {
    if (pharmacistFeedback) setFeedbackBtn(true);
    else setFeedbackBtn(false);
  });

  const onFinish = async () => {
    console.log(comment, "comment");
    console.log(rating, "rating");

    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setOpen(false);
      setIsModalOpen(false);
    }, 1000);

    console.log(props.idpost, "id");
    const Feedback = await props.filterFeedback(
      props.idpost,
      props.postedbyID,
      props.candidateID
    );
    const feedbackID = Feedback?.data?.feedback.map((user) => {
      return user?._id;
    });
    console.log(feedbackID.length, "length");

    if (feedbackID.length <= 0) {
      await props.submitFeedbackRequest(
        comment,
        props.idpost,
        props.postedbyID,
        props.candidateID,
        rating
      );
      await props.onPharmacistFeedback(
        props.idpost,
        post.candidates,
        props.candidateID
      );
      message.info("Feedback Submited.");
      // history("my")
    } 
    // else {
    //   feedbackID.toString();
    //   console.log(feedbackID, "FeedbackID");
    //   await props.updateFeedback(feedbackID, rating, comment);
    //   message.info(`Feedback Updated `); //  console.log(rating, props.idpost,  props.postedbyID, {...comment});
    // }
  };
  useEffect(() => {
    setDateTime(getDate(props.post?.createdAt));
    setStartTime(getDate(props.post.startDate));
    setEndTime(getDate(props.post?.endDate));
    console.log(dateTime);

    const hours = () => {
      var startDate = moment(props.post.dateTime);
      const endData = moment(props.post.endTime);
      const diff = endData.diff(startDate);
      const diffDuration = moment.duration(diff);
      return diffDuration.asHours();
    };
    const date = hours();

    setHours(Math.abs(Math.round(date)));

    const discount = () => {
      const off = props.post?.payment;
      let dis;
      if (props.discount)
        dis = `after applicable fees & deductions $${off - props.discount * off}  `;
      else dis = `after applicable fees & deductions $${off - 0.12 * off}  `;
      // console.log(props.discount, "hjjj");
      return dis;
    };
    const finalDiscount = discount();
    setDiscount(finalDiscount);
  }, [props.discount]);
  return (
    <Row>
      <Col span={24}>
        <Row style={cardStyle}>
          <Col span={14}>
            <span>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "800px",
                }}
              >
                <Title
                  level={3}
                  style={{
                    marginTop: "0px",
                    lineHeight: "1",
                    fontSize: "23px",
                  }}
                >
                  {post?.name}
                </Title>
                <div style={{ margin: "3px 0px 11px 14px" }}>
                  <span style={iconBox}>
                    <Text>
                      <CarryOutOutlined style={iconStyle} />
                      {dateTime?.month} {dateTime?.day} {dateTime?.hours}:
                      {dateTime?.minutes}
                    </Text>
                  </span>
                  <span style={iconBox}>
                    <Text>
                      <EnvironmentOutlined style={iconStyle} />
                      {post.postedBy?.company?.city}
                    </Text>
                  </span>
                  <span style={iconBox}>
                    <Text>
                      <FieldTimeOutlined style={iconStyle} />
                      {hours} Hours Shift
                    </Text>

                    </span>
                    <span style={iconBox}>
                    <Text>
                    <CarryOutOutlined style={iconStyle} />

                    {startTime?.month} {startTime?.day}
                    {"-"}
                    {endTime?.month} {endTime?.day}{" "}
                    {/* {props?.startDate?.hours}:{props?.startDate?.minutes} */}
                    </Text>
                  </span>
                  <span>
                    <Text>
                      <DollarOutlined style={iconStyle} />
                      {props.post?.payment}
                    </Text>
                  </span>
                  <span style={{ paddingLeft: "20px" }}>
                    <Space wrap>
                      <Tooltip title={discount} color={"#f50"}>
                        {/* <Button>{color}</Button> */}
                        <span>
                          <Text>
                            {/* <InfoCircleOutlined style={iconStyle} /> */}
                            <InfoCircleOutlined
                              style={{ marginLeft: "-15px" }}
                            />
                          </Text>
                        </span>
                      </Tooltip>
                    </Space>
                  </span>
                </div>
              </div>
            </span>
            <span>
              <Text>
                By <Text underline>{post.postedBy?.company?.name} </Text>
              </Text>
            </span>
            <div style={{ margin: "22px 0 0px 0", width: "13%" }}>
              <Button
                type="primary"
                size="large"
                block
                style={{
                  backgroundColor: getColor(),
                  color: getBgColor(),
                  borderColor: getColor(),
                  width: "115px",
                }}
              >
                {status.current}
              </Button>
            </div>
          </Col>

          <Col
            span={6}
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: "82px",
              marginLeft: "40px",
              marginRight: "auto",
            }}
          >
            {status.current !== "Disapproved" && (
              <Button
                type="danger"
                block
                size="large"
                onClick={() => props.onPressViewShift(post._id)}
                style={{ display: "flex" }}
              >
                View Shift
              </Button>
            )}

            <Popconfirm
              placement="top"
              title={text}
              description={description}
              onConfirm={() => confirm(post._id, post?.postedBy?.company?._id, post.candidates)}
              okText="Yes"
              cancelText="No"
              disabled={complete || Candidatestatus == "Pending" || Candidatestatus=='Disapproved'}
            >
              <Col span={16} style={{ display: "flex" }}>
                <Button
                  type="danger"
                  block
                  size="large"
                  disabled={complete || Candidatestatus == "Pending" || Candidatestatus=='Disapproved'}
                  style={{
                    width: "150px",
                    marginLeft: "8px",
                    backgroundColor: "#83e3ad",
                    float: "right",
                    alignContent: "center",
                    display: "flex",
                  }}
                >
                  Mark Complete
                </Button>
              </Col>
            </Popconfirm>
            <Col span={12} style={{ display: "flex" }}>
              {feedbackCmplt && complete ? (
                <div>
                  <Button
                    type="danger"
                    size="large"
                    onClick={() => showModal(post._id, post.postedBy._id)}
                    hidden={feedbackBtn}
                    style={{ backgroundColor: "#f44336", color: "white", marginLeft:'3px' }}
                    //  style={{alignItems:"center", float:"right"}}
                  >
                    {/* {feedbackBtn} */}
                    Add Review
                  </Button>

                  <Modal
                    title="Feedback"
                    open={isModalOpen}
                    onCancel={handleCancel}
                    onOk={onFinish}
                  >
                    <Form
                      // onFinish={onFinish}
                      // id="submit-data"
                      initialValues={{ remember: true }}
                    >
                      <span>
                        <Form.Item
                          {...(props.isError && {
                            help: props.errorMessage,
                            validateStatus: "error",
                          })}
                        >
                          <Rate
                            tooltips={desc}
                            onChange={handleChange}
                            value={rating}
                            name="ratings"
                          />
                          {rating ? (
                            <span className="ant-rate-text">
                              {desc[rating - 1]}
                            </span>
                          ) : (
                            ""
                          )}
                        </Form.Item>
                      </span>

                      <Form.Item
                        {...(props.isError && {
                          help: props.errorMessage,
                          validateStatus: "error",
                        })}
                        name="comment"
                        rules={[
                          {
                            required: true,
                            message: "Please enter a description",
                          },
                        ]}
                        style={{ marginTop: "10px" }}
                      >
                        <Input.TextArea
                          placeholder="Enter Description here "
                          autoSize={{ minRows: 8 }}
                          value={comment}
                          onChange={handleCommentChange}
                        />
                      </Form.Item>
                      {/* <Form.Item>
                        <Button onClick={handleCancel}>cancel</Button>,
                        <Button
                          type="primary"
                          htmlType="submit"
                          form="submit-data"
                        >
                          Submit
                        </Button>
                      </Form.Item> */}
                    </Form>
                  </Modal>
                </div>
              ) : (
                <></>
              )}
            </Col>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
