import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { withRouter } from "../../../../helpers/withRouter";
import style from "./index.module.scss";
import { connect } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import {
  getCandidateInformationRequest,
  updatePostInformationRequest,
  createPaymentRequest,
  filterPayment,
  getPostByyPostID,
  createContractsRequest
} from "../service";
import { message } from "antd";
import { CandidateInfo } from "../components/CandidateInfo";
import { Loader } from "../../../Common/Loader";

const CandidateInformation = (props) => {
  const { id } = useParams();
  const history = useNavigate();
  const location = useLocation();
  const [payment, setPayment] = useState();
  const [messageApi, contextHolder] = message.useMessage();

  const post = location?.state;

  console.log(id);
  console.log(post, "POSTS");
  // console.log(status1, "Status");
  const [candidate, setCandidate] = useState();
  const [candidateStatus, setCandidateStatus] = useState();
  const [userstatus, setUserStatus] = useState();

  const [agency, setAgency] = useState();
  const [isInitialLoading, setIsInitialLoading] = useState(true);

  // const userStatus = post?.candidates.map((candidates) => {
  //   if (candidate?._id === candidates?.candidate?._id) {
  //     // console.log(candidates?.canddidate?.status, "shaj")
  //     return candidates?.status;
  //   }
  // });

  //  console.log(userStatus.toString(), "shjdsh")
  useEffect(() => {
    const fetchCandidate = async () => {
      const response = await props.getCandidateInformationRequest(id);
      setCandidate(response?.data?.user);
      const user1 = await props.getCandidateInformationRequest(props?.user?.id);
      setAgency(user1?.data?.user?.company._id);
      const postStatus = await props.getPostByyPostID(post?._id);
      setCandidateStatus(postStatus?.data?.post?.candidates);
      let statusData;
      postStatus.data?.post?.candidates.map((candidate) => {
        if (candidate?.candidate._id === id) {
          statusData = candidate?.status;
          return candidate?.status;
        }
      });

      setUserStatus(statusData);
  
      setIsInitialLoading(false);
    };

    fetchCandidate();
  }, []);

  const success = () => {
    messageApi.open({
      type: "success",
      content:
        "Candidate status has been updated successfully. You will be redirected to the candidates page",
      duration: 2,
    });
  };

  const onChangeStatus = async (status, candidateID) => {
    // alert(status)
    if (status === "Approved") {
      post.availableShifts = post.availableShifts - 1;
      // const response = await props.filterPayment(
      //   agency,
      //   candidateID,
      //   post?._id
      // );
      // setPayment(response?.data?.payment);
      // if (response?.data?.payment?.length == 0) {
        await props.createPaymentRequest(
          agency,
          candidateID,
          post?.payment,
          post?._id
        );
      
      // message.info("Payment Created")
    }
   
    post.candidates = post?.candidates.map((candidate) => {
      if (candidate.candidate._id === candidateID) {
        candidate.status = status;
      }
      return candidate;
    });
   
    await props.updatePostInformationRequest(post._id, post);
    await props.createContractsRequest(candidateID,  post?.postedBy?.company?._id, post?.startDate, post?.endDate , post?.payment, post?._id)
    success();
    setTimeout(() => {
      history(`/candidates/${post._id}`);
    }, 3000);
  };

  return (
    <>
      {contextHolder}
      <div className={style.container}>
        {props.isLoading && isInitialLoading ? (
          <Loader />
        ) : (
          <CandidateInfo
            candidate={candidate}
            userstatus = {userstatus}
            onChangeStatus={onChangeStatus}
            isLoading={props.isLoading}
            status={userstatus}
          />
        )}
      </div>
    </>
  );
};

CandidateInformation.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  isAuth: PropTypes.bool.isRequired,
  isSuccess: PropTypes.bool.isRequired,
  isError: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
  getCandidateInformationRequest: PropTypes.func.isRequired,
  updatePostInformation: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  isLoading: state.shared.loading.isLoading,
  isAuth: state.auth.signin.isAuth,
  isError: state.auth.signup.isError,
  isSuccess: state.auth.signup.isSuccess,
  errorMessage: state.auth.signup.errorMessage,
  user: state.auth.signin.user,
});

export const CandidateInformationContainer = withRouter(
  connect(mapStateToProps, {
    getCandidateInformationRequest,
    updatePostInformationRequest,
    createPaymentRequest,
    filterPayment,
    getPostByyPostID,
    createContractsRequest
  })(CandidateInformation)
);
