import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { withRouter } from "../../../../helpers/withRouter";
import style from "./index.module.scss";
import { connect } from "react-redux";
import { Modal, message } from "antd";
import { MyScheduleContainer } from "../../../Cabinet/MySchedule/container";
import {
  getAllFeedbacks,
  getFeedbackID,
  getMyScheduleRequest,
  getAllPostsRequest,
  updateCandidateRequest,
  updateFeedback,
  submitFeedbackRequest,
  getFeedbacksByPostID,
  filterFeedback,
} from "../service";
import { randomNumberInRange } from "../../../../constants/UNIQUE_ID";
import { Loader } from "../../../Common/Loader";
import { ItemNotFound } from "../../../Common/ItemNotFound";
import { Table, Space, Button } from "antd";
import { Tabs } from "antd";
import { useNavigate } from "react-router";
import { ScheduleCard } from "../../MySchedule/components/ScheduleCard";
import { ShiftCard } from "../../../Common/Shift";
const Allfeedback = (props) => {
  const [user, setUser] = useState([]);
  const history = useNavigate();
  const [posts, setPosts] = useState([]);
  const [userInfo, setUserInfo] = useState([]);

  const [role, setRole] = useState();
  const [postToReview, setPostToReview] = useState([]);
  const [discount, setDiscount] = useState();
  const [freelancerPost, setFreelancerPost] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [id, setPostID] = useState();
  const [postedbyID, setPostbyID] = useState();
  const [reviewer, setReviewer] = useState();
  const [users, setUsers] = useState();

  const { TabPane } = Tabs;
  function callback(key) {
    console.log(key);
  }
  useEffect(() => {
    // alert(props.user.id)
    const getData = async () => {
      const details = [];
      const userPost = await props.getAllPostsRequest(props.user?.id);
      const data = await props.getAllFeedbacks(props.user?.id);

      setUser(data?.data?.feedback);
      setDiscount(userPost?.data.discount);
      console.log(data, "data");
      if (props.user.role === "Pharmacy") {
        userPost?.data?.posts?.map((candidate) => {
          candidate.candidates.map((user) => {
            if (user.isComplete && !user.isPharmmcyFeedback) {
              if (!details.includes(candidate)) {
                details.push(candidate);
              }
            }
          });
        });
      }

      setRole(props.user.role);

      console.log(details, "userPost");
      console.log(userPost?.data?.posts, "user");
      setPostToReview(details);
    };
   
    getData();
    if(props.user?.role ==='Freelancer')
    setReviewer(["reviewerID", "company", "name"])
  }, []);

  useEffect(()=>{
//     if(props.user.role === 'Pharmacy')
//     {
// setReviewer( ["reviewerID", "firstName"])}
// else
// setReviewer( ["reviewerID", "company", "name"])
// alert(props.user.role)
// if(props.user.role === 'Freelancer'){
// setReviewer["reviewerID", "company", "name"]}
  }, [users])
  const [columns, setColumns] = useState([
    {
      title: "Post",
      dataIndex: ["postID", "name"],
      key: randomNumberInRange(),
    },
{
      title: "Reviewer",
      dataIndex: props.user.role==='Pharmacy' ?["reviewerID", "firstName"]:["reviewerID", "company", "name"],
      key: randomNumberInRange(),
},

    {
      title: "Rating",
      dataIndex: "ratings",
      key: randomNumberInRange(),
    },
    {
      title: "Action",

      dataIndex: "_id",
      key: randomNumberInRange(),
      render: (text, record) => (
        <Space size="middle">
          <Button type="primary" danger onClick={() => showModal(record?._id)}>
            View Details
          </Button>
        </Space>
      ),
    },
  ]);

  const showModal = (id) => {
    Details(id);
    setIsModalVisible(true);
  };
  const handleOk = () => {
    setIsModalVisible(false);
  };
  const Details = async (id) => {
    const response = await props.getFeedbackID(id);
    console.log("Inside UseEffect of userInfo", response);
    setUserInfo(response?.data?.feedback);
    // console.log(reviewer, "sdijsdi")
  };
  const onEditClick = (post) => {
    history("/make-post", { state: post });
  };

  const onCandidateClick = (post) => {
    history(`/candidates/${post}`);
  };
  useEffect(() => {
    const detail2 = [];

    const getSchedule = async () => {
      const response = await props.getMyScheduleRequest(props.user?.id);
      setPosts(response.data.posts);
      setDiscount(response?.data?.discount);

      response?.data?.posts?.map((candidate) => {
        candidate.candidates.map((user) => {
          if (user.candidate._id === props.user.id) {
            if (
              user.isComplete === true &&
              user.isPharmmcyFeedback === true &&
              user.isPharmacistFeedback == false
            ) {
              console.log(
                candidate._id,
                candidate.name,
                user.isComplete,
                user.isPharmmcyFeedback,
                user.isPharmacistFeedback,
                "namw"
              );
              if (!detail2.includes(candidate)) {
                detail2.push(candidate);
              }
            }
            // console.log("yes")
          }
        });
      });
      console.log(detail2, "freelancer");
      console.log(response?.data?.posts, "UserFreelancer");
      setFreelancerPost(detail2);
    };
    getSchedule();
  }, []);
  const onPressViewShift = (postID) => {
    history(`/post-details/${postID}`, { state: "my-schedule" });
  };

  const postID = (postID, postedby) => {
    setPostID(postID);
    setPostbyID(postedby);
  };

  const data = async (comment, rating) => {
    console.log(rating, "rating");
    console.log(comment, "comment");
    // const Feedback = await props.filterFeedback(id, postedbyID, props.user?.id);
    // const feedbackID = Feedback?.data?.feedback.map((user) => {
    //   return user?._id;
    // });
    // console.log(feedbackID.length, "length");

    // if (feedbackID.length > 0) {
    //   feedbackID.toString();
    //   console.log(feedbackID, "FeedbackID");
    //   await props.updateFeedback(feedbackID, rating, comment);
    //   message.info("Feedback Updated");
    // } else {
      await props.submitFeedbackRequest(
        id,
        props.user?.id,
        postedbyID,
        rating,
        comment
      );
      console.log(rating, id, postedbyID, { ...comment });
      message.info("Feedback submitted");
      setUsers(true)
    // history("/all-feedbacks")
  };

  const onMarkComplete = async (postID, caandidates) => {
    const data = caandidates?.map((candidate) => {
      if (candidate.candidate._id === props.user?.id) {
        candidate.isComplete = true;
      }
      return candidate;
    });
    const data1 = {
      candidates: data,
    };
    const response = await props.updateCandidateRequest(postID, data1);
    console.log(response);
  };

  const onPharmacistFeedback = async (postID, caandidates, id) => {
    const data = caandidates?.map((candidate) => {
      if (candidate.candidate._id === id) {
        candidate.isPharmacistFeedback = true;
      }
      return candidate;
    });

    const data1 = {
      candidates: data,
    };
    const response = await props.updateCandidateRequest(postID, data1);
    console.log(response);
  };

  return (
    <div className={style.container}>
      <Tabs
        defaultActiveKey="1"
        onChange={callback}
        className={style.custom_tab}
        style={{
          width: "100%",
        }}
      >
        <TabPane
          tab="Reviewed"
          key="1"
          style={{
            width: "100%",
            color: "#f44336",
          }}
        >
          {props.isLoading ? (
            <Loader />
          ) : user.length > 0? (
            <>
              <Table
                columns={columns}
                dataSource={user}
                style={{
                  width: "100%",
                  alignContent: "center",
                  marginTop: "30px",
                }}
              />
              <Modal
                title="Feedback Details"
                open={isModalVisible}
                onOk={handleOk}
                onCancel={handleOk}
              >
                <p>Ratings: {userInfo?.ratings}</p>
                <p>Comment: {userInfo?.comment}</p>
              </Modal>
              .
            </>
          ) : (
            <ItemNotFound>
              <h1 style={{ color: "#808080" }}>There is No Feedback</h1>
            </ItemNotFound>
          )}
        </TabPane>

        <TabPane tab="Pending Review" key="2">
          {
          
          role === "Pharmacy" && postToReview  && postToReview.length>0? (
            postToReview.map((post) => {
              return (
                <ShiftCard
                  key={post._id}
                  post={post}
                  role={props.user?.role}
                  onEditClick={onEditClick}
                  onCandidateClick={onCandidateClick}
                  discount={discount}
                />
              );
            })
          ) :  props.isLoading ?(
            <Loader/>):
            (role === "Freelancer" ||
          role === "Pharmacist") && (freelancerPost && freelancerPost.length>0) ? (
            freelancerPost.map((postt) => (
              <ScheduleCard
                key={postt._id}
                id={postt._id}
                post={postt}
                posts={posts}
                candidateID={props.user?.id}
                onPressViewShift={onPressViewShift}
                onMarkComplete={onMarkComplete}
                updateFeedback={props.updateFeedback}
                submitFeedbackRequest={props.submitFeedbackRequest}
                filterFeedback={props.filterFeedback}
                data={data}
                postID={postID}
                idpost={id}
                postedbyID={postedbyID}
                onPharmacistFeedback={onPharmacistFeedback}
                discount={discount}
                us= {users}
              />
            ))
          ): 
          (<ItemNotFound>
            <h1 style={{ color: "#808080" }}>There is no Post to Review</h1>
          </ItemNotFound>)
          }
         
        
       
        </TabPane>
      </Tabs>
    </div>
  );
};

Allfeedback.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  isAuth: PropTypes.bool.isRequired,
  isSuccess: PropTypes.bool.isRequired,
  isError: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
  getAllPostsRequest: PropTypes.func.isRequired,
  searchPostsRequest: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  isLoading: state.shared.loading.isLoading,
  isAuth: state.auth.signin.isAuth,
  isError: state.auth.signup.isError,
  isSuccess: state.auth.signup.isSuccess,
  errorMessage: state.auth.signup.errorMessage,
  user: state.auth.signin.user,
});

export const AllFeedbackContainer = withRouter(
  connect(mapStateToProps, {
    getAllFeedbacks,
    getFeedbackID,
    getAllPostsRequest,
    getMyScheduleRequest,
    updateCandidateRequest,
    updateFeedback,
    filterFeedback,
    submitFeedbackRequest,
    getFeedbacksByPostID,
  })(Allfeedback)
);
