import {
  SIGNIN_REQUEST_PROCESS,
  SIGNIN_SUCCESS,
  SIGNIN_REQUEST_ERROR,
  LOGOUT,
  PROFILE_COMPLETED,
  PROFILE_NOT_COMPLETED,
} from "./actions";

const initialState = {
  isLoading: false,
  isError: false,
  errorMessage: "",
  isAuth: false,
  isProfileCompleted: false,
  accessToken: "",
  refreshToken: "",
  user: {
    id: "",
    name: "",
    email: "",
    role: "",
    firstName: "",
    lastName: "",
    isProfileComplete: false,
  },
};

export const signinReducer = (state = initialState, action) => {
  switch (action.type) {
    case SIGNIN_REQUEST_PROCESS:
      return { ...state, isError: false, errorMessage: "", isLoading: true };
    case SIGNIN_REQUEST_ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
        errorMessage: action.error.message,
      };
    case SIGNIN_SUCCESS:
      console.log(action.data, "action.data");
      return {
        ...state,
        isLoading: false,
        isAuth: true,
        user: action.data?.user,
        role: action.data.role,
        firstName: action.data.firstName,
        lastName: action.data.lastName,
        isProfileCompleted: action.data?.isProfileComplete,
        accessToken: action.data.accessToken,
        refreshToken: action.data.refreshToken,
      };
    case LOGOUT:
      return { ...initialState };
    case PROFILE_COMPLETED:
      return {
        ...state,
        isProfileCompleted: true,
      };
    case PROFILE_NOT_COMPLETED:
      return {
        ...state,
        isProfileCompleted: false,
      };
    default:
      return state;
  }
};
