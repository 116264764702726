import React from "react";
import { Layout } from "antd";
import { Sidebar } from "./Sidebar";
import { Header } from "./Header";
import { Footer } from "./Footer";
import PropTypes from "prop-types";
import style from "./index.module.scss";
import { ROLES } from "../../../../../constants/ROLES";
import { STATUS } from "../../../../../constants/STATUS";

const { Content } = Layout;

const CabinetComponent = ({ children, menuList, user, logout }) => {
  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Header user={user} logout={logout} />
      <Layout>
        {((user?.isProfileComplete && user?.status === STATUS.active) ||
          (user?.role === ROLES.pharmacy && user?.status === STATUS.active && (user?.company!=null || user?.company!=undefined)) ||
          user?.role === ROLES.admin) && (
          <Sidebar menuList={menuList} logout={logout} />
        )}
        <Layout style={{ padding: "0 24px 24px" }}>
          <Content className={style.cabinetContent}>{children}</Content>
          <Footer />
        </Layout>
      </Layout>
    </Layout>
  );
};

CabinetComponent.propTypes = {
  menuList: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
};

export const Cabinet = CabinetComponent;
