import React, { useState } from "react";
import { Form, Input, Button, Checkbox, Select } from "antd";
import Icon from "@ant-design/icons";
import style from "./index.module.scss";
import { Link } from "react-router-dom";

const SignupForm = (props) => {
  const [termsChecked, setTermsChecked] = useState(false);
  const onFinish = async (values) => {
    const data = await props.onSubmit(values);

    if (data.status === "success") props.setSuccess(true);
  };
  const onChange = (e) => {
    setTermsChecked(e.target.checked);
  };

  return (
    <Form
      onFinish={onFinish}
      className={style.signupForm}
      size="large"
      layout="vertical"
    >
      <h1 className={style.authHeader}>Create account</h1>
      <p>
        Thank you for your interest! Please take a minute to create your
        Candidate Portal where you will be able to monitor your job
        application(s) and complete associated tasks. PwC email addresses should
        not be used.
      </p>
      <p>
        Password Requirements:
        <ul>
          <li>An uppercase character</li>
          <li>A numeric character</li>
          <li>An alphabetic character</li>
          <li>A special character</li>
          <li>A lowercase character</li>
          <li>A minimum of 8 characters</li>
        </ul>
      </p>
      <Form.Item
        {...(props.isError && {
          help: props.errorMessage,
          validateStatus: "error",
        })}
        name="email"
        rules={[
          { required: true, message: "Please input your email!" },
          { type: "email", message: "The input is not valid E-mail!" },
        ]}
        label="Email"
      >
        <Input
          prefix={<Icon type="mail" style={{ color: "rgba(0,0,0,.25)" }} />}
          placeholder="Enter your email"
        />
      </Form.Item>
      <Form.Item
        {...(props.isError && {
          help: props.errorMessage,
          validateStatus: "error",
        })}
        name="password"
        rules={[{ required: true, message: "Please input your password!" }]}
        label="Password"
      >
        <Input
          prefix={<Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />}
          type="password"
          placeholder="Enter your password"
        />
      </Form.Item>
      <Form.Item
        {...(props.isError && {
          help: props.errorMessage,
          validateStatus: "error",
        })}
        name="confirmPassword"
        rules={[{ required: true, message: "Please input your password!" }]}
        label="Confirm password"
      >
        <Input
          prefix={<Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />}
          type="password"
          placeholder="Confirm your password"
        />
      </Form.Item>
      <Form.Item
        {...(props.isError && {
          help: props.errorMessage,
          validateStatus: "error",
        })}
        name="role"
        rules={[{ required: true, message: "Please select a role" }]}
        label="Role"
      >
        <Select
          placeholder="Select a role"
          options={[
            // {
            //   value: "Admin",
            //   label: "Admin",
            // },
            {
              value: "Pharmacy",
              label: "Pharmacy",
            },
            {
              value: "Freelancer",
              label: "Freelancer",
            },
          ]}
        />
      </Form.Item>
      <Form.Item>
        <p>
          By creating this account, you acknowledge that you have read and
          understand the Terms of Use and Privacy Statement. To the extent
          consent is required under applicable law, by creating this account you
          hereby consent to the collection and processing of your personal
          information (including any sensitive personal information you choose
          to provide) by Etesal in connection with your account as described in
          the Privacy Statement.
        </p>
        <p style={{ padding: "1rem 0" }}>
          <Checkbox onChange={onChange}>
            Yes, I have read and consent to the terms and conditions.
          </Checkbox>
        </p>
        <Button
          loading={props.isLoading}
          disabled={!termsChecked}
          type="primary"
          style={{
            width: "100%",
            background: termsChecked && "#f44336",
            borderColor: termsChecked && "#f44336",
          }}
          htmlType="submit"
        >
          Sign up
        </Button>
        <div className={style.singupLinks}>
          <p>
            Already have an account?
            <Link to="/signin" className={style.signin_link}>
              {" "}
              Sign in
            </Link>
          </p>
          {/* <p>
            <Link to="/restore-password" style={{ color: "#f44336" }}>
              Forgot your password?
            </Link>
          </p> */}
        </div>
      </Form.Item>
    </Form>
  );
};

export default SignupForm;
