import React from "react";
import { Button, Space, Input } from "antd";
import style from "./index.module.scss";
import { Table, Tag, ColumnsType } from "antd";
import { Loader } from "../../../../Common/Loader";
import { ItemNotFound } from "../../../../Common/ItemNotFound";
import {
  updateUserRequest,
  getUserByStatusRequest,
  searchUser,
} from "../../service";
import moment from "moment";
import { useEffect, useState } from "react";
import { getFullDate } from "../../../../../constants/DATE_FORMATE";
import { withRouter } from "../../../../../helpers/withRouter";
import { connect } from "react-redux";
import { randomNumberInRange } from "../../../../../constants/UNIQUE_ID";
import { AccountsContainer } from "../../../Accounts";
import { useNavigate } from "react-router";
// import {}from ''
const AccountsPending = (props) => {
  const history = useNavigate();
  const [users, setUsers] = useState([]);
  const [searchData, setSearchData] = useState([]);
  const [columns, setColumns] = useState([
    {
      title: "Name",
      dataIndex: ["firstName"],
      key: "_id",
      render: (text, record) => (
        <a onClick={() => showDetails(record?._id)}>
          {record?.firstName ? record?.firstName : record?.email}
        </a>
      ),
    },
    {
      title: "Type",
      dataIndex: "role",
      key: "_id",
    },
    {
      title: "CreatedAt",
      dataIndex: "createdAt",
      key: "_id",
      render: (date) => moment(getFullDate(date)).format("MM/DD/YYYY"),
    },
    {
      title: "Action",

      dataIndex: "_id",
      key: "_id",
      render: (text, record) => (
        <Space size="middle">
          <Button type="primary" danger onClick={() => onBlock(record?._id)}>
            Block
          </Button>
          <Button type="primary" onClick={() => onApprove(record?._id)}>
            Approve
          </Button>
        </Space>
      ),
    },
  ]);
  const onSearch = async (data) => {
    try {
      if (data === "") {
        getPendingUser();
      } else {
        const response = await props.searchUser("Pending", data);
        setUsers(response?.data?.users);
        console.log(response, "shdhdsj");
      }
    } catch (err) {
      console.log(err);
    }
  };
  const showDetails = (id) => {
    history(`/user-details/${id}`);
  };
  const getPendingUser = async () => {
    const pendingUser = [];
    const response = await props.getUserByStatusRequest("Pending");
    console.log("Inside UseEffect", response);
    response.data?.users.map((u) => {
      if (u.isProfileComplete === true || ( u.role ==='Pharmacy' && u.company) ) pendingUser.push(u);
    });
    console.log(pendingUser, "user");
    setUsers(pendingUser);
  };
  useEffect(() => {
    getPendingUser();
    console.log(searchData, "skhdk");
  }, [searchData]);

  const onApprove = async (_id) => {
    const data = {
      status: "Active",
    };
    const approveRequest = async () => {
      const response = await props.updateUserRequest(_id, data);
      console.log("response", response);
      console.log(response);
    };

    await approveRequest();
    await getPendingUser();
  };

  const onBlock = async (_id) => {
    const data = {
      status: "Blocked",
    };
    const blockRequestRequest = async () => {
      const response = await props.updateUserRequest(_id, data);
      console.log("response", response);
      console.log(response);
    };

    await blockRequestRequest();
    await getPendingUser();
  };

  return (
    <div className={style.container}>
      {props.isLoading ? (
        <Loader />
      ) : users && users.length > 0 ? (
        <div style={{ justifyContent: "center" }}>
          <div style={{ justifyContent: "center", display: "flex" }}>
            <div className={style.container}>
              <AccountsContainer />

              <div style={{ justifyContent: "center", display: "flex" }}>
                <div className={style.searchContainer}>
                  <Input.Search
                    placeholder="Search by name or email"
                    allowClear
                    onSearch={onSearch}
                    onChange={(e) => {
                      console.log(e.target.value);
                      if (e.target.value.length === 0) {
                        getPendingUser();
                      }
                    }}
                    style={{ width: 600, marginBottom: "20px", float: "left" }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div style={{ justifyContent: "center", display: "flex" }}>
            <Table
              columns={columns}
              dataSource={users}
              style={{ width: "100%", alignContent: "center" }}
            />
          </div>
        </div>
      ) : (
        <ItemNotFound>
          <h1 style={{ color: "#808080" }}>There is no Data</h1>
          <AccountsContainer />
        </ItemNotFound>
      )}
    </div>
  );
};
const mapStateToProps = (state) => ({
  isLoading: state.shared.loading.isLoading,
  isAuth: state.auth.signin.isAuth,
  isError: state.auth.signup.isError,
  isSuccess: state.auth.signup.isSuccess,
  errorMessage: state.auth.signup.errorMessage,
  user: state.auth.signin?.user,
});

export const AccountsPendingContainer = withRouter(
  connect(mapStateToProps, {
    updateUserRequest,
    getUserByStatusRequest,
    searchUser,
  })(AccountsPending)
);
