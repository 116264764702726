import React from "react";
import { Button, Space } from "antd";
import PropTypes from "prop-types";
import { getFullDate } from "../../../../../constants/DATE_FORMATE";
import style from "./index.module.scss";
import { Table, Tag, ColumnsType } from "antd";
import {
  getPaymentsrequest,
  updatePaymentsrequest,
  updatePaymentsrequestAndNotification,
  getPaymentsByIDrequest,
} from "../../service";
import { Loader } from "../../../../Common/Loader";
import { ItemNotFound } from "../../../../Common/ItemNotFound";
import { useEffect, useState } from "react";
import { withRouter } from "../../../../../helpers/withRouter";
import { connect } from "react-redux";
import { randomNumberInRange } from "../../../../../constants/UNIQUE_ID";
import { useNavigate } from "react-router";
import { PaymentsContainer } from "../../../Payments";
import moment from "moment";
import { Modal } from "antd";
const Freelancer = (props) => {
  const [users, setUsers] = useState([]);
  const [discount, setDiscount] = useState();
  const [userInfo, setUserInfo] = useState([]);
  const [isModalVisibleAgency, setIsModalVisibleAgency] = useState(false);
  const [freelancerEmail, setFreelancerEmail] = useState();
  const [isModalVisibleFreelancer, setIsModalVisibleFreelancer] =
    useState(false);

  const [columns, setColumns] = useState([]);
  const showModalFreelancer = (id) => {
    freelanccerDetails(id);
    setIsModalVisibleFreelancer(true);
  };
  const handleOkFreelancer = () => {
    setIsModalVisibleFreelancer(false);
  };

  const showModalAgency = (id) => {
    AgencyDetails(id);
    setIsModalVisibleAgency(true);
  };
  const handleOkAgecny = () => {
    setIsModalVisibleAgency(false);
  };

  const getCompleted = async () => {
    const response = await props.getPaymentsrequest("Completed", "Pending");
    console.log("Inside UseEffect ", response);
    setUsers(response?.data?.payments);
    setDiscount(response?.data?.discount);
    console.log(discount);
  };

  const freelanccerDetails = async (id) => {
    const response = await props.getPaymentsByIDrequest(id);
    console.log("Inside UseEffect of userInfo", response);
    setUserInfo(response?.data?.payments);
  };

  const AgencyDetails = async (id) => {
    const response = await props.getPaymentsByIDrequest(id);
    console.log("Inside UseEffect of userInfo", response);
    setUserInfo(response?.data?.payments);
  };

  const updatePayment = async (_id) => {
    const data = {
      freelancerBool: "Completed",
    };
    const freelancerBool = async () => {
      const responseData = await props.getPaymentsByIDrequest(_id);
      // console.log(responseData?.data?.payments?.freelancer?.email , "jdoijofiweuifwuifusdfuhu")
      const response = await props.updatePaymentsrequestAndNotification(
        _id,
        data,
        responseData?.data?.payments?.freelancer?.email,
        responseData?.data?.payments?.freelancer?.firstName,

      );
      console.log("Inside UseEffect", response);

      setUsers(response?.data?.payments);
      setFreelancerEmail(responseData?.data?.payments?.freelaner?.email);

      // console.log(responseData?.data?.payments?.freelancer?.email, "sjijeiji")
    };
    await freelancerBool();
    await getCompleted();
  };
  useEffect(() => {
    getCompleted();
    setDiscount(discount);

    setColumns([
      {
        title: "Agency",
        dataIndex: ["agency", "name"],
        key: randomNumberInRange(),
        render: (text, record) => (
          <a onClick={() => showModalAgency(record?._id)}>{text}</a>
        ),
      },
      {
        title: "Freelancer",
        dataIndex: ["freelancer", "firstName"],
        key: randomNumberInRange(),
        render: (text, record) => (
          <a onClick={() => showModalFreelancer(record?._id)}>{text}</a>
        ),
      },

      {
        title: "Actual Budget",
        dataIndex: "amount",
        key: randomNumberInRange(),
      },
      {
        title: "Budget After Platform Fee",
        dataIndex: "amount",
        key: randomNumberInRange(),
        render: (text, record) => (
          <>
            {discount
              ? record.amount - record.amount * discount
              : record.amount - record.amount * 0.12}
          </>
        ),
      },
      {
        title: "Date",
        dataIndex: "createdAt",
        key: randomNumberInRange(),
        render: (date) => moment(getFullDate(date)).format("MM/DD/YYYY"),
      },
      {
        title: "Action",
        dataIndex: "_id",
        key: randomNumberInRange(),
        render: (text, record) => (
          <Space size="middle">
            <Button
              type="primary"
              danger
              onClick={(e) => updatePayment(record?._id)}
            >
              Mark Complete
            </Button>
          </Space>
        ),
      },
    ]);
  }, [discount]);

  return (
    <div className={style.container}>
      {props.isLoading ? (
        <Loader />
      ) : users && users.length > 0 ? (
        <div style={{ justifyContent: "center" }}>
          <div style={{ justifyContent: "center", display: "flex" }}>
            <PaymentsContainer />
          </div>
          <div
            style={{
              justifyContent: "center",
              display: "flex",
              marginTop: "25px",
            }}
          >
            <Table
              columns={columns}
              dataSource={users}
              style={{ width: "100%", alignContent: "center" }}
            />
            <Modal
              title="Agency Details"
              open={isModalVisibleAgency}
              onOk={handleOkAgecny}
              onCancel={handleOkAgecny}
            >
              <p>Name: {userInfo?.agency?.name}</p>
              <p>Email: {userInfo?.agency?.email}</p>
              <p>Country: {userInfo?.agency?.county}</p>
              <p>Address: {userInfo?.agency?.address}</p>
              <p>Phone: {userInfo?.agency?.phone}</p>
            </Modal>

            <Modal
              title="Freelancer Details"
              open={isModalVisibleFreelancer}
              onOk={handleOkFreelancer}
              onCancel={handleOkFreelancer}
            >
              <p>
                Name: {userInfo?.freelancer?.firstName}{" "}
                {userInfo?.freelancer?.lastName}
              </p>
              <p>Email: {userInfo?.freelancer?.email}</p>
              <p>Phone: {userInfo?.freelancer?.phone}</p>
              <p>Role: {userInfo?.freelancer?.role}</p>
              <p>City: {userInfo?.freelancer?.city}</p>
              <p>State: {userInfo?.freelancer?.state}</p>
              <p>Address: {userInfo?.freelancer?.addressLine1}</p>
            </Modal>
          </div>
        </div>
      ) : (
        <ItemNotFound>
          <h1 style={{ color: "#808080" }}>There is no Data</h1>
          <PaymentsContainer />
        </ItemNotFound>
      )}
    </div>
  );
};
const mapStateToProps = (state) => ({
  isLoading: state.shared.loading.isLoading,
  isAuth: state.auth.signin.isAuth,
  isError: state.auth.signup.isError,
  isSuccess: state.auth.signup.isSuccess,
  errorMessage: state.auth.signup.errorMessage,
  user: state.auth.signin.user,
});

export const FreelancerContainer = withRouter(
  connect(mapStateToProps, {
    getPaymentsrequest,
    updatePaymentsrequest,
    getPaymentsByIDrequest,
    updatePaymentsrequestAndNotification,
  })(Freelancer)
);
