// import React, { useState } from "react";
// import PropTypes from "prop-types";
// import { withRouter } from "../../../../helpers/withRouter";
// import style from "./index.module.scss";
// import { connect } from "react-redux";
// import { addCompanyRequest, getCompanyRequest } from "../service";
// import { CompanyForm } from "../components/CompanyForm";
// import { useEffect } from "react";
// import { Loader } from "../../../Common/Loader";
// import { message } from "antd";

// const MyCompany = (props) => {
//   const [company, setCompany] = useState(null);
//   const [isInitialLoading, setIsInitialLoading] = useState(true);
//   const [messageApi, contextHolder] = message.useMessage();

//   const success = () => {
//     messageApi.open({
//       type: "success",
//       content: "Company has been successfully saved",
//       duration: 2,
//     });
//     //reload company
//     setIsInitialLoading(true);
//     getCompany();
//   };
 

//   const getCompany = async () => {
//     if (props.user?.id) {
//       const response = await props.getCompanyRequest(props.user?.id);
//       setCompany(response.data.user?.company);
//       setIsInitialLoading(false);
//     }
//   };
//   useEffect(() => {
//     getCompany();
//   }, []);

//   return (
//     <>
//       {contextHolder}
//       <div className={style.container}>
//         {props.isLoading && isInitialLoading ? (
//           <Loader />
//         ) : (
//           <CompanyForm
//             onSubmit={props.addCompanyRequest}
//             onSuccess={success}
//             isLoading={props.isLoading}
//             isError={props.isError}
//             errorMessage={props.errorMessage}
//             userID={props.user?.id}
//             company={company}
            
//           />
//         )}
//       </div>
//     </>
//   );
// };

// MyCompany.propTypes = {
//   isLoading: PropTypes.bool.isRequired,
//   isAuth: PropTypes.bool.isRequired,
//   isSuccess: PropTypes.bool.isRequired,
//   isError: PropTypes.bool.isRequired,
//   errorMessage: PropTypes.string.isRequired,
//   addCompanyRequest: PropTypes.func.isRequired,
//   getCompanyRequest: PropTypes.func.isRequired,
//   user: PropTypes.object.isRequired,
// };

// const mapStateToProps = (state) => ({
//   isLoading: state.shared.loading.isLoading,
//   isAuth: state.auth.signin.isAuth,
//   isError: state.auth.signup.isError,
//   isSuccess: state.auth.signup.isSuccess,
//   errorMessage: state.auth.signup.errorMessage,
//   user: state.auth.signin.user,
// });
// export const MyCompanyContainer = withRouter(
//   connect(mapStateToProps, { addCompanyRequest, getCompanyRequest })(MyCompany)
// );
import React, { useState } from "react";
import PropTypes from "prop-types";
import { withRouter } from "../../../../helpers/withRouter";
import style from "./index.module.scss";
import { connect } from "react-redux";
import { addCompanyRequest, getCompanyRequest } from "../service";
import { CompanyForm } from "../components/CompanyForm";
import { useEffect } from "react";
import { Loader } from "../../../Common/Loader";
import { message } from "antd";

const MyCompany = (props) => {
  const [company, setCompany] = useState(null);
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [messageApi, contextHolder] = message.useMessage();

  const success = () => {
    messageApi.open({
      type: "success",
      content: "Company has been successfully saved",
      duration: 2,
    });
    //reload company
    setIsInitialLoading(true);
    getCompany();
  };

  const getCompany = async () => {
    if (props.user.id) {
      const response = await props.getCompanyRequest(props.user.id);
      setCompany(response.data.user.company);
      setIsInitialLoading(false);
    }
  };
  useEffect(() => {
    getCompany();
  }, []);

  return (
    <>
      {contextHolder}
      <div className={style.container}>
        {props.isLoading && isInitialLoading ? (
          <Loader />
        ) : (
          <CompanyForm
            onSubmit={props.addCompanyRequest}
            onSuccess={success}
            isLoading={props.isLoading}
            isError={props.isError}
            errorMessage={props.errorMessage}
            userID={props.user.id}
            company={company}
            user = {props.user}
          />
        )}
      </div>
    </>
  );
};

MyCompany.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  isAuth: PropTypes.bool.isRequired,
  isSuccess: PropTypes.bool.isRequired,
  isError: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
  addCompanyRequest: PropTypes.func.isRequired,
  getCompanyRequest: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  isLoading: state.shared.loading.isLoading,
  isAuth: state.auth.signin.isAuth,
  isError: state.auth.signup.isError,
  isSuccess: state.auth.signup.isSuccess,
  errorMessage: state.auth.signup.errorMessage,
  user: state.auth.signin.user,
});
export const MyCompanyContainer = withRouter(
  connect(mapStateToProps, { addCompanyRequest, getCompanyRequest })(MyCompany)
);