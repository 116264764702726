import { api } from "../../../helpers/api";
import {
  isLoading,
  isLoaded,
} from "../../Common/Layout/components/Cabinet/actions";

export const getCandidatesRequest = (postID) => async (dispatch) => {
  // try {
  //   const response = await api("get", `posts/${postID}`, null);
  //   return response;
  // } catch (err) {
  //   console.log(err);
  // }
  dispatch(isLoading());
  try {
    const response = await api("get", `posts/${postID}`, null);
    dispatch(isLoaded());
    console.log("In the service file", response);
    return response;
  } catch (err) {
    console.log(err);
    dispatch(isLoaded());
  }
};



export const getMyScheduleRequest = (candidateID) => async (dispatch) => {
  //dispatch(isLoading());
  try {
    console.log("In the service file for poster", candidateID);

    const response = await api("get", `posts/creater/${candidateID}`, null);
    console.log("In the service file for poster", response);
    return response;
  } catch (err) {
    console.log(err);
    //(isLoaded());
  }
};
export const updateCandidateRequest = (postID, data) => async (dispatch) => {
  dispatch(isLoading());
  try {
    const response = await api("patch", `posts/edit-post/${postID}`, data);
    dispatch(isLoaded())
    console.log("In the service file for candidate", response);
    return response;
  } catch (err) {
    console.log(err);
    dispatch(isLoaded());
  }};



export const getPostByIdRequest = (id) => async (dispatch) => {
  dispatch(isLoading());
  try {
    const response = await api("get", `posts/${id}`, null);
    dispatch(isLoaded());
    console.log("In the service file", response);
    return response;
  } catch (err) {
    console.log(err);
    dispatch(isLoaded());
  }
};
export const getContractsByFilter =
  (post, agency, freelancer) => async (dispatch) => {
    dispatch(isLoading());

    try {
      const response = await api(
        "get",
        `contracts/filters?post=${post}&agency=${agency}&freelancer=${freelancer}`,
        null
      );

      dispatch(isLoaded());

      console.log("In the service file of", response);
      return response;
    } catch (err) {
      console.log(err);
      dispatch(isLoaded());
    }
  };

export const updateContracts = (contractID, status) => async (dispatch) => {
  dispatch(isLoading());
console.log(status)
  try {
    const response = await api(
      "patch",
      `/contracts/edit-contract/${contractID}`,
      status
    );

    dispatch(isLoaded());

    console.log("In the service file of", response);
    return response;
  } catch (err) {
    console.log(err);
    dispatch(isLoaded());
  }
};