import React, { useEffect } from "react";

const HomeContainer = () => {
  useEffect(() => {
    window.location.href = "https://etesalhealth.com/home";
  }, []);

  return;
};

export default HomeContainer;
