import React from "react";
import { Button, Space } from "antd";
import style from "./index.module.scss";
import { Table, Tag, ColumnsType } from "antd";
import { useEffect } from "react";
import { useNavigate } from "react-router";

export const MatchedOpportunityContainer = () => {
  const history = useNavigate();
  const inProgress = () => {
    history("/in-progress");
  };
  const completed = () => {
    history("/completed");
  };
  return (
    <div style={{ justifyContent: "center", marginTop: "25px" }}>
      <div style={{ justifyContent: "center", display: "flex" }}>
        <Space wrap className={style.btnStyle}>
          <Button type="primary" danger size="large" onClick={inProgress}>
            In Progress
          </Button>
          <Button type="primary" danger size="large" onClick={completed}>
            Completed
          </Button>
        </Space>
      </div>
    </div>
  );
};
