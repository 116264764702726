import { MONTHS } from "../constants/MONTHS";

export const getDate = (date) => {
  const dateObj = new Date(date);
  const day = dateObj.getDate();
  const month = MONTHS[dateObj.getMonth()];
  const year = dateObj.getFullYear();
  const hours = dateObj.getHours();
  const minutes = dateObj.getMinutes();
  return {
    day,
    month,
    year,
    hours,
    minutes,
  };
};
