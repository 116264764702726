import React, { useState } from "react";

import { ShopOutlined, EnvironmentOutlined } from "@ant-design/icons";
import style from "./index.module.scss";
import moment, { Moment } from "moment";
import { Row, Col, Button, Typography, Checkbox } from "antd";
import { timeSince } from "../../../../../helpers/timeSince";
import { useEffect } from "react";
import { ROLES } from "../../../../../constants/ROLES";
import { Link } from "react-router-dom";

export const PostHeader = (props) => {
  const [isCandidate, setIsCandidate] = useState(false);
  const [terms, setTerms] = useState(false);

  const data = "data goes here";

  useEffect(() => {
    console.log("HERE", props.post);
    if (props.post) {
      //check if the current user is already in the candidates array of this post
      const isCandidate = props.post.candidates.find(
        (candidate) => candidate.candidate._id === props.user?.id
      );

      setIsCandidate(isCandidate);
    }
  }, [props]);
  const onChange = (e) => {
    console.log(`checked = ${e.target.checked}`);
    if (e.target.checked === true) setTerms(true);
    else setTerms(false);
  };
  const iconStyle = {
    color: "#f44336",
    fontSize: "20px",
    // paddingLeft: "0px",
    // paddingRight: "10px",
  };
  return (
    <Row className={style.container}>
      <div style={{ background: "#fff" }}>
        <div className={style.infoContainer}>
          <div className={style.iconContainer}>
            <ShopOutlined style={iconStyle} />
          </div>
          <div>
            <h1>{props?.post?.name}</h1>
            <p>By {props?.post?.postedBy?.company?.name}</p>
          </div>
        </div>
      </div>
      <div style={{ background: "#fff" }}>
        <div className={style.infoContainer}>
          <p style={{ marginBottom: 0 }}>
            {props?.post?.postedBy?.company?.address},{" "}
            {props?.post?.postedBy?.company?.city},{" "}
            {props?.post?.postedBy?.company?.county}
          </p>
          {/* <div className={style.iconContainer} style={{ background: "#000" }}>
            <EnvironmentOutlined
              style={{
                fontSize: "20px",
                color: "#fff",
              }}
            />
          </div> */}
          {!isCandidate && props.user.role ===  ROLES.pharmacist? (
           <>
         
         <div style={{display:"block"}}>
    
         <Button 
              type="danger"
              size="large"
              onClick={() => props.onPressApply(props?.post?._id)}
              loading={props?.isLoading}
              disabled ={!terms}
              style={{float:"right"}}
            >
              Apply Now
            </Button>
          
            <br/>
            <span>
            {/* <br/><br/> */}
             
            <Checkbox onClick={onChange} /> <span> I accept the Terms and Conditions. Please review the   </span>
            <span>
              {" "}
              <Link to="/terms" state={props.user?.role} target="_blank">
                <u>terms and conditions</u>
              </Link>
            </span>
            <span>{" "}before applying</span>
            </span>
            </div>
             {/* </div> */}
             </>
          ) : isCandidate && props.user.role ===  ROLES.pharmacist?(
            <Button type="danger" size="large">
              Applied
            </Button>
          ):(<></>)}
        </div>
      </div>
    </Row>
  );
};
