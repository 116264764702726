import { api } from "../../../helpers/api";
import {
  isLoading,
  isLoaded,
} from "../../Common/Layout/components/Cabinet/actions";
export const getAllFeedbacks = (userID) => async (dispatch) => {
  dispatch(isLoading());
  try {
    const response = await api("get", `feedback/reviewedID-feedback/${userID}`, null);
   
    console.log("In the service file", response);
    dispatch(isLoaded());
    return response;
    
  } catch (err) {
    console.log(err);
    dispatch(isLoaded());
  }
};
export const getFeedbackID = (userID) => async (dispatch) => {
  dispatch(isLoading());
  try {
    const response = await api("get", `feedbacks/${userID}`, null);
    dispatch(isLoaded());
    console.log("In the service file", response);
    return response;
  } catch (err) {
    console.log(err);
    dispatch(isLoaded());
  }
};
export const getAllPostsRequest = (userID) => async (dispatch) => {
  dispatch(isLoading());
  try {
    const response = await api("get", `posts/creator/${userID}`, null);
    dispatch(isLoaded());
    console.log("In the service file", response);
    return response;
  } catch (err) {
    console.log(err);
    dispatch(isLoaded());
  }
};
export const getMyScheduleRequest = (candidateID) => async (dispatch) => {
  dispatch(isLoading());
  try {
    const response = await api("get", `posts/candidates/${candidateID}`, null);
    dispatch(isLoaded());
    console.log("In the service file", response);
    return response;
  } catch (err) {
    console.log(err);
    dispatch(isLoaded());
  }
};

export const updateCandidateRequest = (postID, data) => async (dispatch) => {
  dispatch(isLoading());
  try {
    const response = await api("patch", `posts/edit-post/${postID}`, data);
    dispatch(isLoaded());
    console.log("In the service file", response);
    return response;
  } catch (err) {
    console.log(err);
    dispatch(isLoaded());
  }
};


export const submitFeedbackRequest =
  ( comment,postID, reviewedID, reviewerID, rating) => async (dispatch) => {
    const data = {
      ...comment,
        reviewedID: reviewedID,
        reviewerID: reviewerID,
        ratings: rating,
        postID: postID,
     
    };

    dispatch(isLoading());
    try {
      const response = await api("post", "feedback/create-feedback", data);
      dispatch(isLoaded());
      console.log("In the service file", response);
      return response;
    } catch (err) {
      console.log(err);
      dispatch(isLoaded());
    }
  };
  export const getFeedbacksByPostID =
  (postID) => async (dispatch) => {
    
    dispatch(isLoading());
    try {
      const response = await api("get", `/feedback/postID-feedback/${postID}`, null);
      dispatch(isLoaded());
      console.log("In the service file", response);
      return response;
    } catch (err) {
      console.log(err);
      dispatch(isLoaded());
    }
  };


  export const updateFeedback =
  (id,  rating, comment) => async (dispatch) => {
    const data = {
  
    
      ...comment,
      ratings: rating,
  
    
  };

    dispatch(isLoading());
    try {
      const response = await api("patch", `/feedbacks/update-feedback/${id}`, data);
      dispatch(isLoaded());
      console.log("In the service file", response);
      return response;
    } catch (err) {
      console.log(err);
      dispatch(isLoaded());
    }
  };
export const filterFeedback = (postID, reviewedID, reviewerID) => async (dispatch) => {
    
        dispatch(isLoading());
        try {
          const response = await api("get",  `/feedback/filter?postID=${postID}&reviewedID=${reviewedID}&reviewerID=${reviewerID}`)
      
        
        
          console.log("In the service file", response);
          return response;
        } catch (err) {
          console.log(err);
          dispatch(isLoaded());
        }
      };


