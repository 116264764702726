import React from "react";
import { Button, Space } from "antd";
import style from "./index.module.scss";
import { Table, Tag, ColumnsType } from "antd";
import { getContractsByStatusRequest } from "../../service";
import { Loader } from "../../../../Common/Loader";
import { ItemNotFound } from "../../../../Common/ItemNotFound";
import moment from "moment";
import { getFullDate } from "../../../../../constants/DATE_FORMATE";
import { useEffect, useState } from "react";
import { withRouter } from "../../../../../helpers/withRouter";
import { randomNumberInRange } from "../../../../../constants/UNIQUE_ID";
import { connect } from "react-redux";
import { useNavigate } from "react-router";
import { MatchedOpportunityContainer } from "../../../MatchedOpportunity";
const Inprogress = (props) => {
  const [users, setUsers] = useState([]);
  const [columns, setColumns] = useState([
    {
      title: "Agency",
      dataIndex: ["agency", "name"],
      key: randomNumberInRange(),
    },
    {
      title: "Freelancer",
      dataIndex: ["freelancer", "firstName"],
      key: randomNumberInRange(),
    },
    {
      title: "StartDate",
      dataIndex: "startDate",
      key: randomNumberInRange(),
      render: (date) => moment(getFullDate(date)).format("MM/DD/YYYY"),
    },
    {
      title: "EndDate",
      dataIndex: "endDate",
      key: randomNumberInRange(),
      render: (date) => moment(getFullDate(date)).format("MM/DD/YYYY"),
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: randomNumberInRange(),
    },
  ]);
  const getCompleted = async () => {
    console.log(props.user?.id);
    const response = await props.getContractsByStatusRequest("In Progress");
    console.log("Inside UseEffect", response);
    setUsers(response?.data?.contracts);
  };
  useEffect(() => {
    getCompleted();
  }, []);

  return (
    <div className={style.container}>
      {props.isLoading ? (
        <Loader />
      ) : users && users.length > 0 ? (
        <div style={{ justifyContent: "center" }}>
          <div style={{ justifyContent: "center", display: "flex" }}>
            <MatchedOpportunityContainer />
          </div>
          <div
            style={{
              justifyContent: "center",
              display: "flex",
              marginTop: "25px",
            }}
          >
            <Table
              columns={columns}
              dataSource={users}
              style={{ width: "100%", alignContent: "center" }}
            />
          </div>
        </div>
      ) : (
        <ItemNotFound>
          <h1 style={{ color: "#808080" }}>There is no Data</h1>
          <MatchedOpportunityContainer />
        </ItemNotFound>
      )}
    </div>
  );
};
const mapStateToProps = (state) => ({
  isLoading: state.shared.loading.isLoading,
  isAuth: state.auth.signin.isAuth,
  isError: state.auth.signup.isError,
  isSuccess: state.auth.signup.isSuccess,
  errorMessage: state.auth.signup.errorMessage,
  user: state.auth.signin.user,
});

export const InProgressContainer = withRouter(
  connect(mapStateToProps, {
    getContractsByStatusRequest,
  })(Inprogress)
);
