import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { withRouter } from "../../../../helpers/withRouter";
import style from "./index.module.scss";
import { connect } from "react-redux";
import { message } from "antd";
import moment from "moment";
import { PostTablets } from "../components/PostTablets";
import { PostHeader } from "../components/PostHeader";
import { PostDescription } from "../components/PostDescription";
import { useLocation, useNavigate, useParams } from "react-router";
import { getPostByIdRequest, applyToPostRequest } from "../service";
import { getDate } from "../../../../helpers/getDate";
import { Loader } from "../../../Common/Loader";
const PostDetails = (props) => {
  const history = useNavigate();

  const location = useLocation();

  const { state } = location;
  const [messageApi, contextHolder] = message.useMessage();
  // const { id } = useParams();

  const [hours, setHours] = useState();

  // const [dateTime, setDateTime] = useState(null);
  const [discount, setDiscount] = useState();
  const [postID, setPostID] = useState(null);
  const [post, setPost] = useState(null);
  const { id } = useParams();
  const [dateTime, setDateTime] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [enddate, setEndDate] = useState(null);

  const [isInitialLoading, setIsInitialLoading] = useState(true);
  useEffect(() => {
    setPostID(id);
    const hours = () => {
      var startDate = moment(post?.dateTime);
      const endData = moment(post?.endTime);
      const diff = endData.diff(startDate);
      const diffDuration = moment.duration(diff);
      return diffDuration.asHours();
    };
    const date = hours();

    setHours(Math.abs(Math.round(date)));
  }, [id]);
  const success = () => {
    messageApi.open({
      type: "success",
      content:
        "Applications are sent. You will be redirected to your schedule page",
      duration: 2,
    });
    setTimeout(() => {
      history(`/my-schedule`);
    }, 3000);
  };

  useEffect(() => {
    const getPost = async () => {
      const response = await props.getPostByIdRequest(postID);
      setPost(response.data.post);
      setIsInitialLoading(false);
      setDiscount(response.data?.discount);
      // alert(response.data?.discount)
    };
    if (postID) getPost();
  }, [postID]);

  useEffect(() => {
    // alert(props.user?.firstName)
    if (post) {
      setDateTime(getDate(post.createdAt));
      setStartDate(getDate(post?.startDate))
      setEndDate(getDate(post?.endDate))

    }
  }, [post]);

  const goBack = () => {
    history(`/${state}`);
  };
  const onPressApply = async (postID) => {
    // console.log(postID);
    let candidates;
    if (post?.candidates) {
      post.candidates.push({
        candidate: props.user?.id,
        status: "Pending",
      });
      candidates = post.candidates;
    } else {
      candidates = [{ candidate: props.user?.id, status: "Pending" }];
    }
    try {
      const curr_date = Date.now();
      let new_date = moment(curr_date).format("ddd DD MMM, YYYY");

      await props.applyToPostRequest(
        {
          candidates: candidates,
          userEmail: props.user?.email,
          userFirstName: props.user?.firstName,
          copmanyName: post?.postedBy?.company?.name,
          companyAddress: post?.postedBy?.company?.address,
          date: new_date,
          hours: hours,
          postedByEmail: post?.postedBy?.email,
          postedByFirstName: post?.postedBy?.firstName,
          userLastName: props.user?.lastName,
          companyCity: post?.postedBy?.company?.city,
          companyAddress: post?.postedBy?.company?.address,
        },
        postID
      );
      success();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      {contextHolder}
      <div className={style.container}>
        {props.isLoading && isInitialLoading ? (
          <Loader />
        ) : (
          <>
            <PostTablets
              post={post}
              dateTime={dateTime}
              startDate = {startDate}
              endDate = {enddate}
              goBack={goBack}
              discount={discount}
              user = {props.user}
            />
            <PostHeader
              post={post}
              onPressApply={onPressApply}
              isLoading={props.isLoading}
              user={props.user}
              discount={discount}
            />
            <PostDescription post={post} />{" "}
          </>
        )}
      </div>
    </>
  );
};

PostDetails.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  isAuth: PropTypes.bool.isRequired,
  isSuccess: PropTypes.bool.isRequired,
  isError: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
  getPostByIdRequest: PropTypes.func.isRequired,
  applyToPostRequest: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  isLoading: state.shared.loading.isLoading,
  isAuth: state.auth.signin.isAuth,
  isError: state.auth.signup.isError,
  isSuccess: state.auth.signup.isSuccess,
  errorMessage: state.auth.signup.errorMessage,
  user: state.auth.signin.user,
});
export const PostDetailsContainer = withRouter(
  connect(mapStateToProps, { getPostByIdRequest, applyToPostRequest })(
    PostDetails
  )
);
