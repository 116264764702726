import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import SignupForm from "../components/SignupForm";
import { connect } from "react-redux";
import { signupRequest } from "../actions";
import { Link } from "react-router-dom";
import { withRouter } from "./../../../../helpers/withRouter";
import style from "./index.module.scss";
import { Col } from "antd";
import { useNavigate } from "react-router-dom";

const Signup = (props) => {
  const [success, setSuccess] = useState(false);

  const history = useNavigate();
  useEffect(() => {
    props.isAuth && history("/signin");
  });

  return (
    <Col
      xs={{ span: 12, offset: 6 }}
      sm={{ span: 12, offset: 6 }}
      md={{ span: 12, offset: 6 }}
      lg={{ span: 12, offset: 6 }}
      xl={{ span: 12, offset: 6 }}
    >
      <div className={style.signupFormWrapper}>
        {success ? (
          <div className={style.authHeader}>
            <h1>Success</h1>
          <p> Your account has been successfully created. </p> 
            <p>
           <span> <Link to="/signin" className={style.signin_link}>
                Sign in    

              </Link>
              </span>  
              <span  style={{color:"black"}}>  to complete your profile</span>  


             
            </p>
          </div>
        ) : (
          <SignupForm
            onSubmit={props.signupRequest}
            isLoading={props.isLoading}
            isError={props.isError}
            errorMessage={props.errorMessage}
            setSuccess={setSuccess}
          />
        )}
      </div>
    </Col>
  );
};

Signup.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  isAuth: PropTypes.bool.isRequired,
  isSuccess: PropTypes.bool.isRequired,
  isError: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
  signupRequest: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  isLoading: state.auth.signup.isLoading,
  isAuth: state.auth.signin.isAuth,
  isError: state.auth.signup.isError,
  isSuccess: state.auth.signup.isSuccess,
  errorMessage: state.auth.signup.errorMessage,
});

export const SignupContainer = withRouter(
  connect(mapStateToProps, { signupRequest })(Signup)
);
