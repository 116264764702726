import { api } from "../../../helpers/api";
import {
  isLoading,
  isLoaded,
} from "../../Common/Layout/components/Cabinet/actions";

export const getAllPostsRequest = (userID) => async (dispatch) => {
  dispatch(isLoading());
  try {
    const response = await api("get", `posts/creator/${userID}`, null);
    dispatch(isLoaded());
    console.log("In the service file", response);
    return response;
  } catch (err) {
    console.log(err);
    dispatch(isLoaded());
  }
};
