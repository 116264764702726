import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { withRouter } from "../../../../helpers/withRouter";
import style from "./index.module.scss";
import { connect } from "react-redux";
import { getAllPostsRequest } from "../service";
import { useNavigate } from "react-router";
import { ShiftCard } from "../../../Common/Shift";
import { Loader } from "../../../Common/Loader";

import { ItemNotFound } from "../../../Common/ItemNotFound";
import { Button } from "antd";

const AllPost = (props) => {
  const [posts, setPosts] = useState([]);

  const history = useNavigate();
  const [discount, setDiscount] = useState();


  useEffect(() => {
    // console.log(props.getAllPostsRequest(props.user?.id));
    const getAllPosts = async () => {
      const response = await props.getAllPostsRequest(props.user?.id);
      console.log("Inside UseEffect", response);
      setPosts(response?.data?.posts);
      setDiscount(response?.data.discount);
    };

    getAllPosts();
  }, []);

  const onEditClick = (post) => {
    history("/make-post", { state: post });
  };

  const onCandidateClick = (post) => {
    history(`/candidates/${post}`);
  };

  return (
    <div className={style.container}>
      {props.isLoading ? (
        <Loader />
      ) : posts && posts.length > 0 ? (
        posts.map((post) => {
          return (
            <ShiftCard
              key={post._id}
              post={post}
              role={props.user?.role}
              onEditClick={onEditClick}
              onCandidateClick={onCandidateClick}
              discount= {discount}
            />
          );
        })
      ) : (
        <ItemNotFound>
          <h1 style={{ color: "#808080" }}>There are no posts</h1>
          <Button type="danger" onClick={() => history("/make-post")}>
            Create a new post
          </Button>
        </ItemNotFound>
      )}
    </div>
  );
};

AllPost.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  isAuth: PropTypes.bool.isRequired,
  isSuccess: PropTypes.bool.isRequired,
  isError: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
  getAllPostsRequest: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  isLoading: state.shared.loading.isLoading,
  isAuth: state.auth.signin.isAuth,
  isError: state.auth.signup.isError,
  isSuccess: state.auth.signup.isSuccess,
  errorMessage: state.auth.signup.errorMessage,
  user: state.auth.signin.user,
});

export const AllPostsContainer = withRouter(
  connect(mapStateToProps, { getAllPostsRequest })(AllPost)
);
