import { api } from "../../../helpers/api";
import {
  isLoading,
  isLoaded,
} from "../../Common/Layout/components/Cabinet/actions";
export const sendSupportEmail = (data) => async (dispatch) => {
  dispatch(isLoading());
  try {
    const response = await api("post", `/feedbacks/support`, data);
    dispatch(isLoaded());
    console.log("In the service file", response);
    return response;
  } catch (err) {
    console.log(err);
    dispatch(isLoaded());
  }
};



